import { knightTypeToStyle } from "../../helpers/knightFrameStyle";
import knight_basic from "../../images/knight-basic.png";
import knight_squire from "../../images/knight-squire.png";
import knight_boosted from "../../images/knight-boosted.png";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import "../../stories/KnightFrame.css";
import { ReactComponent as Success } from "../../svgs/success.svg";

type Props = {
  knightType: string;
  knightStatus: string;
  name: string;
  // imgUri: string;
  isSelected?: boolean;
  knightButtons?: any;
  level?: string;
  onSelected?: any;
  tokenId?: string;
};

function KnightFrame(props: Props) {
  const frameStyle = knightTypeToStyle(props.knightType);
  return (
    <div
      className={"flex flex-col relative w-full p-1"}
    >
      <div className={"rounded-full " + frameStyle?.circleStyle}>
        <div className="relative pt-full">
          <div
            className={
              "absolute inset-0 bg-transparent rounded-full border-2 overflow-hidden " +
              frameStyle?.circleStyle
              + (props.isSelected ? " bg-gradient-to-tl from-rose-600/30 to-indigo-800/30" : "")
            }
          >
            <img src={props.knightType ==="nft" ? knight_boosted : props.knightType ==="squire" ? knight_squire : knight_basic } className="w-full" width="250" height="250" alt={"Knight" + (props.tokenId ? " ID: " + props.tokenId : '')} />
          </div>
          {props.knightType ==="nft" && (
            <div className="absolute top-0 right-0 w-1/4 p-1 text-amber-100 bg-black bg-opacity-40 rounded-full">
              <Logo />
            </div>
          )}

          {props.knightType ==="squire" && (
            <div className="absolute top-0 right-0 w-1/4 p-1 bg-black bg-opacity-40 rounded-full">
              <Logo />
            </div>
          )}
          {props.isSelected && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 bg-black/40 rounded-full">
              <Success className="" />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          "knight-shade z-10 -mt-6 flex flex-col px-2 bg-gradient-to-b from-black border-y-4 " +
          frameStyle?.borderStyle
        }
      >
        <div
          className={
            "flex items-center justify-around py-1.5 text-smer font-light border-b " +
            frameStyle?.borderBottomStyle
          }
        >
          {props.tokenId && (<span>ID <span className="font-bold">{props.tokenId}</span></span>)}
          <span>Lvl <span className="font-bold">{props.level}</span></span>
        </div>
        <div className="py-2 text-center uppercase truncate">
          {props.name}
        </div>
        {props.knightButtons &&
          <div className="pb-2">{props.knightButtons}</div>
        }
        {props.onSelected &&
          <div className="pb-2">
            <button className="btn-boost" onClick={props.onSelected}>
              Boost
            </button>
          </div>
        }
      </div>
    </div>
  );
}
export default KnightFrame;
