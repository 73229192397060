interface ChainConfig {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  iconUrls: string[];
}

export const smartBchChain: ChainConfig = {
  chainId: "0xC85E31", // 10001
  chainName: "geth Regtest",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://geth.regtest.actorforth.org/"],
  iconUrls: [],
};

export const polygonMainnetChain: ChainConfig = {
  chainId: "0x89", // 137
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://rpc-mainnet.matic.network"],
  iconUrls: [],
};

export const polygonMumbaiChain: ChainConfig = {
  chainId: "0x13881", // 80001
  chainName: "Polygon Testnet Mumbai",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://rpc-mumbai.matic.today/"],
  iconUrls: [],
};

export function getChainById(chainId: string): ChainConfig {
  switch (chainId) {
    case "80001":
      return polygonMumbaiChain;
    case "137":
      return polygonMainnetChain;
    default:
      return smartBchChain;
  }
}
