import { ButtonHTMLAttributes, ReactNode } from "react";

import classNames from "classnames";

type Props = {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  children: ReactNode;
  primary?: boolean;
};

export const buttonLogin = classNames(
  "w-auto h-auto rounded-2xl"
);

export function Button(props: Props) {
  return (
    <button type={props.type} className={buttonLogin}>
      {props.children}
    </button>
  );
}
export default Button;
