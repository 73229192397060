import ck_logo from "./images/ck_logo.png";
import { ReactComponent as Discord } from "./svgs/discord.svg";
import { ReactComponent as Linktree } from "./svgs/linktree.svg";
import { ReactComponent as Twitter } from "./svgs/twitter.svg";
import { ReactComponent as Youtube } from "./svgs/youtube.svg";

export default function Footer() {
  return (
    <div className="px-6 xs:px-10">
      <div className="flex flex-col items-center justify-center py-12 lg:py-16">
        <img
          src={ck_logo}
          alt="CryptoKnights Game"
          className="block w-72 lg:w-112"
        />  
        <h2 className="title relative w-full">
          Join Community
        </h2>
        <div className="flex gap-4 md:gap-6">
          <a
            href="https://twitter.com/MonetDotMarket"
            className="btn btn-circle btn-ie"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter className="w-5" />
          </a>
          <a
            href="https://discord.gg/sScbW4xV2T"
            className="btn btn-circle btn-ie"
            target="_blank"
            rel="noreferrer"
          >
            <Discord className="w-5" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCOtBokg7hDZxFMSyiD18TyA"
            className="btn btn-circle btn-ie"
            target="_blank"
            rel="noreferrer"
          >
            <Youtube className="w-5" />
          </a>
          <a
            className="btn btn-circle btn-ie"
            target="_blank"
            rel="noreferrer"
            href="https://linktr.ee/cryptoknightsgames"
          >
            <Linktree className="w-5" />
          </a>
        </div>

        <div className="flex flex-col items-center font-bold mt-6 sm:mt-12 space-y-4">
          <a
            className="hover:text-gold-400 text-smer"
            href="https://cryptoknights.games/"
            target="_blank"
            rel="noreferrer"
          >
            Home
          </a>
          <a
            className="hover:text-gold-400 text-smer"
            href="https://cryptoknights.games/t&c.html"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
          <a
            className="hover:text-gold-400 text-smer"
            href="https://whitepaper.cryptoknights.games/"
            target="_blank"
            rel="noreferrer"
          >
            Whitepaper
          </a>
        </div>
      </div>
    </div>
  );
}
