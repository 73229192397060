import { useActor } from "@xstate/react";
import { useCallback } from "react";
import { actorsInterpreter } from "../actors_machine";
import { publish } from "../app_events";

import KnightFrame from "../components/KnightFrame/Index";
import { PopUpTransaction } from "../components/PopUpTransaction";
import PopUpRequestConfirm from "../components/PopUpRequestConfirm/Index";
import PopUpPromote from "../components/PopUpPromote/Index";
import { ReactComponent as Spinner } from "../svgs/spinner.svg";
import { TransactionType } from "../components/PopUpRequestConfirm/Index";
import PopUpApprove from "../components/PopUpApprove";
import {
  KnightFrameStyle,
  knightTypeToStyle,
  smallGoldButtonStyle,
} from "../helpers/knightFrameStyle";
const slug = require('github-slugger').slug;
const { inventory } = actorsInterpreter.getSnapshot().context;

export default function Inventory() {
  const [inventoryState] = useActor(inventory);

  const inGameSelected = useCallback(() => {
    publish(
      { type: "inventory__selected_menu_clicked", selected: "inGame" },
      "inventory"
    );
  }, []);

  const onChainSelected = useCallback(() => {
    publish(
      { type: "inventory__selected_menu_clicked", selected: "onChain" },
      "inventory"
    );
  }, []);

  const onPromoteClicked = useCallback((index: number) => {
    publish(
      { type: "inventory__promote_squire_clicked", index: index },
      "inventory"
    );
  }, []);
  const onPromoteNonNFTClicked = useCallback((index: number) => {
    publish(
      { type: "inventory__promote_non_nft_clicked", index: index },
      "inventory"
    );
  }, []);

  const onExportClicked = useCallback((index: number, knightType: string) => {
    publish(
      {
        type: "inventory__export_clicked",
        index: index,
        knightType: knightType,
      },
      "inventory"
    );
  }, []);
  const closeModalClicked = useCallback(() => {
    publish({ type: "inventory__close_modal_clicked" }, "inventory");
  }, []);
  const confirmPromoteClicked = useCallback(() => {
    publish({ type: "inventory__confirm_promote_clicked" }, "inventory");
  }, []);
  const confirmImportClicked = useCallback(() => {
    publish({ type: "inventory__confirm_import_clicked" }, "inventory");
  }, []);
  const confirmExportClicked = useCallback(() => {
    publish({ type: "inventory__confirm_export_clicked" }, "inventory");
  }, []);
  const onCancelSelectedNonNFTClicked = useCallback(() => {
    publish({ type: "inventory__selected_non_nft_cancel" }, "inventory");
  }, []);
  const onConfirmSelectedNonNFTClicked = useCallback(() => {
    publish({ type: "inventory__selected_non_nft_confirm" }, "inventory");
  }, []);
  const approveClicked = useCallback(() => {
    publish({ type: "inventory__approve_clicked" }, "inventory");
  }, []);
  const selectedNonNFTClicked = useCallback((index: number) => {
    publish(
      { type: "inventory__selected_non_nft_clicked", index: index },
      "inventory"
    );
  }, []);
  const onCancelSelectedSquireClicked = useCallback(() => {
    publish({ type: "inventory__selected_squire_cancel" }, "inventory");
  }, []);
  const onConfirmSelectedSquireClicked = useCallback(() => {
    publish({ type: "inventory__selected_squire_confirm" }, "inventory");
  }, []);
  const selectedSquireClicked = useCallback((index: number) => {
    publish(
      { type: "inventory__selected_squire_clicked", index: index },
      "inventory"
    );
  }, []);

  const onShopClicked = useCallback(() => {
    publish({ type: "main__buy_selected" }, "main_menu");
  }, []);

  function toKnightOnChainButton(
    index: number,
    knightType: string,
    frameStyle?: KnightFrameStyle
  ) {
    return (
      <>
        {convertToKnightButton(
          index,
          knightType,
          frameStyle?.buttonGradient || "",
          "btn-ie",
          "Import",
          () => {
            publish(
              {
                type: "inventory__import_clicked",
                index: index,
                knightType: knightType,
              },
              "inventory"
            );
          }
        )}
      </>
    );
  }

  function toKnightInGameButton(
    index: number,
    knightType: string,
    frameStyle?: KnightFrameStyle,
    disable?: boolean
  ) {
    switch (knightType) {
      case "non-nft":
        return (
          <>
            {convertToKnightButton(
              index,
              knightType,
              frameStyle?.buttonGradient || "",
              "btn-boost",
              "Boost",
              () => {
                onPromoteNonNFTClicked(index);
              }
            )}
          </>
        );
      case "nft":
        return (
          <>
            {convertToKnightButton(
              index,
              knightType,
              frameStyle?.buttonGradient || "",
              "btn-ie",
              "Export",
              () => {
                onExportClicked(index, knightType);
              },
              disable
            )}
          </>
        );
      case "squire":
        return (
          <>
            <div className="flex gap-2">
              {convertToKnightButton(
                index,
                knightType,
                frameStyle?.buttonGradient || "",
                "btn-boost",
                "Boost",
                () => {
                  onPromoteClicked(index);
                }
              )}

              {convertToKnightButton(
                index,
                knightType,
                smallGoldButtonStyle || "",
                "btn-ie",
                "Export",
                () => {
                  onExportClicked(index, knightType);
                }
              )}
            </div>
          </>
        );
    }
  }

  return (
    <div className="sm:pl-8 md:pl-12 lg:pl-20 xl:pl-24">
      <h1 className="sr-only">CrtyptoKnights Inventory</h1>
      <div className="w-full flex gap-2 bg-black bg-opacity-25 rounded-3xl">
        <button
          onClick={inGameSelected}
          className={`btn-menu w-1/2 lg:w-1/3 +
            ${
              inventoryState.context.selectedMenu === "inGame"
                ? "btn-active"
                : ""
            }
        `}
        id="btn-inventory-in-game"
        >
          In-Game <span className="sr-only">Inventory</span>
        </button>
        <button
          onClick={onChainSelected}
          className={`btn-menu w-1/2 lg:w-1/3 +
            ${
              inventoryState.context.selectedMenu === "onChain"
                ? "btn-active"
                : ""
            }
        `}
        id="btn-inventory-on-chain"
        >
          On-Chain <span className="sr-only">Inventory</span>
        </button>
        <div className="hidden lg:flex w-1/3"></div>
      </div>

      {inventoryState.matches("loading") && (
        <div className="flex flex-col items-center justify-center h-80">
          <Spinner className="animate-spin w-24 h-24" />
          <div className="mt-4 text-smer uppercase">Loading...</div>
        </div>
      )}

      {/* Approve import on-chain -> in-game knight */}
      {[
        "checkingApproval",
        "waitForApprove",
        "approving",
        "approved",
        "approveFailed",
        "importing",
        "waitForBlockConfirmImport",
        "importFailed",
      ].some(inventoryState.matches) && (
        <PopUpApprove
          onClose={closeModalClicked}
          onApproveClicked={approveClicked}
          onConfirmImportClicked={confirmImportClicked}
          depositStatus={inventoryState.value}
          selectedKnight={inventoryState.context.selectedKnight}
        />
      )}

      {inventoryState.matches("confirmExport") && (
        <PopUpRequestConfirm
          onClose={closeModalClicked}
          onConfirmClicked={confirmExportClicked}
          type={TransactionType.Export}
          from="CryptoKnights"
          transferTo="MetaMask"
          isShowCloseModal={true}
        />
      )}
      {inventoryState.matches("processingTransaction") && (
        <PopUpRequestConfirm
          onClose={closeModalClicked}
          onConfirmClicked={confirmImportClicked}
          type={TransactionType.Import}
          from="MetaMask"
          transferTo="CryptoKnights"
          isLoading
        />
      )}
      {[
        "processingWithdraw",
        "createWithdrawTransaction",
        "waitForBlockConfirmWithdraw",
      ].some(inventoryState.matches) && (
        <PopUpRequestConfirm
          onClose={closeModalClicked}
          onConfirmClicked={confirmImportClicked}
          type={TransactionType.Export}
          from="CryptoKnights"
          transferTo="MetaMask"
          isLoading
        />
      )}
      {inventoryState.matches("transferSuccessful") && (
        <PopUpTransaction
          transactionStatus="successful"
          onClose={closeModalClicked}
          itemsType="NFT"
        />
      )}
      {inventoryState.matches("transferfailed") && (
        <PopUpTransaction
          transactionStatus="declined"
          onClose={closeModalClicked}
          itemsType="NFT"
        />
      )}
      {[
        "selectNonNftKnight",
        "selectSquire",
        "confirmPromote",
        "promoting",
        "promoteSuccessful",
        "promoteFailed",
      ].some(inventoryState.matches) && (
        <div>
          <div className="pt-6 px-1 pb-3 lg:w-3/4">
            Boost a knight, by linking a knight with a squire. Boosted knights will earn <strong>RUBY</strong> in PvP battle, and may be exported to your personal wallet.
          </div>
          {inventoryState.context.selectingLast === "squire" && (
            <div>
              <div className="flex gap-4 mb-6 px-1 items-center">
                <p className="text-smer">
                  Select squire to link for boosting.
                  {/*
                  ({inventoryState.context.selectedSquire ? 1 : 0}/1)
                  */}
                </p>

                <div className="items-center ml-auto flex gap-2">
                  <button
                    className="btn-ie"
                    id="btn-inventory-promote-1-cancel"
                    onClick={onCancelSelectedSquireClicked}
                  >
                    Cancel
                  </button>

                  <button
                    disabled={
                      inventoryState.context.selectedSquire === undefined
                    }
                    className="btn-boost"
                    id="btn-inventory-promote-1-confirm"
                    onClick={onConfirmSelectedSquireClicked}
                  >
                    Confirm
                  </button>
                </div>
              </div>
              { !inventoryState.context.squires.length ?
                <div className="px-1">
                  <p>You do not have any <strong>squires</strong>! You may mint a <strong>new squire</strong> in the <a href="#" onClick={onShopClicked}>Shop</a>.</p>
                </div>
              :
                <div className="grid grid-cols-2 gap-2 sm:gap-5 lg:grid-cols-3 xl:gap-6">
                  {inventoryState.context.squires.map((i, index) => {
                    return (
                      <KnightFrame
                        key={index + inventoryState.context.selectedMenu}
                        level={i.level}
                        isSelected={
                          inventoryState.context.selectedSquire?.displayIndex ===
                          index
                        }
                        onSelected={() => selectedSquireClicked(index)}
                        // imgUri={i.imgUrl}
                        name={i.name}
                        knightType="squire"
                        knightStatus={
                          inventoryState.context.selectedMenu === "inGame"
                            ? "in-game"
                            : "on-chain"
                        }
                        tokenId={i.tokenId}
                      ></KnightFrame>
                    );
                  })}
                </div>
              }
            </div>
          )}
          {inventoryState.context.selectingLast === "nonnft" && (
            <div>
              <div className="flex gap-4 mb-6 px-1 items-center">
                <p className="text-smer">
                  Select knight to link for boosting.
                  {/*
                  ({inventoryState.context.selectedNonNftKnight ? 1 : 0}/1)
                  */}
                </p>

                <div className="items-center ml-auto flex gap-2">
                  <button
                    className="btn-ie"
                    id="btn-inventory-promote-2-cancel"
                    onClick={onCancelSelectedNonNFTClicked}
                  >
                    Cancel
                  </button>

                  <button
                    disabled={
                      inventoryState.context.selectedNonNftKnight === undefined
                    }
                    className="btn-boost"
                    id="btn-inventory-promote-2-confirm"
                    onClick={onConfirmSelectedNonNFTClicked}
                  >
                    Confirm
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 sm:gap-5 lg:grid-cols-3 xl:gap-6">
                {inventoryState.context.nonNftKnights.map((i, index) => {
                  return (
                    <KnightFrame
                      key={index + inventoryState.context.selectedMenu}
                      level={i.level}
                      isSelected={
                        inventoryState.context.selectedNonNftKnight
                          ?.displayIndex === index
                      }
                      onSelected={() => selectedNonNFTClicked(index)}
                      // imgUri={i.imgUrl}
                      name={i.name}
                      knightType="non-nft"
                      knightStatus={
                        inventoryState.context.selectedMenu === "inGame"
                          ? "in-game"
                          : "on-chain"
                      }
                    ></KnightFrame>
                  );
                })}
                <KnightFrame
                  key={
                    inventoryState.context.nonNftKnights.length +
                    1 +
                    inventoryState.context.selectedMenu
                  }
                  level={"0"}
                  isSelected={
                    inventoryState.context.selectedNonNftKnight
                      ?.displayIndex ===
                    inventoryState.context.nonNftKnights.length + 1
                  }
                  onSelected={() =>
                    selectedNonNFTClicked(
                      inventoryState.context.nonNftKnights.length + 1
                    )
                  }
                  // imgUri={i.imgUrl}
                  name={"New Knight"}
                  knightType="non-nft-new"
                  knightStatus={
                    inventoryState.context.selectedMenu === "inGame"
                      ? "in-game"
                      : "on-chain"
                  }
                ></KnightFrame>
              </div>
            </div>
          )}
        </div>
      )}
      {[
        "confirmPromote",
        "promoting",
        "promoteSuccessful",
        "promoteFailed",
      ].some(inventoryState.matches) && (
        <PopUpPromote
          onClose={closeModalClicked}
          onConfirmClicked={confirmPromoteClicked}
          promoteState={inventoryState.value}
          isShowCloseModal
          knight={inventoryState.context.selectedNonNftKnight}
          squire={inventoryState.context.selectedSquire}
        />
      )}
      {[
        "idle",
        "confirmRequest",
        "confirmExport",
        "processingTransaction",
        "processingWithdraw",
        "createWithdrawTransaction",
        "waitForBlockConfirmWithdraw",
        "transferSuccessful",
        "transferfailed",
        "checkingApproval",
        "waitForApprove",
        "approving",
        "approved",
        "approveFailed",
        "importing",
        "waitForBlockConfirmImport",
        "importFailed",
      ].some(inventoryState.matches) && (
        <div>
          <div className="py-6 px-1 lg:w-3/4">
            { inventoryState.context.selectedMenu === 'inGame' &&
              <div className="mb-3">You may boost your <strong>in-game knights</strong>. Boosted knights will earn <strong>RUBY</strong> in PvP battle, and may be exported to your personal wallet.</div>
            }
            { inventoryState.context.selectedMenu === 'onChain' &&
              <div className="mb-3">You may import your <strong>on-chain knights</strong>. Imported knights will be available to equip and battle in-game.</div>
            }
            <div className="text-smer"><strong>{inventoryState.context.knights.length + inventoryState.context.squires.length + inventoryState.context.nonNftKnights.length}</strong> Items</div>
          </div>
          <div className="grid grid-cols-2 gap-2 sm:gap-5 lg:grid-cols-3 xl:gap-6">
            {inventoryState.context.knights.map((i, index) => {
              const knightFrameStyle = knightTypeToStyle("nft");
              const isDisable =
                inventoryState.context.currentKnight.current_knight_token_id ===
                  i.tokenId &&
                inventoryState.context.currentKnight.current_knight_token_contract?.toLowerCase() ===
                  i.token_contract?.toLowerCase();

              return (
                <KnightFrame
                  key={index + inventoryState.context.selectedMenu}
                  level={i.level}
                  // imgUri={i.imgUrl}
                  name={i.name}
                  knightButtons={
                    inventoryState.context.selectedMenu === "inGame"
                      ? toKnightInGameButton(
                          index,
                          "nft",
                          knightFrameStyle,
                          isDisable
                        )
                      : toKnightOnChainButton(index, "nft", knightFrameStyle)
                  }
                  knightType="nft"
                  knightStatus={
                    inventoryState.context.selectedMenu === "inGame"
                      ? "in-game"
                      : "on-chain"
                  }
                  tokenId={i.tokenId}
                ></KnightFrame>
              );
            })}
            {inventoryState.context.squires.map((i, index) => {
              const knightFrameStyle = knightTypeToStyle("squire");
              return (
                <KnightFrame
                  key={index + inventoryState.context.selectedMenu}
                  level={i.level}
                  // imgUri={i.imgUrl}
                  name={i.name}
                  knightButtons={
                    inventoryState.context.selectedMenu === "inGame"
                      ? toKnightInGameButton(index, "squire", knightFrameStyle)
                      : toKnightOnChainButton(index, "squire", knightFrameStyle)
                  }
                  knightType="squire"
                  knightStatus={
                    inventoryState.context.selectedMenu === "inGame"
                      ? "in-game"
                      : "on-chain"
                  }
                  tokenId={i.tokenId}
                ></KnightFrame>
              );
            })}
            {inventoryState.context.nonNftKnights.map((i, index) => {
              const knightFrameStyle = knightTypeToStyle("non-nft");
              return (
                <KnightFrame
                  key={index + inventoryState.context.selectedMenu}
                  level={i.level}
                  // imgUri={i.imgUrl}
                  name={i.name}
                  knightButtons={
                    inventoryState.context.selectedMenu === "inGame"
                      ? toKnightInGameButton(index, "non-nft", knightFrameStyle)
                      : toKnightOnChainButton(
                          index,
                          "non-nft",
                          knightFrameStyle
                        )
                  }
                  knightType="non-nft"
                  knightStatus={
                    inventoryState.context.selectedMenu === "inGame"
                      ? "in-game"
                      : "on-chain"
                  }
                ></KnightFrame>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

function convertToKnightButton(
  index: number,
  knightType: string,
  buttonStyle: string,
  btnClass: string,
  btnAction: string,
  actionClicked: () => void,
  disable?: boolean
) {
  const disableClass = disable ? " brightness-50 cursor-default" : "";

  return (
    <button
      className={btnClass + disableClass}
      id={"btn-inventory-" + slug(btnAction) + "-" + slug(knightType) + "-" + index}
      onClick={disable ? () => {} : actionClicked}
    >
      {btnAction}
    </button>
  );
}
