import Web3 from "web3";
import { assign, createMachine } from "xstate";
import { INVENTORY_PATH } from "../env";
import {
  ConvertToFixBitPrice,
  priceString,
  toFixedWithoutZeros,
} from "../helpers/convertWei";
import {
  gasOracle
} from "../helpers/gasOracle";
import { fetchX } from "../helpers/fetchX";
import { parseJwt } from "../helpers/jwtParser";
import { createERC20, createGameCustodialContract } from "../web3";
type Context = {
  walletAddress: string;
  playerEmail: string;
  inGameBalance: string; // need to find how to get this
  onChainBalance: string;
  decimal: number;
  allowance: string; //wei
  transactionValue: string; // normal like 0.4 ruby not wei
  status: string | null;
  erc20: string;
  gameCustodial: string;
  finality_blocks: number;
};

export const depositMachine = createMachine(
  {
    id: "deposit",
    tsTypes: {} as import("./deposit_machine.typegen").Typegen0,
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
    },
    context: {
      walletAddress: "",
      transactionValue: "",
      playerEmail: "",
      onChainBalance: "",
      allowance: "",
      inGameBalance: "",
      decimal: 18,
      status: null,
      erc20: "",
      gameCustodial: "",
      finality_blocks: 1,
    },
    initial: "waitForContract",
    on: {
      close_popup_modal: {
        target: "idle",
      },
      swap__transaction_clicked: {
        target: "loading",
      },
    },
    states: {
      waitForContract: {
        on: {
          has_contract: {
            target: "waitForStartSignal",
            actions: "updateContractAddr",
          },
        },
      },
      waitForStartSignal: {
        on: {
          deposit_start: {
            target: "loading",
            actions: "updateWallet",
          },
        },
      },
      loading: {
        // fetch every thing by wallet address etc.ruby balance on chain , game info
        invoke: {
          id: "fetchinfo",
          src: "fetchInfo",
          onDone: {
            target: "idle",
            actions: "updateInfo",
          },
          onError: {
            target: "error",
          },
        },
      },
      idle: {
        // transfer click
        on: {
          deposit_submitted: {
            target: "transfering",
            actions: "updateTransactionValue",
          },
          approve_request: {
            target: "approving",
            actions: "updateTransactionValue",
          },
        },
      },
      transfering: {
        invoke: {
          id: "deposit-ruby",
          src: "depositRuby",
          onDone: {
            target: "waitForBlockConfirmation",
          },
          onError: {
            target: "transferError",
          },
        },
      },
      waitForBlockConfirmation: {
        invoke: {
          id: "waitforXblock",
          src: "waitBlockConfirm",
          onDone: {
            target: "transfered",
          },
          onError: {
            target: "transferError",
          },
        },
      },
      approving: {
        invoke: {
          id: "approve",
          src: "approveRequest",
          onDone: {
            target: "loading",
          },
          onError: {
            target: "loading",
          },
        },
      },
      transfered: {
        on: {
          close_popup_modal: {
            target: "loading",
          },
          main__deposit_cancel: {
            target: "waitForStartSignal",
          },
        },
      },
      transferError: {
        on: {
          main__deposit_cancel: {
            target: "waitForStartSignal",
          },
          close_popup_modal: {
            target: "idle",
          },
        },
      },
      error: {},
    },
  },
  {
    services: {
      approveRequest: async (context) => {
        const contract = await createERC20(context.erc20);

        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })

        const approve = await contract.methods
          .approve(
            context.gameCustodial,
            ConvertToFixBitPrice(context.transactionValue, context.decimal)
          )
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(function (result: any) {
            return JSON.stringify(result);
          });
        return approve;
      },

      depositRuby: async (context) => {
        const gameCustodialContract = await createGameCustodialContract(
          context.gameCustodial
        );

        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })

        const deposit = await gameCustodialContract.methods
          .deposit20(context.erc20, context.transactionValue)
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(function (result: any) {
            return result;
          });
        return true;
      },
      waitBlockConfirm: async (context) => {
        let web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8545");
        let isFirstBlockAppear = false;
        let qty = 0;
        var subscription = web3.eth
          .subscribe("newBlockHeaders", function (error, result) {
            if (!error) {
              qty += 1;
              isFirstBlockAppear = true;
              return;
            }
            console.error(error);
          })
          .on("error", console.error);
        console.log("finality ", context.finality_blocks);
        while (isFirstBlockAppear === false) {
          await new Promise((r) => setTimeout(r, 2000));
          while (qty <= context.finality_blocks) {
            await new Promise((r) => setTimeout(r, 2000));
            console.log("block added", qty);
          }
        }
        subscription.unsubscribe(function (error, success) {
          if (success) {
            console.log("Successfully unsubscribed!");
          } else {
            console.log("error", error);
          }
        });
      },
      fetchInfo: async (context) => {
        const jwt = localStorage.getItem("CustomerJWT");
        const data = parseJwt(jwt || "");
        const email = data.email;
        const resp = await fetchX(
          `${INVENTORY_PATH}/account/${data.identity_id}/RUBY`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + jwt,
            },
          }
        );
        const contract = await createERC20(context.erc20);
        const allowance = await contract.methods
          .allowance(context.walletAddress, context.gameCustodial)
          .call()
          .then(function (result: any) {
            return result;
          })
          .catch(() => console.log);
        const rubyBalance = await contract.methods
          .balanceOf(context.walletAddress)
          .call()
          .then(function (result: any) {
            return result;
          });
        const decimal = await contract.methods
          .decimals()
          .call()
          .then(function (result: any) {
            return result;
          });
        return {
          allowance: priceString(allowance, parseInt(decimal)),
          inGameBalance: toFixedWithoutZeros(
            priceString(resp.balance.toString(), parseInt(decimal)),
            4
          ),
          onChainBalance: toFixedWithoutZeros(
            priceString(rubyBalance, parseInt(decimal)),
            4
          ),
          decimal: decimal,
          playerEmail: email,
        };
      },
    },
    actions: {
      updateContractAddr: assign((context, event: any) => {
        return {
          ...context,
          erc20: event.payload.ruby,
          gameCustodial: event.payload.gameCustodial,
          finality_blocks: event.payload.finality_blocks,
        };
      }),
      updateTransactionValue: assign((context, event: any) => {
        return { ...context, transactionValue: event.value };
      }),
      updateInfo: assign((context, event: any) => {
        return {
          ...context,
          inGameBalance: event.data.inGameBalance,
          allowance: event.data.allowance,
          onChainBalance: event.data.onChainBalance,
          playerEmail: event.data.playerEmail,
        };
      }),
      updateWallet: assign((context, event) => {
        return { ...context, walletAddress: event.wallet };
      }),
    },
  }
);
