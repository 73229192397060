import { useCallback } from "react";
import { ReactComponent as Ruby } from "../../svgs/ruby.svg";
const slug = require('github-slugger').slug;

type Props = {
  currency: string;
  name: string;
  balance: string;
  srcImg?: any;
  btnText?: string;
  style?: React.CSSProperties;
  btnClicked: () => void;
  unstakeClicked?: () => void;
};

function BalanceFrame(props: Props) {
  const onButtonClicked = useCallback(() => {
    props.btnClicked();
  }, [props]);
  return (
    <div
      className="flex flex-col relative bg-white bg-opacity-10 rounded-2xl"
      style={props.style}
    >  
      <div className={`bg-gradient-to-tl rounded-t-2xl overflow-hidden ${props.btnText === 'Stake' ? "to-orange-400 from-pink-700" : "from-green-400 to-cyan-700"}`}>
        <div className="relative pt-full">
          <div className="absolute inset-0 flex items-center p-5">
            <Ruby className={props.btnText === 'Stake' ? "rotate-180 hue-rotate-240" : ""} />
            <div className={`absolute bottom-6 left-6 py-1 px-3 text-smer bg-black bg-opacity-50 rounded-full`}>
              { props.btnText === 'Stake' ?
                <span>RUBY for xRUBY</span>
              :
                <span><span className="font-bold">{props.balance}</span> <span className="uppercase">{props.currency}</span></span>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <button
          className={props.btnText === 'Stake' ? "btn-boost" : "btn-ie"}
          id={"btn-wallet-" + slug(props.btnText)}
          onClick={onButtonClicked}
        >
          {props.btnText}
        </button>
        { props.btnText === 'Deposit' &&
          <button
            className="mt-2 btn-ie"
            disabled
          >
            Trade
          </button>
        }
        { props.btnText === 'Stake' &&
          <button
            className="mt-2 btn-ie"
            onClick={props.unstakeClicked}
          >
            Unstake
          </button>
        }
        </div>
    </div>
  );
}
export default BalanceFrame;
