import React, { useCallback } from "react";
import { publish } from "../app_events";
import { shortenWalletAddress } from "../helpers/walletAddress";
import { ReactComponent as Chevron } from "../svgs/chevron.svg";
import { ReactComponent as Hamburger } from "../svgs/hamburger.svg";

type Props = {
  walletAddress?: string;
  isShowPopup?: boolean;
  onExpandClicked: (e: React.MouseEvent) => void;
};

function Wallet(props: Props) {
  const onExpandClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      props.onExpandClicked(e);
    },
    [props]
  );
  const onConnectWalletClicked = useCallback(() => {
    publish({ type: "login__connect_clicked" }, "login");
  }, []);
  return (
    <div>
      {props.walletAddress && (
        <button
          className={
            "connected btn-dim btn-circle-scrolled gap-3 w-auto normal-case"
          }
          onClick={onExpandClicked}
          title="Toggle menu"
        >
          <div className="no-scrolled font-bold">
            {shortenWalletAddress(props.walletAddress, 6)}
          </div>
          <div className={"no-scrolled transition-all duration-300" + (props.isShowPopup ? " rotate-180" : "")}>
            <Chevron className="w-4" />
          </div>
          <Hamburger className="hidden on-scrolled w-4" />
        </button>
      )}
      {!props.walletAddress && (
        <button
          className="btn-dim"
          onClick={onConnectWalletClicked}
        >
          Connect Wallet <span className="sr-only">to CryptoKnights</span>
        </button>
      )}
    </div>
  );
}
export default Wallet;
