export type KnightFrameStyle = {
  circleStyle: string;
  borderBottomStyle: string;
  borderStyle: string;
  buttonGradient: string;
};

export const goldenSet = {
  circleStyle: "borderGoldCircle border-2 p-0.5",
  borderBottomStyle: "borderBottomGoldGradient",
  borderStyle: "borderLinearGoldGradient",
  buttonGradient:
    "bg-gradient-to-r from-[#AB800F] via-[#B2891C] to-[#AB800F]",
};

export const silverSet = {
  circleStyle: "borderSilverCircle border-2 p-0.5",
  borderBottomStyle: "borderBottomSilverGradient",
  borderStyle: "borderLinearSilverGradient",
  buttonGradient:
    "bg-gradient-to-r from-[#75684E] to-[#827269]",
};

export const copperSet = {
  circleStyle: "borderCopperCircle p-0.5",
  borderBottomStyle: "borderBottomCopperGradient",
  borderStyle: "borderLinearCopperGradient",
  buttonGradient:
    "bg-gradient-to-r from-[#752107] via-[#9C4125] to-[#631301]",
};

export const greenSet = {
  circleStyle: "borderGreenCircle p-0.5",
  borderBottomStyle: "borderBottomGreenGradient",
  borderStyle: "borderLinearGreenGradient",
  buttonGradient:
    "bg-gradient-to-r from-[#077521] via-[#259C41] to-[#016313]",
};

export const smallSilverButtonStyle =
  "bg-gradient-to-r from-[#75684E] to-[#827269]";
export const smallGoldButtonStyle =
  "bg-gradient-to-r from-[#AB800F] via-[#B2891C] to-[#AB800F]";

export const knightTypeToStyle = (knightType: string) => {
  switch (knightType) {
    case "nft":
      return goldenSet;
    case "squire":
      return silverSet;
    case "non-nft":
      return copperSet;
    case "non-nft-new":
      return greenSet;
  }
};
