import { useActor } from "@xstate/react";
import { useCallback } from "react";
import { ActorRef, EventFrom } from "xstate";
import LoginTemplate from "../components/LoginTemplate";
import OTPInput from "../components/OTPInput/Index";
import ResendOTPComponent from "../components/ResendOTP/Index";
import otpMachine from "./otp_machine";
function loadingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      width="48"
      fill="#ffffff"
    >
      <path d="M42 23.6H38.95Q38.7 21.85 38.025 20.1Q37.35 18.35 36.2 16.7L38.4 14.55Q39.85 16.35 40.75 18.625Q41.65 20.9 42 23.6ZM26.3 44V40.95Q28.05 40.75 29.775 40.05Q31.5 39.35 33.1 38.15L35.25 40.35Q33.2 41.9 30.925 42.8Q28.65 43.7 26.3 44ZM38.4 37.2 36.2 35Q37.35 33.35 38.025 31.6Q38.7 29.85 38.95 28.1H42Q41.6 30.65 40.725 32.975Q39.85 35.3 38.4 37.2ZM21.85 44Q15 43.15 10.55 38Q6.1 32.85 6.1 25.85Q6.1 18.3 11.325 13.075Q16.55 7.85 24.1 7.85H25.1L21.15 3.9L23.35 1.7L31 9.35L23.35 17L21.15 14.8L25.1 10.85H24.1Q17.75 10.85 13.425 15.175Q9.1 19.5 9.1 25.85Q9.1 31.7 12.725 35.85Q16.35 40 21.85 40.95Z" />
    </svg>
  );
}
export default function OTP(props: {
  otpActorRef: ActorRef<EventFrom<typeof otpMachine>>;
}) {
  const [otpState, send] = useActor(props.otpActorRef);

  const onOTPInput = useCallback(
    (otpInput: string) => {
      if (otpInput.length === 6)
        send({ type: "login__otp_submitted", otp: otpInput });
    },
    [send]
  );
  const onOTPpaste = useCallback(
    (otpPaste: string) => {
      if (otpPaste.length === 6)
        send({ type: "login__otp_submitted", otp: otpPaste });
    },
    [send]
  );
  const onCancelClicked = useCallback(() => {
    send({ type: "login__otp_cancel" });
  }, [send]);
  const onResendClicked = useCallback(() => {
    send({ type: "login__otp_resend" });
  }, [send]);
  return (
    <div className="mt-8 flex flex-col">
      {/* <h2 className="mx-auto text-lger font-bold">Email Verification</h2>
      {["inputOTP", "verifyOTP", "invalidOTP", "requestOTP"].some(
        otpState.matches
      ) && (
        <div className="text-center mt-6 mb-8 whitespace-pre-line ">
          <p className="text-sm sm:text-lg sm:w-1/2 mx-auto">
            We have sent 6-digits verification code to your email{" "}
            <span className="text-gold-400">{otpState.context.email}</span> ( ref
            code:
            {otpState.context.otpInfo ? otpState.context.otpInfo.refId : "----"}
            ), please check & fill in the code here.
          </p>
        </div>
      )} */}
      {["inputOTP", "requestOTP", "invalidOTP"].some(otpState.matches) && (
        <LoginTemplate
          title="Verify email"
          description={
            <>
              A 6-digit verification code has been sent to your email{" "}
              <span className="text-gold-400">{otpState.context.email}</span>{" "}
              (ref code:
              <span className="text-gold-400">
                {" "}
                {otpState.context.otpInfo
                  ? otpState.context.otpInfo.refId
                  : "----"}
              </span>)
            </>
          }
          contents={
            <OTPInput
              autoFocus
              isNumberInput
              length={6}
              onChangeOTP={onOTPInput}
              onPasteOTP={onOTPpaste}
              disabled={otpState.matches("verifyOTP")}
            ></OTPInput>
          }
        ></LoginTemplate>
      )}
      {otpState.matches("verifyOTP") && (
        <LoginTemplate
          description={
            <>
              A 6-digit verification code has been sent to your email{" "}
              <span className="text-gold-400">{otpState.context.email}</span>{" "}
              (ref code:
              {otpState.context.otpInfo
                ? otpState.context.otpInfo.refId
                : "----"})
            </>
          }
          contents={
            <div className="pt-4 mx-auto flex items-center justify-center">
              {loadingIcon()}
            </div>
          }
        ></LoginTemplate>
      )}
      {otpState.context.isError && (
        <div className="pt-1 text-center text-red-500 text-smer">
          {otpState.context.errorDetail}
        </div>
      )}
      <div className="flex justify-center mt-8">
        {otpState.context.otpInfo && !otpState.matches("requestOTP") && (
          <ResendOTPComponent
            onResendClicked={() => onResendClicked}
            resendAfter={otpState.context.otpInfo.resendAfter}
          />
        )}
        {(!otpState.context.otpInfo || otpState.matches("requestOTP")) && (
          <div className="cursor-pointer text-smer">
            <span>Didn't receive OTP? </span>
            <span className="text-gray-400 font-bold">RESEND</span>
          </div>
        )}
      </div>
      {["inputOTP", "invalidOTP", "requestOTP", "verifyOTP"].some(
        otpState.matches
      ) && (
        <button
          className="btn-ie max-w-min mt-4 mx-auto"
          onClick={onCancelClicked}
        >
          Cancel
        </button>
      )}
    </div>
  );
}
