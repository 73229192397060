import { useCallback, useRef, useState } from "react";
import { useActor } from "@xstate/react";

import ruby from "../svgs/ruby.svg";
import { PopUpTransaction } from "../components/PopUpTransaction";
import ActionFrame from "../components/ActionFrame/Index";
import { actorsInterpreter } from "../actors_machine";
import { publish } from "../app_events";
import { ConvertToFixBitPrice } from "../helpers/convertWei";
import { useWalletAddress } from "../hooks/useWalletAddress";
import { ReactComponent as Logo } from "../svgs/logo.svg";
import { ReactComponent as Chevron } from "../svgs/chevron.svg";
import { ReactComponent as MM } from "../svgs/metamask.svg";
import { ReactComponent as SwapArrow } from "../svgs/swapArrow.svg";

const { rubyWithDraw } = actorsInterpreter.getSnapshot().context;

export default function Withdraw() {
  const [withdrawState] = useActor(rubyWithDraw);
  const walletAddress = useWalletAddress();
  const [transactionValue, setTransactionValue] = useState("0");
  const transactionValueRef = useRef<HTMLInputElement>(null);
  const onUpdateTransactionValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTransactionValue(String(Math.floor(parseFloat(e.target.value))));
    },
    []
  );
  const onCancelClicked = useCallback(() => {
    publish({ type: "main__withdraw_cancel" }, "withdraw");
  }, []);
  const onClosePopUp = useCallback(() => {
    publish({ type: "close_popup_modal" }, "withdraw");
  }, []);
  const swapclicked = useCallback(() => {
    publish({ type: "swap__transaction_clicked" }, "withdraw");
    publish({ type: "deposit_start", wallet: walletAddress }, "withdraw");
  }, [walletAddress]);
  const onTransferClicked = useCallback(async () => {
    const transactionNormal = transactionValueRef.current?.value;
    const transactionWei = ConvertToFixBitPrice(
      transactionNormal ? transactionNormal : "",
      18
    );
    console.log("send traction with ", transactionWei);
    publish({ type: "withdraw__submitted", value: transactionWei }, "withdraw");
  }, []);
  return (
    <>
      {withdrawState.matches("waitForContract") && (
        <div>Can't Get Contract Address</div>
      )}
      {withdrawState.matches("transfered") && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <PopUpTransaction
            transactionStatus="successful"
            onClose={onClosePopUp}
            itemsType="withdraw"
          ></PopUpTransaction>
        </div>
      )}
      {withdrawState.matches("transferError") && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <PopUpTransaction
            transactionStatus="declined"
            onClose={onClosePopUp}
            itemsType="withdraw"
          ></PopUpTransaction>
        </div>
      )}
      {withdrawState.matches("waitForStartSignal") && <div></div>}
      {withdrawState.matches("loading") && <div></div>}
      {["idle", "approving", "transfered", "transferError"].some(
        withdrawState.matches
      ) && (
        <div className="flex flex-col mx-auto max-w-lg">
          <div className="relative">
            <button className="btn-dim btn-circle absolute" onClick={onCancelClicked}>
              <Chevron className="rotate-90 w-4" />
            </button>
            <h1 className="text-center">
              Withdraw RUBY
            </h1>
          </div>
          <div className="flex flex-col mt-8">
            <div>
              <ActionFrame
                icon=""
                balance={withdrawState.context.inGameBalance}
                direction="From"
                walletType="inGame"
                description={withdrawState.context.playerEmail}
              />
            </div>
            <div className="relative z-10 w-min -my-5 mx-auto bg-black rounded-full">
              <button className="btn-dim btn-circle" onClick={swapclicked}>
                <SwapArrow className="w-4" />
              </button>
            </div>
            <div>
              <ActionFrame
                icon=""
                balance={withdrawState.context.onChainBalance}
                direction="To"
                walletType="onChain"
                description={withdrawState.context.walletAddress}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <div className="flex flex-col items-center">
                <input
                  value={transactionValue || ""}
                  onInput={onUpdateTransactionValue}
                  ref={transactionValueRef}
                  className="w-1/3 h-12 px-4 text-lger text-center bg-gradient-to-tl from-black to-gray-800 border-gold-400 border-b-2 rounded-full"
                  type="number"
                ></input>
                <div
                  className={
                    "mt-1 text-smer" +
                    (parseFloat(withdrawState.context.inGameBalance) <
                    parseFloat(transactionValue)
                      ? "text-red-500"
                      : "text-gray-300")
                  }
                >
                  {parseFloat(withdrawState.context.inGameBalance) <
                  parseFloat(transactionValue)
                    ? "insufficient balance"
                    : "please specify coin amount"}
                </div>
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                className="btn-ie"
                onClick={onCancelClicked}
              >
                Cancel
              </button>
              <button
                className={
                  (parseFloat(transactionValue) <= 0 ||
                  transactionValue === "" ||
                  parseFloat(withdrawState.context.inGameBalance) <
                    parseFloat(transactionValue)
                    ? "disabledBtn "
                    : "") +
                    "btn-go"
                }
                onClick={() => {
                  onTransferClicked();
                }}
                disabled={
                  parseFloat(transactionValue) <= 0 ||
                  transactionValue === "" ||
                  parseFloat(withdrawState.context.inGameBalance) <
                    parseFloat(transactionValue)
                }
              >
                Transfer
              </button>
            </div>
          </div>
        </div>
      )}
      {["waitForBlockConfirmation", "transfering"].some(
        withdrawState.matches
      ) && (
        <div className="flex flex-col gap-y-2 pt-20 items-center w-full">
          <img alt="ruby" src={ruby} className="w-16 h-16"></img>
          <div className="flex flex-col">
            <h1><span className="uppercase">Ruby</span> Withdrawal</h1>
            <p className="text-lger mx-auto">processing...</p>
          </div>
          <div className="flex flex-row items-center gap-4 md:gap-6 mt-8">
            <Logo className="w-16 h-16" />
            <Chevron className="-rotate-90 w-8" />
            <MM className="w-16 h-16" />
          </div>
        </div>
      )}
      {withdrawState.matches("transfered") && <div></div>}
      {withdrawState.matches("error") && (
        <div className="text-center">
          <h2 className="title">Error!</h2>
          <p>There was an unexpected error. <br/>Please reload the page.</p>
        </div>
      )}
    </>
  );
}
