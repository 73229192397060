import { inspect } from "@xstate/inspect";
import {
  ActorRefFrom,
  assign,
  createMachine,
  interpret,
  send,
  spawn,
} from "xstate";
import { registerActor } from "./app_events";
import { loginMachine } from "./login/login_machine";
import { depositMachine } from "./rubyDeposit/deposit_machine";
import { mainMachine } from "./main_machine";
import { buyMachine } from "./buy/buy_machine";
import { inventoryMachine } from "./inventory/inventory_machine";
import { walletMachine } from "./myWallet/wallet_machine";
import { withdrawMachine } from "./rubyWithdraw/withdraw_machine";
type Context = {
  main: ActorRefFrom<typeof mainMachine>;
  login: ActorRefFrom<typeof loginMachine>;
  rubyDeposit: ActorRefFrom<typeof depositMachine>;
  rubyWithDraw: ActorRefFrom<typeof withdrawMachine>;
  inventory: ActorRefFrom<typeof inventoryMachine>;
  buy: ActorRefFrom<typeof buyMachine>;
  wallet: ActorRefFrom<typeof walletMachine>;
};

const actorsMachine = createMachine(
  {
    id: "actors",
    schema: {
      context: {} as Context,
      events: {} as {
        type: "actor_context__publish";
        event: AppEvent;
        actor: string;
        source: string;
      },
    },
    context: {} as Context,
    states: {
      init: {
        entry: ["createActors"],
        always: "ready",
      },
      ready: {
        on: {
          actor_context__publish: {
            actions: ["publish"],
          },
        },
      },
    },
    initial: "init",
  },
  {
    actions: {
      createActors: assign((c) => {
        const mainActor = spawn(mainMachine, mainMachine.id);
        registerActor(mainMachine.events, mainActor.id);

        const loginActor = spawn(loginMachine, loginMachine.id);
        registerActor(loginMachine.events, loginActor.id);

        const inventoryActor = spawn(inventoryMachine, inventoryMachine.id);
        registerActor(inventoryMachine.events, inventoryActor.id);

        const depositActor = spawn(depositMachine, depositMachine.id);
        registerActor(depositMachine.events, depositActor.id);

        const withdrawActor = spawn(withdrawMachine, withdrawMachine.id);
        registerActor(withdrawMachine.events, withdrawActor.id);

        const buyActor = spawn(buyMachine, buyMachine.id);
        registerActor(buyMachine.events, buyActor.id);

        const walletActor = spawn(walletMachine, walletMachine.id);
        registerActor(walletMachine.events, walletActor.id);

        return {
          ...c,
          main: mainActor,
          login: loginActor,
          rubyDeposit: depositActor,
          rubyWithDraw: withdrawActor,
          inventory: inventoryActor,
          buy: buyActor,
          wallet: walletActor,
        };
      }),
      publish: send((_, e) => e.event, { to: (_, e) => e.actor }),
    },
  }
);
if (process.env.NODE_ENV === "development") {
  inspect({
    // options
    // url: 'https://statecharts.io/inspect', // (default)
    iframe: false, // open in new window
  });
}
export const actorsInterpreter = interpret(actorsMachine, {
  devTools: process.env.NODE_ENV === "development",
});
actorsInterpreter.start();
