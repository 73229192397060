import { useCallback } from "react";
import { StateValue } from "xstate";
import { publish } from "../../app_events";
import { ReactComponent as Castles } from "../../svgs/castles.svg";
import { ReactComponent as Knights } from "../../svgs/knights.svg";
import { ReactComponent as Shop } from "../../svgs/shop.svg";
import { ReactComponent as Skins } from "../../svgs/skins.svg";
import { ReactComponent as Wallet } from "../../svgs/wallet.svg";
import { ReactComponent as Weapons } from "../../svgs/weapons.svg";
import "./index.css";

export default function SideBar(props: { stateSelected: StateValue }) {
  const myInventorySelected = useCallback(() => {
    publish({ type: "main__inventory_selected" }, "main_menu");
  }, []);
  const myWalletSelected = useCallback(() => {
    publish({ type: "main__wallet_selected" }, "main_menu");
  }, []);
  const myBuySelected = useCallback(() => {
    publish({ type: "main__buy_selected" }, "main_menu");
  }, []);

  return (
    <div className="menu ml-auto">
      <ul className="bg-black bg-opacity-25 rounded-3xl">
        <li>
          <button
            onClick={myInventorySelected}
            className={`btn-menu justify-start gap-4 ${
              props.stateSelected === "inventory"
                ? "btn-active"
                : ""
            }`}
            id="btn-inventory"
          >
            <Knights className="w-4" />
            <span>Knights</span>
          </button>
        </li>
        <li>
          <button
            onClick={myWalletSelected}
            className={`btn-menu justify-start gap-4 ${
              props.stateSelected === "wallet"
                ? "btn-active"
                : ""
            }`}
            id="btn-wallet"
          >
            <Wallet className="w-4" />
            <span>Wallet</span>
          </button>
        </li>
        <li>
          <button
            className="btn-menu justify-start gap-4"
            disabled
          >
            <Weapons className="w-4" />
            <span>Weapons</span>
          </button>
        </li>
        <li>
          <button
            className="btn-menu justify-start gap-4"
            disabled
          >
            <Skins className="w-4" />
            <span>Skins</span>
          </button>
        </li>
        <li>
          <button
            className="btn-menu justify-start gap-4"
            disabled
          >
            <Castles className="w-4" />
            <span>Castles</span>
          </button>
        </li>
        <li>
          <button
            onClick={myBuySelected}
            className={`btn-menu justify-start gap-4 ${
              props.stateSelected === "buy"
                ? "btn-active"
                : ""
            }`}
            id="btn-shop"
          >
            <Shop className="w-4" />
            <span>Shop</span>
          </button>
        </li>
      </ul>
    </div>
  );
}
