import { assign, createMachine } from "xstate";
import { publish } from "../app_events";
import { API_PATH } from "../env";
import { fetchX } from "../helpers/fetchX";

type Context = {
  email: string;
  otpInfo: { refId: string; expiredAt: string; resendAfter: string };
  inputOTP: string;
  isError: boolean;
  errorDetail: string;
};

type ErrorDetail = {
  detail: string;
};

const otpMachine = createMachine(
  {
    id: "otp",
    tsTypes: {} as import("./otp_machine.typegen").Typegen0,
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
      services: {} as {
        verifyOTP: { data: string };
        requestOTP: {
          data: { refId: string; expiredAt: string; resendAfter: string };
        };
      },
    },
    context: {
      email: "",
      otpInfo: {
        refId: "",
        expiredAt: "",
        resendAfter: "",
      },
      inputOTP: "",
      isError: false,
      errorDetail: "",
    },
    initial: "inputOTP",
    on: {
      login__otp_cancel: "canceled",
    },
    states: {
      inputOTP: {
        on: {
          login__otp_submitted: { target: "verifyOTP", actions: "updateOTP" },
          login__otp_resend: "requestOTP",
        },
      },
      requestOTP: {
        invoke: {
          id: "resuqest-otp",
          src: "requestOTP",
          onDone: { target: "inputOTP", actions: "updateOTPInfo" },
        },
      },
      verifyOTP: {
        invoke: {
          id: "verify-otp",
          src: "verifyOTP",
          onDone: { actions: "updateAuthJWT", target: "success" },
          onError: {
            target: "inputOTP",
            actions: "updateError",
          },
        },
      },
      success: {
        type: "final",
        data: () => {
          return {
            otpVerified: true,
          };
        },
      },
      canceled: {
        type: "final",
        data: () => {
          return {
            otpVerified: false,
          };
        },
      },
    },
  },
  {
    services: {
      verifyOTP: async (c: Context) => {
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const result = await fetchX(`${API_PATH}/email/login`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  otp_ref_id: c.otpInfo.refId,
                  otp_value: c.inputOTP,
                  email: c.email,
                }),
              });
              resolve(result.jwt);
            } catch (error) {
              reject(error);
            }
          }, 1000);
        }) as Promise<string>;
      },
      requestOTP: async (c: Context) => {
        const result = await fetchX(`${API_PATH}/email/request-login-otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: c.email,
          }),
        });
        return {
          refId: result.ref_id,
          expiredAt: result.expired_at,
          resendAfter: result.resend_after,
        };
      },
    },
    actions: {
      updateAuthJWT: (_, event) => {
        localStorage.setItem("AuthJWT", event.data);
        console.log("auth", localStorage.getItem("AuthJWT"));
        publish({ type: "otp__otp_verified" }, "otp");
      },
      updateOTP: assign((context: Context, event) => {
        return { ...context, inputOTP: event.otp };
      }),
      updateOTPInfo: assign((context: Context, event) => {
        return {
          ...context,
          otpInfo: {
            refId: event.data.refId,
            expiredAt: event.data.expiredAt,
            resendAfter: event.data.resendAfter,
          },
        };
      }),
      updateError: assign((context: Context, event) => {
        const errorObject = event.data as ErrorDetail;
        return {
          ...context,
          isError: true,
          errorDetail: errorObject.detail,
        };
      }),
    },
  }
);

export default otpMachine;
