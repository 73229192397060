import { useEffect, useState } from "react";
export interface ResendOTPProps {
  resendAfter: string;
  onResendClicked: Function;
}

export function ResendOTPComponent(props: ResendOTPProps) {
  // Default countdown time
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [isResendable, setIsResendable] = useState(false);

  useEffect(() => {
    if (!timeLeft || timeLeft > 0) {
      const interval = setInterval(() => {
        const milliLeft =
          new Date(props.resendAfter).getTime() - new Date().getTime();

        setTimeLeft(Math.floor((milliLeft / 1000) % 60));
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setIsResendable(true);
    }
  }, [timeLeft, props.resendAfter]);

  useEffect(() => {
    const milliLeft =
      new Date(props.resendAfter).getTime() - new Date().getTime();
    setTimeLeft(Math.floor((milliLeft / 1000) % 60));
    setIsResendable(false);
  }, [props.resendAfter]);
  return (
    <div>
      {isResendable && (
        <p>
          <span>Didn't receive OTP? </span>
          <a href="#" className="text-white font-bold" onClick={props.onResendClicked()}>Resend</a>
        </p>
      )}
      {!isResendable && (
        <p>
          <span>Didn't receive OTP? </span>
          <span className="text-white font-bold">Resend in {timeLeft}s</span>
        </p>
      )}
    </div>
  );
}

export default ResendOTPComponent;
