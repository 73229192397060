import { useSelector } from "@xstate/react";
import { actorsInterpreter } from "../actors_machine";

const { login } = actorsInterpreter.getSnapshot().context;

export function useWalletAddress() {
  const walletAddress = useSelector(
    login,
    (state) => state?.context.walletAddress
  );
  return walletAddress;
}
