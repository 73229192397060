export const ConvertToFixBitPrice = (
  priceString: string,
  contractDecimals: number
) => {
  let [v, d] = priceString.split(".");
  d = d && d.length > contractDecimals ? d.slice(0, contractDecimals) : d;
  d = d ? d.replace(/0+$/, "") : "";
  const basedStr = v + d;
  const addingZero = contractDecimals - d.length;
  return basedStr.padEnd(basedStr.length + addingZero, "0");
};

export const toFixedWithoutZeros = (num: string, precision: number) =>
  parseFloat(num).toFixed(precision).replace(/\.0+$/, "");

export const priceString = (p: string, d: number) => {
  const processed =
    d === 0
      ? p
      : p.length <= d
      ? "0." + p.padStart(d, "0")
      : p.substring(0, p.length - d) +
        "." +
        p.substring(p.length - d, p.length + 1);

  return processed;
};

export const formatNumberWithCommas = (
  stringNumber: string,
  decimal: number
) => {
  const options = {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  };
  return parseFloat(stringNumber).toLocaleString("en-US", options);
};

const fixedDecimal = (decimal: number) => (data: string) => {
  const result = formatNumberWithCommas(data, decimal);
  return result.toString();
};
export const fixed2Decimal = fixedDecimal(2);

export const fixMaxDecimalPlace = (
  priceString: string,
  contractDecimals: number
) => {
  let [v, d] = priceString.split(".");
  d = d && d.length > contractDecimals ? d.slice(0, contractDecimals) : d;
  return d ? [v, d].join(".") : v;
};
