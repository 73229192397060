import { useCallback, useRef, useState } from "react";
import ruby from "../svgs/ruby.svg";
import { PopUpTransaction } from "../components/PopUpTransaction";
import ActionFrame from "../components/ActionFrame/Index";
import { useActor } from "@xstate/react";
import { actorsInterpreter } from "../actors_machine";
import { publish } from "../app_events";
import { ConvertToFixBitPrice } from "../helpers/convertWei";
import { ReactComponent as Logo } from "../svgs/logo.svg";
import { useWalletAddress } from "../hooks/useWalletAddress";
import { ReactComponent as Chevron } from "../svgs/chevron.svg";
import { ReactComponent as MM } from "../svgs/metamask.svg";
import { ReactComponent as SwapArrow } from "../svgs/swapArrow.svg";

const { rubyDeposit } = actorsInterpreter.getSnapshot().context;

export default function Deposit() {
  const [depositState] = useActor(rubyDeposit);
  const walletAddress = useWalletAddress();
  const [transactionValue, setTransactionValue] = useState("0");
  const transactionValueRef = useRef<HTMLInputElement>(null);
  const onUpdateTransactionValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTransactionValue(String(Math.floor(parseFloat(e.target.value))));
    },
    []
  );
  const onCancelClicked = useCallback(() => {
    publish({ type: "main__deposit_cancel" }, "deposit");
  }, []);
  const onApproveClicked = useCallback(() => {
    publish(
      {
        type: "approve_request",
        value: transactionValueRef.current?.value || "",
      },
      "deposit"
    );
  }, []);
  const swapclicked = useCallback(() => {
    publish({ type: "swap__transaction_clicked" }, "deposit");
    publish({ type: "withdraw_start", wallet: walletAddress }, "deposit");
  }, [walletAddress]);
  const onClosePopUp = useCallback(() => {
    publish({ type: "close_popup_modal" }, "deposit");
  }, []);
  const onTransferClicked = useCallback(async () => {
    const transactionNormal = transactionValueRef.current?.value;
    const transactionWei = ConvertToFixBitPrice(
      transactionNormal ? transactionNormal : "",
      18
    );
    console.log("send traction with ", transactionWei);
    publish({ type: "deposit_submitted", value: transactionWei }, "deposit");
  }, []);
  return (
    <>
      {depositState.matches("waitForContract") && (
        <div>Can't Get Contract Address</div>
      )}
      {depositState.matches("transfered") && (
        <div className="z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <PopUpTransaction
            transactionStatus="successful"
            onClose={onClosePopUp}
            itemsType="deposit"
          ></PopUpTransaction>
        </div>
      )}
      {depositState.matches("transferError") && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <PopUpTransaction
            transactionStatus="declined"
            onClose={onClosePopUp}
            itemsType="deposit"
          ></PopUpTransaction>
        </div>
      )}
      {depositState.matches("waitForStartSignal") && <div></div>}
      {depositState.matches("loading") && <div></div>}
      {["idle", "approving", "transfered", "transfererror"].some(
        depositState.matches
      ) && (
        <div className="flex flex-col mx-auto max-w-lg">
          <div className="relative">
            <button className="btn-dim btn-circle absolute" onClick={onCancelClicked}>
              <Chevron className="rotate-90 w-4" />
            </button>
            <h1 className="text-center">
              Deposit RUBY
            </h1>
          </div>
          <div className="flex flex-col mt-8">
            <div>
              <ActionFrame
                icon=""
                balance={depositState.context.onChainBalance}
                direction="From"
                walletType="onChain"
                description={depositState.context.walletAddress}
              />
            </div>
            <div className="relative z-10 w-min -my-5 mx-auto bg-black rounded-full">
              <button className="btn-dim btn-circle" onClick={swapclicked}>
                <SwapArrow className="w-4" />
              </button>
            </div>
            <div>
              <ActionFrame
                icon=""
                balance={depositState.context.inGameBalance}
                direction="To"
                walletType="inGame"
                description={depositState.context.playerEmail}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <div className="flex flex-col items-center">
                <input
                  value={transactionValue || ""}
                  onInput={onUpdateTransactionValue}
                  ref={transactionValueRef}
                  className="w-1/3 h-12 px-4 text-lger text-center bg-gradient-to-tl from-black to-gray-800 border-gold-400 border-b-2 rounded-full"
                  type="number"
                ></input>
                <div
                  className={
                    "mt-1 text-smer" +
                    (parseFloat(depositState.context.onChainBalance) <
                    parseFloat(transactionValue)
                      ? "text-red-500"
                      : "text-gray-300")
                  }
                >
                  {parseFloat(depositState.context.onChainBalance) <
                  parseFloat(transactionValue)
                    ? "insufficient balance"
                    : "please specify coin amount"}
                </div>
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                className="btn-ie"
                onClick={onCancelClicked}
              >
                Cancel
              </button>
              {/* not enought allow */}
              {parseFloat(depositState.context.allowance) <
              parseFloat(transactionValue) ? (
                <button
                  className="btn-go"
                  onClick={() => {
                    onApproveClicked();
                  }}
                  disabled={depositState.value === "approving"}
                >
                  {depositState.value === "approving"
                    ? "Approving"
                    : "Approve"}
                </button>
              ) : (
                <button
                  className={
                    (parseFloat(transactionValue) <= 0 ||
                    transactionValue === "" ||
                    parseFloat(depositState.context.onChainBalance) <
                      parseFloat(transactionValue)
                      ? "disabledBtn "
                      : "") +
                      "btn-go"
                  }
                  onClick={() => {
                    onTransferClicked();
                  }}
                  disabled={
                    parseFloat(transactionValue) <= 0 ||
                    transactionValue === "" ||
                    parseFloat(depositState.context.onChainBalance) <
                      parseFloat(transactionValue)
                  }
                >
                  Transfer
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {["transfering", "waitForBlockConfirmation"].some(
        depositState.matches
      ) && (
        <div className="flex flex-col gap-y-2 pt-20 items-center w-full">
          <img alt="ruby" src={ruby} className="w-16 h-16"></img>
          <div className="flex flex-col">
            <h2><span className="uppercase">Ruby</span> Deposit</h2>
            <p className="text-sm mx-auto">processing...</p>
          </div>
          <div className="flex flex-row items-center gap-4 md:gap-6 mt-8">
            <MM className="w-16 h-16" />
            <Chevron className="-rotate-90 w-8" />
            <Logo className="w-16 h-16" />
          </div>
        </div>
      )}
      {depositState.matches("error") &&
        <div className="text-center">
          <h2 className="title">Error!</h2>
          <p>There was an unexpected error. <br/>Please reload the page.</p>
        </div>
      }
    </>
  );
}
