import { CheckCircleIcon } from "@heroicons/react/solid";
import { useActor } from "@xstate/react";
import React, { useCallback, useRef, useState } from "react";
import { actorsInterpreter } from "../actors_machine";
import { useWalletAddress } from "../hooks/useWalletAddress";
import { publish } from "../app_events";
import { buttonLogin } from "../components/LoginButton";
import LoginTemplate from "../components/LoginTemplate";
import { shortenWalletAddress } from "../helpers/walletAddress";
import ck_logo from "../images/ck_logo.png";
import { ReactComponent as LinkChain } from "../svgs/linkChain.svg";
import { ReactComponent as Logo } from "../svgs/logo.svg";
import { ReactComponent as MM } from "../svgs/metamask.svg";
import { ReactComponent as Spinner } from "../svgs/spinner.svg";
import OTP from "./OTP";
import PopUpBuyKnight from "../components/PopUpBuyKnight/Index";
import KnightFrame from "../components/KnightFrame/Index";
const { login } = actorsInterpreter.getSnapshot().context;
const { buy } = actorsInterpreter.getSnapshot().context;

export default function Login() {
  const [loginState] = useActor(login);
  const emailRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  React.useEffect(() => {
    if (emailRef.current) setIsEmailValid(emailRef.current.validity.valid);
  }, [loginState]);
  const onConnectWalletClicked = useCallback(() => {
    publish({ type: "login__connect_clicked" }, "login");
  }, []);
  const onCancelClicked = useCallback(() => {
    setIsEmailValid(false);
    publish({ type: "login__otp_cancel" }, "login");
  }, []);
  const onSwitchChainClicked = useCallback(() => {
    publish({ type: "login__change_chain" }, "login");
  }, []);
  const onEmailInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (emailRef.current) setIsEmailValid(emailRef.current.validity.valid);
  }, []);
  const onEmailSubmitted = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (emailRef.current)
        publish({ type: "login__email_submitted", email }, "login");
    },
    [email]
  );

  const onConfirmLink = useCallback(() => {
    publish({ type: "login__confirm_link" }, "login");
  }, []);

  const [buyState] = useActor(buy);
  const walletAddress = useWalletAddress();
  buyState.context.walletAddress = walletAddress;
  const [buyQty, setBuyQty] = useState("1");

  const debounceInterval = 500;
  let filterTimeout: ReturnType<typeof setTimeout>;
  // Math.min to prevent value above 300
  // Math.max to prevent value below 1
  // Math.ceil to prevent value with decimals (conveniently round up)
  const updateBuyQty = (value: string):void => {
    clearTimeout(filterTimeout)
    // If there is no value, still clear out the state, but do not calculate qty, or get a quote
    if (!value) {
      setBuyQty('')
      return
    }

    const newQty = String(Math.min(Math.max(Math.ceil(parseFloat(value)), 1), 300))
    setBuyQty(newQty)
    buyState.context.buyQty = newQty

    filterTimeout = setTimeout(() => {
      publish({ type: "buy__get_buy_knight_qty_input" }, "buy")
    }, debounceInterval)
  }
  const increaseBuyQty = ():void => {
    const newQty = String(Math.min(parseFloat(buyQty) + 1, 300))
    setBuyQty(newQty)
    buyState.context.buyQty = newQty
  }
  const decreaseBuyQty = ():void => {
    const newQty = String(Math.max(parseFloat(buyQty) - 1, 1))
    setBuyQty(newQty)
    buyState.context.buyQty = newQty
  }

  const onBuyKnightClick = useCallback(() => {
    publish({ type: "buy__buy_knight_clicked" }, "buy");
  }, []);
  const buyKnightApproveClicked = useCallback(() => {
    publish({ type: "buy__knight_approve_clicked" }, "buy");
  }, []);
  const buyKnightChainMintClicked = useCallback(() => {
    publish({ type: "buy__knight_mint_chain_clicked" }, "buy");
  }, []);
  const buyKnightAccountMintClicked = useCallback(() => {
    publish({ type: "buy__knight_mint_account_clicked" }, "buy");
  }, []);
  const closeModalClicked = useCallback(() => {
    publish({ type: "buy__close_modal_clicked" }, "buy");
  }, []);


  return (
    <div>
      <div className="text-center">
        <img
          src={ck_logo}
          alt="CryptoKnights Game"
          className="block w-72 mx-auto lg:w-112"
        />
        <h1 className="title">
          <span className="sr-only">to CryptoKnights</span> Game Asset Portal
        </h1>
      </div>
      {(loginState.matches("init") ||
        loginState.matches("checkWalletStatus")) && (
        <div className="flex flex-col items-center justify-center h-80">
          <Spinner className="animate-spin w-24 h-24" />
          <div className="mt-4 text-smer uppercase">Loading...</div>
        </div>
      )}
      {(loginState.matches("disconnected") ||
        loginState.matches("reconnect")) && (
        <div className="mt-8 flex flex-col justify-center items-center">
          <p className="mb-12 text-lger text-center">
            Connect a wallet to access <br />your <strong>CryptoKnights</strong> account.
          </p>
          
          <button
            className={"btn-dim " + buttonLogin}
            onClick={onConnectWalletClicked}
          >
            <div className="flex flex-col items-center gap-5 p-5">
              <MM className="w-24" />
              <span className="text-xl">Connect MetaMask <span className="sr-only">to CryptoKnights</span></span>
            </div>
          </button>
        </div>
      )}
      {loginState.matches("verifyOTP") && (
        <OTP otpActorRef={loginState.children.otp}></OTP>
      )}
      {(loginState.matches("walletConnectionRequest") ||
        loginState.matches("checkChain") ||
        loginState.matches("signInWithEthereum")) && (
        <div className="mt-8 flex flex-col justify-center items-center">
          <p className="mb-12 text-lger text-center">
            Connect a wallet to access <br />your <strong>CryptoKnights</strong> account.
          </p>
          <button
            className={"btn-dim " + buttonLogin}
            disabled
          >
            <div className="flex flex-col items-center gap-5 p-5">
              <MM className="w-24" />
              <span className="text-xl">Connecting...</span>
            </div>
          </button>
        </div>
      )}
      {loginState.matches("wrongChain") && (
        <div className="max-w-md mt-8 mx-auto text-center">
          <p>You are on a wrong chain, please switch chain.</p>
          <button className="btn-go btn-lg max-w-min mt-8 mx-auto" onClick={onSwitchChainClicked}>
            Switch Chain
          </button>
        </div>
      )}
      {loginState.matches("changeChain") && (
        <div className="max-w-md mt-8 mx-auto text-center">
          <p>You are on a wrong chain, please switch chain.</p>
          <button className="btn-go btn-lg max-w-min mt-8 mx-auto" disabled>
            Switching Chain...
          </button>
        </div>
      )}
      {["inputEmail", "notRegisterUser", "requestOTP"].some(
        loginState.matches
      ) && (
        <>
          <div className="frame s">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-16">
              <LoginTemplate
                title="Associate wallet"
                /*title={`Hello! ${shortenWalletAddress(
                  loginState.context.walletAddress,
                  5
                )}`}*/
                description={
                  <>
                    <p>This wallet is not linked with an account. Fill in your <strong>CryptoKnights</strong> email address to access the asset portal.</p>
                    <p className="mt-3">Don't have an account yet? <a href="https://cryptoknights.games/" className="text-white font-bold">Download</a> the game and log in.</p>
                  </>
                }
                contents={
                  <form autoComplete="off" onSubmit={onEmailSubmitted}>
                    <label className="sr-only" htmlFor="email">
                      Email Address
                    </label>
                    <div className="mt-1 flex rounded-md shadow-md">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,}$"
                          required
                          className="input"
                          placeholder="email@address.com"
                          onInput={onEmailInput}
                          ref={emailRef}
                          disabled={loginState.matches("requestOTP")}
                        />
                        <div
                          className={`absolute inset-y-0 right-0 pr-5 flex items-center pointer-events-none ${
                            isEmailValid ? "" : "hidden"
                          }`}
                        >
                          <CheckCircleIcon
                            className="h-5 w-5 text-gold-400"
                            aria-hidden
                          />
                        </div>
                      </div>
                    </div>
                    <p
                      className={`mt-1 px-1 text-red-500 ${
                        loginState.context.emailErrorMessage?.length > 0
                          ? ""
                          : "hidden"
                      }`}
                    >
                      {loginState.context.emailErrorMessage === "Email not exists"
                        ? "Invalid email, please try again with your registered email"
                        : loginState.context.emailErrorMessage}
                    </p>
                    <div className="mt-4 flex justify-center">
                      <button
                        className="btn-go btn-lg"
                        type="submit"
                        disabled={!isEmailValid || loginState.matches("requestOTP")}
                      >
                        Request OTP
                      </button>
                    </div>
                  </form>
                }
              ></LoginTemplate>
              <div className="py-8">
                <h2>Just want to invest?</h2>
                <p>You don't need a <strong>CryptoKnights</strong> account to earn and win! You may still get your hands on <strong>CryptoKnights</strong> digital collecitibles, and on-chain currencies. You may hodl Knights, cards, skins, RUBY, and more, in the safety of your personal wallet.</p>
                <a
                  href="#mint-squire"
                  className="btn btn-lg btn-boost max-w-min mt-8"
                  id="btn-buy-knight"
                >
                  Mint Squire
                </a>
                {[
                  "buyKnightQuoting",
                  "buyKnightQuoteSuccess",
                  "buyKnightQuoteFail",
                  "buyKnightApproving",
                  "buyKnightApproveSuccess",
                  "buyKnightChainMinting",
                  "buyKnightChainMintSuccess",
                  "buyKnightChainMintFail",
                  "buyKnightAccountMinting",
                  "buyKnightAccountWaiting",
                ].some(buyState.matches) && (
                  <div>
                  <div>The popup will be open!!!</div>
                <PopUpBuyKnight
                  isGuest={true}
                  onClose={closeModalClicked}
                  buyState={buyState.value}
                  buyQty={buyQty}
                  buyQuote={buyState.context.buyQuote}
                  buyQuoteMod={buyState.context.buyQuoteMod}
                  increaseBuyQty={increaseBuyQty}
                  decreaseBuyQty={decreaseBuyQty}
                  updateBuyQty={updateBuyQty}
                  onApproveClicked={buyKnightApproveClicked}
                  onChainMintClicked={buyKnightChainMintClicked}
                  onAccountMintClicked={buyKnightAccountMintClicked}
                />
                </div>
                )}
              </div>
            </div>
          </div>
          <div className="cinema cinema-1" id="mint-squire">
            <div className="frame">
              <div className="flex flex-wrap items-center gap-6 md:flex-nowrap md:gap-16">
                <div className="w-full md:w-3/5">
                  <h2>Digital-collectible Squire</h2>
                  <p className="mb-3">Knights which are digital-collectibles, either won in a Knight Auction, bought from the marketplace, rented from other holders, or newly minted, can earn RUBY rewards when they win a Ranked Game. The higher Level a Knight, the more RUBY they earn.</p>
                  <p>You may mint a <span className="font-bold">new squire</span>. Squires may be used to <span className="font-bold">boost</span> a knight. Boosted knights will earn <span className="font-bold">RUBY</span> in PvP battle.</p> 
                </div>
                <div className="w-full md:w-2/5">
                  <div className="max-w-xs mx-auto px-12">
                    <KnightFrame
                      level="0"
                      // imgUri={i.imgUrl}
                      name={"New Squire"}
                      knightButtons={(
                        <button
                          className="btn-boost"
                          id="btn-buy-knight"
                          onClick={onBuyKnightClick}
                        >
                          Mint
                        </button>
                      )}
                      knightType="squire"
                      knightStatus={"in-game"}
                    ></KnightFrame>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {loginState.matches("confirmLink") && (
        <div className="frame">
          <div className="max-w-md mt-8 mx-auto text-center">
            <h2>
              Link wallet + email
            </h2>
            <p className="mb-8">
              You need to link wallet with your CryptoKnights game email to
              access your game inventory via CryptoKnights website.
            </p>
            
            <div className="flex items-center gap-4 w-full p-5 text-left bg-gold-800 border-gold-400 border-2 rounded-md focus:ring-gold-400 focus:border-gold-400">
              <MM className="w-12" />
              <div>
                <div className="font-bold">Wallet</div>
                {shortenWalletAddress(loginState.context.walletAddress, 6)}
              </div>
            </div>
            <LinkChain className="w-8 h-8" />
            <div className="flex items-center gap-4 w-full p-5 text-left bg-gold-800 border-gold-400 border-2 rounded-md focus:ring-gold-400 focus:border-gold-400">
              <Logo className="w-12"></Logo>
              <div>
                <div className="font-bold">Email</div>
                {loginState.context.email}
              </div>
            </div>
            
            <p className="text-red-500 mt-4">
              {loginState.context.errorMsg}
            </p>
            <div className="mt-8">
              <button className="btn-go btn-lg" onClick={onConfirmLink}>
                Link Account
              </button>
              <button
                className="btn-ie mt-4"
                onClick={onCancelClicked}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {(loginState.matches("requestLinkChallenge") ||
        loginState.matches("signLinkMessage") ||
        loginState.matches("link")) && (
          <div className="frame">
            <div className="max-w-md mt-8 mx-auto text-center">
              <h2>
                Link wallet + email
              </h2>
              <p className="mb-8">
              You need to link wallet with your <strong>CryptoKnights</strong> game email to
                access your game inventory via <strong>CryptoKnights</strong> website.
              </p>
              <div className="flex items-center gap-4 w-full p-5 text-left bg-gold-800 border-gold-400 border-2 rounded-md focus:ring-gold-400 focus:border-gold-400">
                <MM className="w-12" />
                <div>
                  <div className="font-bold">Wallet</div>
                  {shortenWalletAddress(loginState.context.walletAddress, 6)}
                </div>
              </div>
              <LinkChain className="w-8 h-8" />
              <div className="flex items-center gap-4 w-full p-5 text-left bg-gold-800 border-gold-400 border-2 rounded-md focus:ring-gold-400 focus:border-gold-400">
                <Logo className="w-12"></Logo>
                <div>
                  <div className="font-bold">Email</div>
                  {loginState.context.email}
                </div>
              </div>
              <div className="mt-8">
                <button className="btn-go btn-lg" onClick={onConfirmLink}>
                  Linking
                </button>
                <button
                  className="btn-ie mt-4"
                  disabled
                >
                  Cancel
                </button>
              </div>
          </div>
        </div>
      )}
    </div>
  );
}
