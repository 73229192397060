import { useCallback, useReducer, useEffect, useState } from "react";
import { ReactComponent as Spinner } from "../svgs/spinner.svg";
import { useActor } from "@xstate/react";
import { actorsInterpreter } from "../actors_machine";
import { useErc20Address } from "../hooks/useContract";
import BalanceFrame from "../components/Balance/Index";
import PopUpStakeRuby from "../components/PopUpStakeRuby/Index";
import PopUpUnstakeRuby from "../components/PopUpUnstakeRuby/Index";
import { publish } from "../app_events";
import { useWalletAddress } from "../hooks/useWalletAddress";
import { fetchX } from "../helpers/fetchX";
import { INVENTORY_PATH } from "../env";
import {
  priceString,
  toFixedWithoutZeros,
} from "../helpers/convertWei";
import { createERC20 } from "../web3";
type Action =
  | { type: "in_game_selected" }
  | { type: "on_chain_selected" }
  | { type: "display_items_updated"; payload: Balance[] };

interface State {
  selectedMenu: string;
  items: Balance[];
}
interface Balance {
  name: string;
  currency: string;
  balance: string;
}
function reducer(state: State, action: Action) {
  switch (action.type) {
    case "in_game_selected":
      return { ...state, selectedMenu: "inGame" };
    case "on_chain_selected":
      console.log("set selected me nu to on Chain");
      return { ...state, selectedMenu: "onChain" };
    case "display_items_updated":
      return { ...state, items: action.payload };
    default:
      throw new Error();
  }
}

const fetchOnChainBalance = async (wallet: string, contractAddr: string) => {
  const erc20 = await createERC20(contractAddr);
  const rubyBalance = await erc20.methods
    .balanceOf(wallet)
    .call()
    .then(function (result: any) {
      return result;
    });
  return rubyBalance;
};
const fetchInGameBalance = async () => {
  const list = ["ruby"]; // now just fetch only ruby backend is support ruby , gold and shard
  const jwt = localStorage.getItem("CustomerJWT");
  if (!jwt) throw Error("No JWT!");
  const accountId = JSON.parse(atob(jwt.split(".")[1]));
  const identityId = accountId.identity_id;
  const result = await Promise.all(
    list.map(async (c) => {
      const resp = await fetchX(
        `${INVENTORY_PATH}/account/${identityId}/${c.toUpperCase()}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + jwt,
          },
        }
      );
      return resp;
    })
  );
  const constructedValue: Balance[] = result.map((r) => {
    return {
      name: r.currency.toUpperCase(),
      balance: toFixedWithoutZeros(priceString(r.balance, 18), 2), // receive balance as wei value but should display decimal.
      currency: r.currency.toUpperCase(),
    };
  });
  console.log(constructedValue);
  return constructedValue;
};

const { wallet } = actorsInterpreter.getSnapshot().context;

export default function MyWallet(props: {
  walletAddress: string;
  erc20Address: string;
}) {
  const [walletState] = useActor(wallet);
  const [state, dispatch] = useReducer(reducer, {
    selectedMenu: "inGame",
    items: [],
  });
  const walletAddress = useWalletAddress();
  walletState.context.walletAddress = walletAddress;
  const erc20Address = useErc20Address();
  walletState.context.erc20 = erc20Address;

  const [stakeQty, setStakeQty] = useState("");
  const [unstakeQty, setUnstakeQty] = useState("");
  const stakeSalesLang = "RUBY stakers are subject to receive airdrops, NFTs, and exclusive rewards.";
  const stakeAPRLang = "The APR is calculated on current rates and subject to change based on various external variables. It is a rough estimate provided for convenience only, and by no means represents guaranteed returns.";

  const debounceInterval = 500;
  let filterTimeout: ReturnType<typeof setTimeout>;

  // Math.min to prevent value above current RUBY balance
  // Math.max to prevent value below 1
  // Math.ceil to prevent value with decimals (conveniently round up)
  const updateStakeQty = (value: string):void => {
    clearTimeout(filterTimeout)
    // If there is no value, still clear out the state, but do not calculate qty, or get a quote
    if (!value) {
      setStakeQty('')
      return
    }

    const newQty = String(Math.min(Math.max(Math.ceil(parseFloat(value)), 1), parseFloat(state.items[0].balance)))
    setStakeQty(newQty)
    walletState.context.stakeQty = newQty
    //
    filterTimeout = setTimeout(() => {
      publish({ type: "wallet__get_stake_ruby_qty_input" }, "wallet")
    }, debounceInterval)
    
  }
  const updateUnstakeQty = (value: string):void => {
    clearTimeout(filterTimeout)
    // If there is no value, still clear out the state, but do not calculate qty, or get a quote
    if (!value) {
      setUnstakeQty('')
      return
    }

    const newQty = String(Math.min(Math.max(Math.ceil(parseFloat(value)), 1), parseFloat(walletState.context.stakeVirgin)))
    setUnstakeQty(newQty)
    walletState.context.unstakeQty = newQty
    //
    filterTimeout = setTimeout(() => {
      publish({ type: "wallet__get_unstake_ruby_qty_input" }, "wallet")
    }, debounceInterval)
  }

  const inGameSelected = useCallback(() => {
    dispatch({ type: "in_game_selected" });
  }, []);
  const onChainSelected = useCallback(() => {
    dispatch({ type: "on_chain_selected" });
  }, []);

  const handleBtnClicked = useCallback((menu: string) => {
    console.log("menu", menu);
    switch (menu) {
      case "onChain": {
        publish({ type: "main__wallet_deposit" }, "wallet");
        publish({ type: "deposit_start", wallet: walletAddress }, "wallet");
        break;
      }
      case "inGame": {
        publish({ type: "main__wallet_withdraw" }, "wallet");
        publish({ type: "withdraw_start", wallet: walletAddress }, "wallet");
        break;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStakeClicked = useCallback(() => {
    publish({ type: "wallet__stake_ruby_clicked" }, "wallet");
  }, []);
  const stakeRubyApproveClicked = useCallback(() => {
    publish({ type: "wallet__stake_ruby_approve_clicked" }, "wallet");
  }, []);
  const stakeRubyStakeClicked = useCallback(() => {
    publish({ type: "wallet__stake_ruby_stake_clicked" }, "wallet");
  }, []);
  const closeModalClicked = useCallback(() => {
    publish({ type: "wallet__close_modal_clicked" }, "wallet");
  }, []);

  const handleUnstakeClicked = useCallback(() => {
    publish({ type: "wallet__unstake_ruby_clicked" }, "wallet");
  }, []);
  const unstakeRubyStakeClicked = useCallback(() => {
    publish({ type: "wallet__unstake_ruby_stake_clicked" }, "wallet");
  }, []);

  useEffect(() => {
    switch (state.selectedMenu) {
      case "onChain": {
        fetchOnChainBalance(props?.walletAddress, props.erc20Address).then(
          (res) => {
            dispatch({
              type: "display_items_updated",
              payload: [
                {
                  name: "Ruby",
                  currency: "Ruby",
                  balance: toFixedWithoutZeros(priceString(res, 18), 2),
                },
              ],
            });
          }
        );
        break;
      }
      case "inGame": {
        fetchInGameBalance()
          .then((result) => {
            const constructedData: Balance[] = result.map((r) => {
              return {
                name: r.name,
                currency: r.currency,
                balance: r.balance,
              };
            });
            dispatch({
              type: "display_items_updated",
              payload: constructedData,
            });
          })
          .catch(() => {
            dispatch({
              type: "display_items_updated",
              payload: [],
            });
          });
        break;
      }
    }
  }, [state.selectedMenu]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="text-white sm:pl-8 md:pl-12 lg:pl-20 xl:pl-24">
      <h1 className="sr-only">CrtyptoKnights Wallet</h1>
      <div className="w-full flex gap-2 bg-black bg-opacity-25 rounded-3xl">
        <button
          onClick={() => inGameSelected()}
          className={`btn-menu w-1/2 lg:w-1/3 +
         ${
           state.selectedMenu === "inGame"
             ? "btn-active"
             : ""
         }
      `}
          id="btn-wallet-in-game"
        >
          In-Game <span className="sr-only">Wallet</span>
        </button>
        <button
          onClick={() => onChainSelected()}
          className={`btn-menu w-1/2 lg:w-1/3 +
         ${
           state.selectedMenu === "onChain"
             ? "btn-active"
             : ""
         }
      `}
          id="btn-wallet-on-chain"
        >
          On-Chain <span className="sr-only">Wallet</span>
        </button>
        <div className="hidden lg:flex w-1/3"></div>
      </div>
      {state.items.length === 0 && (
        <div className="flex flex-col items-center justify-center h-80">
          <Spinner className="animate-spin w-24 h-24" />
          <div className="mt-4 text-smer uppercase">Loading...</div>
        </div>
      )}
      {state.items.length !== 0 && (
        <div>
          <div className="py-6 px-1 lg:w-3/4">
            { state.selectedMenu === 'inGame' &&
              <div className="mb-3">You may withdraw your <strong>in-game RUBY</strong>. Withdrawn <strong>RUBY</strong> may be transferred to friends, or traded in <span className="font-bold">DeFi</span>.</div>
            }
            { state.selectedMenu === 'onChain' &&
              <div className="mb-3">You may deposit your <strong>on-chain RUBY</strong>. Deposited <strong>RUBY</strong> may be used to enhance your knight with skins, weapons, and upgrades.</div>
            }
            <div className="text-smer">{state.items.map((i) => {
              return (
                <div key={i.currency}>
                  <span className="font-bold">{i.balance}</span> <span className="uppercase">{i.currency}</span>
                </div>
              )
             })}
             </div> 
          </div>
          <div className="grid grid-cols-2 gap-2 sm:gap-5 lg:grid-cols-3 xl:gap-6">
            <>
            {state.items.map((i) => {
              return (
                <BalanceFrame
                  key={i.currency + "WalletCard"}
                  currency={i.currency}
                  balance={i.balance}
                  name={i.name}
                  btnText={
                    state.selectedMenu === "onChain" ? "Deposit" : "Withdraw"
                  }
                  btnClicked={() => handleBtnClicked(state.selectedMenu)}
                />
              );
            })}
            {state.items.map((i) => {
              return (
                <div key={state.selectedMenu + i.currency + "S"}>
                { state.selectedMenu === "onChain" &&
                  <BalanceFrame
                    currency={i.currency}
                    balance={i.balance}
                    name={i.name}
                    btnText="Stake"
                    btnClicked={handleStakeClicked}
                    unstakeClicked={handleUnstakeClicked}
                  />
                }
                </div>
              );
            })}
            </>
          </div>
          {[
            "stakeRubyQuoting",
            "stakeRubyQuoteSuccess",
            "stakeRubyQuoteFail",
            "stakeRubyApproving",
            "stakeRubyApproveSuccess",
            "stakeRubyStaking",
            "stakeRubyStakeSuccess",
            "stakeRubyStakeFail",
          ].some(walletState.matches) && (
          <PopUpStakeRuby
            key="stake-ruby"
            onClose={closeModalClicked}
            rubyCurrent={state.items[0].balance} // temporary kludge
            stakeVirgin={walletState.context.stakeVirgin}
            stakeFull={walletState.context.stakeFull}
            stakeQty={stakeQty}
            stakeQuote={walletState.context.stakeQuote}
            totalStaked={walletState.context.totalStaked}
            APR={walletState.context.APR}
            stakeSalesLang={stakeSalesLang}
            stakeAPRLang={stakeAPRLang}
            updateStakeQty={updateStakeQty}
            onApproveClicked={stakeRubyApproveClicked}
            onRubyStakeClicked={stakeRubyStakeClicked}
            walletState={walletState.value}
          />
          )}
          {[
            "unstakeRubyQuoting",
            "unstakeRubyQuoteSuccess",
            "unstakeRubyQuoteFail",
            "unstakeRubyApproving",
            "unstakeRubyApproveSuccess",
            "unstakeRubyStaking",
            "unstakeRubyStakeSuccess",
            "unstakeRubyStakeFail",
          ].some(walletState.matches) && (
          <PopUpUnstakeRuby
            key="stake-ruby"
            onClose={closeModalClicked}
            rubyCurrent={state.items[0].balance} // temporary kludge
            stakeVirgin={walletState.context.stakeVirgin}
            stakeFull={walletState.context.stakeFull}
            stakeQty={unstakeQty}
            stakeQuote={walletState.context.stakeQuote}
            totalStaked={walletState.context.totalStaked}
            APR={walletState.context.APR}
            stakeSalesLang={stakeSalesLang}
            stakeAPRLang={stakeAPRLang}
            updateStakeQty={updateUnstakeQty}
            onRubyStakeClicked={unstakeRubyStakeClicked}
            walletState={walletState.value}
          />
          )}
        </div>
      )}
    </div>
  );
}
