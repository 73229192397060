import { ReactComponent as XMark } from "../../svgs/xmark.svg";
import Modal from "../../components/Modal/Index";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import { ReactComponent as MM } from "../../svgs/metamask.svg";
import { ReactComponent as Chevron } from "../../svgs/chevron.svg";

export enum TransactionType {
  Export = "Export",
  Import = "Import",
}
interface Props {
  onClose: () => void;
  onConfirmClicked: () => void;
  type: TransactionType;
  from: string;
  transferTo: string;
  isLoading?: boolean;
  isShowCloseModal?: boolean;
}

function PopUpRequestConfirm(props: Props) {
  return (
    <Modal>
      <button
        className="btn-dim btn-circle btn-sm btn-dismiss"
        id="btn-request-popup-close"
        onClick={props.onClose}
        title="Cancel Transfer"
      >
        <XMark className="w-4" />
      </button>
  
      <div className="flex flex-col w-full">
        <h2 className="title w-full">
          Withdraw Knight
        </h2>
        <div
          className={
            "flex w-full items-center justify-center gap-4 md:gap-6" +
            (props.type === TransactionType.Import
              ? " flex-row"
              : " flex-row-reverse")
          }
        >
          <MM className="w-16 h-16" />
          <Chevron className="-rotate-90 w-8" />
          <Logo className="w-16 h-16" />
        </div>
        <p className="mt-8 text-lger text-center">
          {props.type} to <strong>{props.transferTo}</strong>
          <br/>from <strong>{props.from}</strong>
        </p>
        {props.isLoading && (
          <div className="mt-4 mx-auto">
            Processing...
          </div>
        )}
        <button
          className={
            "mt-8 mx-auto btn-ie w-48" +
            (props.isLoading ? " hidden" : "")
          }
          id="btn-request-popup-confirm"
          onClick={props.onConfirmClicked}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
}

export default PopUpRequestConfirm;
