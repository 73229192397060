import "./Index.css";

interface Props {
  children: React.ReactNode;
  size?: String;
}

function Modal(props: Props){
  return (
    <div className={"modal active" + (props.size ? ' modal-' + props.size : '')}>
      <div className="modal-mask"></div>
      <div className="modal-view p-modal">
        <div className="relative">
          { props.children }
        </div>
      </div>
    </div>
  )
}

export default Modal;
