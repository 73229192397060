import { useCallback } from "react";
import { publish } from "../../app_events";
import { shortenWalletAddress } from "../../helpers/walletAddress";
import { ReactComponent as Castles } from "../../svgs/castles.svg";
import { ReactComponent as Copy } from "../../svgs/copy.svg";
import { ReactComponent as Weapons } from "../../svgs/weapons.svg";
import { ReactComponent as Knights } from "../../svgs/knights.svg";
import { ReactComponent as MM } from "../../svgs/metamask.svg";
import { ReactComponent as Shop } from "../../svgs/shop.svg";
import { ReactComponent as Skins } from "../../svgs/skins.svg";
import { ReactComponent as Wallet } from "../../svgs/wallet.svg";

type MainMenuProps = {
  isLoginSuccess: boolean;
  walletAddress: string;
  toggleMenu: (e: React.MouseEvent) => void;
};
export default function MainMenuMobile({
  isLoginSuccess,
  walletAddress,
  toggleMenu,
}: MainMenuProps) {
  const shortWalletAddress = shortenWalletAddress(walletAddress, 6);

  const onCopyWallet = useCallback(() => {
    const walletAddressInput = document.querySelector(
      "#mobile-wallet-address"
    ) as HTMLInputElement;
    if (walletAddressInput) {
      walletAddressInput.select();
      document.execCommand("copy");
    }
  }, []);

  const onConnectWalletClicked = useCallback(() => {
    publish({ type: "login__connect_clicked" }, "login");
  }, []);
  const onInventoryClicked = useCallback(
    (e: React.MouseEvent) => {
      publish({ type: "main__inventory_selected" }, "main_menu");
      toggleMenu(e);
    },
    [toggleMenu]
  );
  const onWalletClicked = useCallback(
    (e: React.MouseEvent) => {
      publish({ type: "main__wallet_selected" }, "main_menu");
      toggleMenu(e);
    },
    [toggleMenu]
  );
  const onBuyClicked = useCallback(
    (e: React.MouseEvent) => {
      publish({ type: "main__buy_selected" }, "main_menu");
      toggleMenu(e);
    },
    [toggleMenu]
  );

  return (
    <div className="p-5 bg-black/80 md:p-6">
      <ul>
        <li
          className={`${
            shortWalletAddress ? "" : "hidden"
          }`}
        >
          <button
            className="btn-ie justify-start gap-3 normal-case"
            id="btn-menu-copy"
            onClick={onCopyWallet}
          >
            <span className="font-bold">{shortWalletAddress}</span>
            <input
              id="mobile-wallet-address"
              className="-z-10 absolute top-0 right-0 opacity-0"
              value={walletAddress}
              readOnly
            ></input>
            <div
              className="w-4 cursor-pointer hover:text-gold-400"
            >
              <Copy></Copy>
            </div>
          </button>
        </li>
        <li
          className={`mt-3 ${
            isLoginSuccess ? "" : "hidden"
          }`}
        >
          <button
            className="btn-dim justify-start gap-4"
            id="btn-menu-inventory"
            onClick={onInventoryClicked}
          >
            <Knights className="w-4" />
            <span className="font-bold">Knights</span>
          </button>
        </li>
        <li
          className={`${
            isLoginSuccess ? "" : "hidden"
          }`}
        >
          <button
            className="btn-dim justify-start gap-4"
            id="btn-menu-wallet"
            onClick={onWalletClicked}
          >
            <Wallet className="w-4" />
            <span className="font-bold">Wallet</span>
          </button>
        </li>
        <li
          className={`${
            isLoginSuccess ? "" : "hidden"
          }`}
        >
          <button
            className="btn-dim justify-start gap-4"
            disabled
          >
            <Weapons className="w-4" />
            <span className="font-bold">Weapons</span>
          </button>
        </li>
        <li
          className={`${
            isLoginSuccess ? "" : "hidden"
          }`}
        >
          <button
            className="btn-dim justify-start gap-4"
            disabled
          >
            <Skins className="w-4" />
            <span className="font-bold">Skins</span>
          </button>
        </li>
        <li
          className={`${
            isLoginSuccess ? "" : "hidden"
          }`}
        >
          <button
            className="btn-dim justify-start gap-4"
            disabled
          >
            <Castles className="w-4" />
            <span className="font-bold">Castles</span>
          </button>
        </li>
        <li
          className={`${
            isLoginSuccess ? "" : "hidden"
          }`}
        >
          <button
            className="btn-dim justify-start gap-4"
            id="btn-menu-shop"
            onClick={onBuyClicked}
          >
            <Shop className="w-4" />
            <span className="font-bold">Shop</span>
          </button>
        </li>
      </ul>
      <button
        className={ "btn-ie gap-4 " + (shortWalletAddress ? "hidden" : "")}
        onClick={onConnectWalletClicked}
      >
        <MM className="w-4" />
        <span>Connect Wallet</span>
      </button>
    </div>
  );
}
