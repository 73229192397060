import { useCallback } from "react";
import { ReactComponent as Hamburger } from "./svgs/hamburger.svg";
import { ReactComponent as Logo } from "./svgs/logo.svg";
import Wallet from "./login/Wallet";

function detectScroll() {
	if (window.pageYOffset > 16) {
    document.querySelector('html')?.classList.add('scrolled');
	} else {
    document.querySelector('html')?.classList.remove('scrolled');
	}
}
detectScroll();
window.onscroll = function(){
	detectScroll();
};

type FooterProps = {
  toggleMenu: (e: React.MouseEvent) => void;
  menuOpened: boolean;
  walletAddress: string;
};
export default function Footer({
  toggleMenu,
  menuOpened,
  walletAddress,
}: FooterProps) {
  const onHamburgerClicked = useCallback(
    (e: React.MouseEvent) => {
      toggleMenu(e);
    },
    [toggleMenu]
  );
  const onExpandClicked = useCallback(
    (e: React.MouseEvent) => {
      toggleMenu(e);
    },
    [toggleMenu]
  );

  return (
    <div className="header flex justify-between w-full py-3 px-5 transition-all duration-500 ease-in-out lg:py-4.5 lg:px-6">
      <a
        className="w-12 h-12 lg:fixed lg:top-4.5 lg:left-6 lg:z-20"
        href="https://cryptoknights.games/"
        title="Return to CryptoKnights homepage"
      >
        <Logo />
      </a>
      <div className="flex-1"></div>
      <div className="hidden lg:flex lg:fixed lg:top-4.5 lg:right-6 lg:z-20">
        <Wallet
          isShowPopup={menuOpened}
          walletAddress={walletAddress}
          onExpandClicked={onExpandClicked}
        ></Wallet>
      </div>
      <button
        className="btn-dim btn-circle lg:hidden"
        onClick={onHamburgerClicked}
      >
        <Hamburger className="w-4" />
      </button>
    </div>
  );
}
