import Web3 from "web3";

//gasOracle is our custom oracle tailered to polygon.
//We need to do this because polygon is run by people who make stupid decisions without thinking.
//returns maxPriorityFeePerGas and maxFeePerGas
export const gasOracle = function(){
  let web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8545");
  //hardcode 36 gwei. minimum for practical purposes is 30
  const maxPriorityFeePerGas = new web3.utils.BN(web3.utils.toWei("36", "gwei"));
  //Get the base fee for most known recent block
  return web3.eth.getBlock("latest").then(function(block){
  let currentbase = new web3.utils.BN(block.baseFeePerGas);
  //Assume base fee to inflate by 40%
  let maxBase = currentbase.mul(new web3.utils.BN(140)).div(new web3.utils.BN(100))
  //Actual amount should be maxBase +  maxPriorityFeePerGas
  let maxFeePerGas = maxBase.add(maxPriorityFeePerGas)
  return {maxPriorityFeePerGas: maxPriorityFeePerGas.toString(), maxFeePerGas: maxFeePerGas.toString()}
  })
}