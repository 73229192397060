import Web3 from "web3";
import { DAI_CONTRACT, KNIGHTMINTER_CONTRACT } from "../env";
import { assign, createMachine } from "xstate";
import {
  ConvertToFixBitPrice,
  priceString,
  toFixedWithoutZeros,
} from "../helpers/convertWei";
import {
  gasOracle
} from "../helpers/gasOracle";
import {
  createERC20,
  createKnightPriceQuote,
} from "../web3";
type Context = {
  buyQty: string;
  buyQuote: string;
  buyQuoteMod: string;
  buyQuoteWei: string;
  quoteMultiplier: number;
  walletAddress: string;
  selectedMenu: string;
  index?: number;
  erc20: string;
  erc721: string;
  gameCustodial: string;
  finality_blocks: number;
  allowance: string;
};
export interface Knight {
  displayIndex?: number;
  token_contract?: string;
  tokenId?: string;
  name: string;
  level?: string;
  imgUrl: string;
}

export const buyMachine = createMachine(
  {
    id: "buy",
    tsTypes: {} as import("./buy_machine.typegen").Typegen0,
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
    },

    context: {
      buyQty: "1",
      buyQuote: "0",
      buyQuoteMod: "0",
      buyQuoteWei: "0",
      quoteMultiplier: 1.1,
      walletAddress: "",
      selectedMenu: "Knights",
      erc20: "",
      erc721: "",
      gameCustodial: "",
      finality_blocks: 1,
      allowance: "",
    } as Context,

    initial: "idle",
    states: {
      idle: {
        on: {
          buy__buy_knight_clicked: {
            target: "buyKnightQuoting",
          },
        },
      },
      buyKnightQuoting: {
        invoke: {
          id: "approve-quoteTEST22233Knight",
          src: "buyKnightGetQuote",
          onDone: {
            target: "buyKnightQuoteSuccess",
            actions: "updateKnightPriceQuote",
          },
          onError: {
            target: "buyKnightQuoteFail",
          },
        },
      },
      buyKnightQuoteSuccess: {
        on: {
          buy__close_modal_clicked: {
            target: "idle",
            actions: "buyKnightReset",
          },
          buy__get_buy_knight_qty_input: {
            target: "buyKnightQuoting",
          },
          buy__knight_approve_clicked: {
            target: "buyKnightApproving",
          },
        },
      },
      buyKnightQuoteFail: {
        on: {
          buy__close_modal_clicked: {
            target: "idle",
            actions: "buyKnightReset",
          },
          buy__get_buy_knight_qty_input: {
            target: "buyKnightQuoting",
          },
        },
      },
      buyKnightApproving: {
        invoke: {
          id: "approve",
          src: "approveRequest",
          onDone: {
            target: "buyKnightApproveSuccess",
          },
          onError: {
            target: "buyKnightQuoteSuccess",
          },
        },
      },
      buyKnightApproveSuccess: {
        on: {
          buy__close_modal_clicked: {
            target: "idle",
            actions: "buyKnightReset",
          },
          buy__knight_mint_chain_clicked: {
            target: "buyKnightChainMinting",
          },
          buy__knight_mint_account_clicked: {
            target: "buyKnightAccountMinting",
          },
        },
      },
      buyKnightChainMinting: {
        invoke: {
          id: "approve-mintKnight",
          src: "buyKnightChainMint",
          onDone: {
            target: "buyKnightChainMintSuccess",
            // actions: "updateKnightMint",
          },
          onError: {
            target: "buyKnightChainMintFail",
          },
        },
      },
      buyKnightChainMintSuccess: {
        on: {
          buy__close_modal_clicked: {
            target: "idle",
            actions: "buyKnightReset",
          },
        },
      },
      buyKnightChainMintFail: {
        on: {
          buy__close_modal_clicked: {
            target: "idle",
            actions: "buyKnightReset",
          },
        },
      },
      buyKnightAccountMinting: {
        invoke: {
          id: "approve-mintKnight",
          src: "buyKnightAccountMint",
          onDone: {
            target: "buyKnightAccountWaiting",
            // actions: "updateKnightMint",
          },
          onError: {
            target: "buyKnightChainMintFail",
          },
        },
      },
      buyKnightAccountWaiting: {
        invoke: {
          id: "buy-knight-confirming",
          src: "buyKnightAccountWait",
          onDone: {
            target: "buyKnightChainMintSuccess",
          },
          onError: {
            target: "buyKnightChainMintFail",
          },
        },
      },
    },
  },
  {
    services: {
      buyKnightGetQuote: async (context, event: any) => {
        // get decimal conversion
        const contract = await createERC20(DAI_CONTRACT);
        const decimal = await contract.methods
          .decimals()
          .call()
          .then(function (result: any) {
            return result;
          });

        // get
        const quoteContract = await createKnightPriceQuote(KNIGHTMINTER_CONTRACT);
        console.log(quoteContract);
        let buyQuoteWei = await quoteContract.methods
          .price_quote(context.buyQty, DAI_CONTRACT)
          .call()
          .then(function (result: any) {
            console.log('result bKGQ')
            console.log(result)
            return result;
          })
        // return true;
        const buyQuote = toFixedWithoutZeros(
          priceString(buyQuoteWei.toString(), decimal),
          4
        )
        const buyQuoteMod = String(Math.ceil(parseFloat(buyQuote) * context.quoteMultiplier))
        /*
        We must use crypto math to multiply the Wei based on the buyQuote and buyMultiplier
        */
        console.log('buyQuote', buyQuote, 'buyQuoteMod', buyQuoteMod, 'buyQuoteWei', buyQuoteWei)
        
        buyQuoteWei = ConvertToFixBitPrice(buyQuoteMod, decimal);

        return {
          ...context,
          buyQuote: buyQuote,
          buyQuoteMod: buyQuoteMod,
          buyQuoteWei: buyQuoteWei
        };
        
        // if (!isApproved) throw new Error("not approve");
      },
      approveRequest: async (context) => {
        /*
        console.log('approveRequest')
        console.log('context')
        console.log(context)
        */
        const contract = await createERC20(DAI_CONTRACT);
        // let approve;
        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })
        const approve = await contract.methods
          .approve(
            KNIGHTMINTER_CONTRACT,
            context.buyQuoteWei
          )
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(function (result: any) {
            console.log('result aR')
            console.log(result)
            return JSON.stringify(result);
          });
        console.log('reached return disabled')
        return approve;
      },
      buyKnightChainMint: async (context) => {
        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })
        // qty, spend_currency, max_spend
        const quoteContract = await createKnightPriceQuote(KNIGHTMINTER_CONTRACT);
        const theMint = await quoteContract.methods
          .buy_tokens(context.buyQty, DAI_CONTRACT, context.buyQuoteWei)
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(
            function (result: any) {
              console.log('result bKCM')
              console.log(result)
              return result;
            },
            function (error: any) {
              console.log('throw bkCM')
              console.log(error)
              throw new Error(error);
            }
          );
        return theMint;
      },
      buyKnightAccountMint: async (context) => {
        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })
        /*
        console.log('buyKnightAccountMint')
        console.log(context)
        */
        // qty, spend_currency, max_spend
        const quoteContract = await createKnightPriceQuote(KNIGHTMINTER_CONTRACT);
        const theMint = await quoteContract.methods
          .buy_tokens_for_account(context.buyQty, DAI_CONTRACT, context.buyQuoteWei)
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(
            function (result: any) {
              console.log('result')
              console.log(result)
              return result;
            },
            function (error: any) {
              console.log('throw')
              console.log(error)
              throw new Error(error);
            }
          );
          return theMint;
      },
      buyKnightAccountWait: async (context) => {
        let web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8545");
        let isFirstBlockAppear = false;
        let qty = 0;
        var subscription = web3.eth
          .subscribe("newBlockHeaders", function (error, result) {
            if (!error) {
              qty += 1;
              isFirstBlockAppear = true;
              return;
            }
            console.error(error);
          })
          .on("error", console.error);
        console.log("finality ", context.finality_blocks);
        while (isFirstBlockAppear === false) {
          await new Promise((r) => setTimeout(r, 2000));
          while (qty <= context.finality_blocks) {
            await new Promise((r) => setTimeout(r, 2000));
            console.log("block added", qty);
          }
        }
        subscription.unsubscribe(function (error, success) {
          if (success) {
            // console.log("Successfully unsubscribed!");
          } else {
            console.log("unsubscribe error", error);
          }
        });
      },
    },
    actions: {
      updateKnightPriceQuote: assign((context: Context, event: any) => {
        return {
          ...context,
          buyQuote: event.data.buyQuote,
          buyQuoteMod: event.data.buyQuoteMod,
          buyQuoteWei: event.data.buyQuoteWei,
        };
      }),
      /*
      updateKnightMint: assign((context: Context, event: any) => {
        console.log('update, event is:')
        console.log(event)
        console.log(context)
        return {
          ...context,
          // walletAddress: walletAddress
        };
      }),
      */
      buyKnightReset: assign((context, event) => {
        return {
          ...context,
          buyQty: "1",
          buyQuote: "0",
          buyQuoteMod: "0"
        };
      }),
    },
  }
);
