import { ethers } from "ethers";
import { assign, createMachine } from "xstate";
import { publish } from "../app_events";
import { getChainById } from "../chain_configs";
import { API_PATH, CHAIN_ID } from "../env";
import { fetchX } from "../helpers/fetchX";
import { parseJwt } from "../helpers/jwtParser";
import { web3 } from "../web3";
import otpMachine from "./otp_machine";
import siweMachine from "./siwe_machine";

type Context = {
  walletAddress: string;
  challenge: LinkChallenge;
  signedMessage: string;
  email: string;
  otpInfo: { refId: string; expiredAt: string; resendAfter: string };
  inputOTP: string;
  errorMsg?: string;
  emailErrorMessage: string;
  skipReconnect: boolean;
};

type ErrorDetail = {
  detail: string;
};

export const services = {
  getLoginAccount: async () => {
    const accounts = await web3.eth.getAccounts();

    if (accounts.length !== 0) {
      return accounts[0];
    }

    throw new Error("No connected account");
  },
  connectWallet: async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_requestAccounts", []);

      if (accounts && accounts.length) {
        return accounts[0];
      }
    } else {
      window.open(
        `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`,
        "_blank"
      );
      throw new Error("No Metamask installed");
    }
  },
  checkMatchChain: async () => {
    const chain = await web3.eth.getChainId();

    if (chain === parseInt(CHAIN_ID)) {
      return "";
    } else {
      throw new Error("Wrong network");
    }
  },
  checkWalletStatus: async (c: Context) => {
    const result = await fetchX(
      `${API_PATH}/eth/link-status/${c.walletAddress}`
    );
    if (result.linked) return result.linked;
    else throw Error(result.linked);
  },
  changeChain: async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: getChainById(CHAIN_ID).chainId }],
      });
    } catch (switchError) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [getChainById(CHAIN_ID)],
        });
      } catch (addError) {
        throw new Error("Error while add network.");
      }
    }
    return "";
  },
  requestOTP: async (c: Context) => {
    const result = await fetchX(`${API_PATH}/email/request-login-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: c.email,
      }),
    });
    return {
      refId: result.ref_id,
      expiredAt: result.expired_at,
      resendAfter: result.resend_after,
    };
  },
  requestLinkChallenge: async (c: Context) => {
    try {
      const result = await fetchX(`${API_PATH}/eth/request-linking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: c.email,
          wallet_address: c.walletAddress,
        }),
      });
      return result.message;
    } catch (e) {
      console.error(e);
      throw new Error(
        "This Email has already been register with another wallet."
      );
    }
  },
  signLinkMessage: async (c: Context) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signature = await provider.send("eth_signTypedData_v4", [
        c.walletAddress,
        JSON.stringify(c.challenge),
      ]);
      return signature;
    } else {
      return "";
    }
  },
  linkEmail: async (c: Context) => {
    const jwt = localStorage.getItem("AuthJWT");
    const result = await fetchX(`${API_PATH}/eth/link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        message: c.challenge,
        signature: c.signedMessage,
      }),
    });
    return result.jwt;
  },
  checkJWT: async (c: Context) => {
    const jwt = localStorage.getItem("CustomerJWT");

    if (!jwt) throw Error("No JWT!");

    const parsedJWT = parseJwt(jwt);
    if (parsedJWT.eth_wallet !== c.walletAddress)
      throw Error("Wallet mismatch!");

    const result = await fetchX(`${API_PATH}/authen/renew-jwt`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    });
    return result.jwt;
  },
};

export const loginMachine = createMachine(
  {
    id: "login",
    tsTypes: {} as import("./login_machine.typegen").Typegen0,
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
      services: {} as {
        getLoginAccount: { data: string };
        connectWallet: { data: string };
        checkMatchChain: { data: any };
        checkWalletStatus: { data: boolean };
        requestOTP: {
          data: { refId: string; expiredAt: string; resendAfter: string };
        };
        requestLinkChallenge: { data: LinkChallenge };
        signLinkMessage: { data: string };
        linkEmail: { data: string };
        changeChain: { data: string };
        checkJWT: { data: string };
      },
    },
    context: {
      walletAddress: "",
      emailErrorMessage: "",
      skipReconnect: false,
    } as Context,
    states: {
      init: {
        invoke: {
          id: "get-login-account",
          src: "getLoginAccount",
          onDone: {
            actions: ["updateWalletAddress"],
            target: "checkChain",
          },
          onError: "disconnected",
        },
      },
      disconnected: {
        on: {
          login__connect_clicked: "walletConnectionRequest",
        },
      },
      walletConnectionRequest: {
        invoke: {
          id: "connect-wallet-request",
          src: "connectWallet",
          onDone: {
            target: "checkChain",
            actions: ["updateWalletAddress", "updateSkipReconnect"],
          },
          onError: "disconnected",
        },
      },
      checkChain: {
        invoke: {
          id: "check-chain",
          src: "checkMatchChain",
          onDone: "checkWalletStatus",
          onError: "wrongChain",
        },
      },
      wrongChain: {
        on: { login__change_chain: "changeChain" },
      },
      changeChain: {
        invoke: {
          id: "change-chain",
          src: "changeChain",
          onDone: "checkChain",
          onError: "checkChain",
        },
      },
      checkWalletStatus: {
        invoke: {
          id: "check-wallet-status",
          src: "checkWalletStatus",
          onDone: { target: "checkJWT" },
          onError: { target: "inputEmail" },
        },
      },
      // Unknown wallet
      inputEmail: {
        on: {
          login__email_submitted: {
            target: "requestOTP",
            actions: "updateEmail",
          },
        },
      },
      requestOTP: {
        invoke: {
          id: "request-otp",
          src: "requestOTP",
          onDone: {
            target: "verifyOTP",
            actions: ["updateOTPInfo"],
          },
          onError: {
            target: "inputEmail",
            actions: ["updateError"],
          },
        },
      },
      verifyOTP: {
        invoke: {
          id: "otp",
          src: otpMachine,
          data: {
            email: (context: Context) => context.email,
            otpInfo: (context: Context) => context.otpInfo,
          },
          onDone: [
            {
              cond: "otpVerified",
              target: "confirmLink",
            },
            {
              target: "inputEmail",
              actions: "clearError",
            },
          ],
        },
      },
      confirmLink: {
        on: {
          login__confirm_link: {
            target: "requestLinkChallenge",
          },
          login__otp_cancel: { target: "inputEmail", actions: "clearError" },
        },
      },
      requestLinkChallenge: {
        invoke: {
          id: "request-link-challenge",
          src: "requestLinkChallenge",
          onDone: {
            target: "signLinkMessage",
            actions: "updateChallenge",
          },
          onError: {
            target: "confirmLink",
            actions: "addErrorLink",
          },
        },
      },
      signLinkMessage: {
        invoke: {
          id: "sign-link-message",
          src: "signLinkMessage",
          onDone: {
            target: "link",
            actions: "updateSignedMessage",
          },
          onError: {
            target: "confirmLink",
          },
        },
      },
      link: {
        invoke: {
          id: "link-email",
          src: "linkEmail",
          onDone: {
            target: "success",
            actions: "updateCustomerJWT",
          },
        },
      },
      // Known wallet
      checkJWT: {
        invoke: {
          id: "check-jwt",
          src: "checkJWT",
          onDone: {
            target: "success",
            actions: "updateCustomerJWT",
          },
          onError: [
            {
              cond: "skipReconnect",
              target: "signInWithEthereum",
            },
            {
              target: "reconnect",
            },
          ],
        },
      },
      reconnect: {
        on: {
          login__connect_clicked: "signInWithEthereum",
        },
      },
      signInWithEthereum: {
        invoke: {
          id: "siwe",
          src: siweMachine,
          onDone: [
            {
              cond: "siweSucess",
              target: "success",
              actions: "updateCustomerJWT",
            },
            { target: "disconnected" },
          ],
        },
      },
      success: { entry: ["broadcastLoginSuccess"] },
    },
    initial: "init",
  },
  {
    services,
    actions: {
      broadcastLoginSuccess: () => {
        publish({ type: "login__success" }, "login");
      },
      updateOTPInfo: assign((context: Context, event) => {
        return {
          ...context,
          otpInfo: {
            refId: event.data.refId,
            expiredAt: event.data.expiredAt,
            resendAfter: event.data.resendAfter,
          },
        };
      }),
      addErrorLink: assign((context: Context, event: any) => {
        return { ...context, errorMsg: event.data.message };
      }),
      clearError: assign((context, event) => {
        return { ...context, errorMsg: undefined, emailErrorMessage: "" };
      }),
      updateWalletAddress: assign((context: Context, event) => {
        return { ...context, walletAddress: event.data };
      }),
      updateChallenge: assign((context: Context, event) => {
        return { ...context, challenge: event.data };
      }),
      updateSignedMessage: assign((context: Context, event) => {
        return { ...context, signedMessage: event.data };
      }),
      updateCustomerJWT: (_, event) => {
        localStorage.setItem("CustomerJWT", event.data);
        console.log("updateCustomerJWT >", localStorage.getItem("CustomerJWT"));
      },
      updateEmail: assign((context: Context, event) => {
        return { ...context, email: event.email };
      }),
      updateError: assign((context: Context, event) => {
        const errorObject = event.data as ErrorDetail;
        return {
          ...context,
          emailErrorMessage: errorObject.detail,
        };
      }),
      updateSkipReconnect: assign((context: Context) => {
        return { ...context, skipReconnect: true };
      }),
    },
    guards: {
      siweSucess: (_, event) => {
        if (event.data) return true;
        else return false;
      },
      otpVerified: (_, event) => {
        return event.data.otpVerified;
      },
      skipReconnect: (context: Context) => {
        return context.skipReconnect;
      },
    },
  }
);
