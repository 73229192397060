export const fetchX = async (
  input: RequestInfo,
  init?: RequestInit | undefined
) => {
  const result = await fetch(input, init);
  const json = await result.json();
  if (!result.ok) {
    throw json;
  }
  return json;
};
