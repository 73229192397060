import { useCallback, useReducer, useEffect, useRef, useState } from "react";
import { useActor } from "@xstate/react";
import { actorsInterpreter } from "../actors_machine";
import { useWalletAddress } from "../hooks/useWalletAddress";
import { publish } from "../app_events";
import KnightFrame from "../components/KnightFrame/Index";
import PopUpBuyKnight from "../components/PopUpBuyKnight/Index";
type Action =
  | { type: "in_game_selected" }
  | { type: "on_chain_selected" }
  | { type: "display_items_updated"; payload: Balance[] };

interface State {
  selectedMenu: string;
  items: Balance[];
}
interface Balance {
  name: string;
  currency: string;
  balance: string;
}
function reducer(state: State, action: Action) {
  switch (action.type) {
    case "in_game_selected":
      return { ...state, selectedMenu: "inGame" };
    case "on_chain_selected":
      console.log("set selected me nu to on Chain");
      return { ...state, selectedMenu: "onChain" };
    case "display_items_updated":
      return { ...state, items: action.payload };
    default:
      throw new Error();
  }
}

const { buy } = actorsInterpreter.getSnapshot().context;

export default function Buy(props: {
  
}) {
  const [buyState] = useActor(buy);
  const walletAddress = useWalletAddress();
  buyState.context.walletAddress = walletAddress;

  const [buyQty, setBuyQty] = useState("1");

  const debounceInterval = 500;
  let filterTimeout: ReturnType<typeof setTimeout>;
  // Math.min to prevent value above 300
  // Math.max to prevent value below 1
  // Math.ceil to prevent value with decimals (conveniently round up)
  const updateBuyQty = (value: string):void => {
    clearTimeout(filterTimeout)
    // If there is no value, still clear out the state, but do not calculate qty, or get a quote
    if (!value) {
      setBuyQty('')
      return
    }

    const newQty = String(Math.min(Math.max(Math.ceil(parseFloat(value)), 1), 300))
    setBuyQty(newQty)
    buyState.context.buyQty = newQty

    filterTimeout = setTimeout(() => {
      publish({ type: "buy__get_buy_knight_qty_input" }, "buy")
    }, debounceInterval)
  }
  const increaseBuyQty = ():void => {
    const newQty = String(Math.min(parseFloat(buyQty) + 1, 300))
    setBuyQty(newQty)
    buyState.context.buyQty = newQty
  }
  const decreaseBuyQty = ():void => {
    const newQty = String(Math.max(parseFloat(buyQty) - 1, 1))
    setBuyQty(newQty)
    buyState.context.buyQty = newQty
  }

  const onBuyKnightClick = useCallback(() => {
    publish({ type: "buy__buy_knight_clicked" }, "buy");
  }, []);
  const buyKnightApproveClicked = useCallback(() => {
    publish({ type: "buy__knight_approve_clicked" }, "buy");
  }, []);
  const buyKnightChainMintClicked = useCallback(() => {
    publish({ type: "buy__knight_mint_chain_clicked" }, "buy");
  }, []);
  const buyKnightAccountMintClicked = useCallback(() => {
    publish({ type: "buy__knight_mint_account_clicked" }, "buy");
  }, []);
  const closeModalClicked = useCallback(() => {
    publish({ type: "buy__close_modal_clicked" }, "buy");
  }, []);

  return (
    <div>
      <div className="text-white sm:pl-8 md:pl-12 lg:pl-20 xl:pl-24">
        <h1 className="sr-only">CrtyptoKnights Buy / Mint</h1>
        <div className="w-full flex gap-2 bg-black bg-opacity-25 rounded-3xl">
          <button
            className="btn-menu w-1/3 btn-active"
          >
            <span className="sr-only">Buy</span> Knights
          </button>
          <button
            className="btn-menu w-1/3"
            disabled
          >
            <span className="sr-only">Buy</span> Currency
          </button>
          <button
            className="btn-menu w-1/3"
            disabled
          >
            <span className="sr-only">Buy</span> Castles
          </button>
        </div>
        {
          // observe state props when needed
          /*
            <div className="mt-4">
              buyQty: {buyQty}
            </div>
          */
        }
        <div className="py-6 px-1 lg:w-3/4">
          <div className="mb-3">You may mint a <span className="font-bold">new squire</span>. Squires may be used to <span className="font-bold">boost</span> a knight. Boosted knights will earn <span className="font-bold">RUBY</span> in PvP battle.</div> 
        </div>
        <div className="grid grid-cols-2 gap-2 sm:gap-5 lg:grid-cols-3 xl:gap-6">
          <KnightFrame
            level="0"
            // imgUri={i.imgUrl}
            name={"New Squire"}
            knightButtons={(
              <button
                className="btn-boost"
                id="btn-buy-knight"
                onClick={onBuyKnightClick}
              >
                Mint
              </button>
            )}
            knightType="squire"
            knightStatus={"in-game"}
          ></KnightFrame>
        </div>
        {[
          "buyKnightQuoting",
          "buyKnightQuoteSuccess",
          "buyKnightQuoteFail",
          "buyKnightApproving",
          "buyKnightApproveSuccess",
          "buyKnightChainMinting",
          "buyKnightChainMintSuccess",
          "buyKnightChainMintFail",
          "buyKnightAccountMinting",
          "buyKnightAccountWaiting",
        ].some(buyState.matches) && (
        <PopUpBuyKnight
          isGuest={false}
          onClose={closeModalClicked}
          buyState={buyState.value}
          buyQty={buyQty}
          buyQuote={buyState.context.buyQuote}
          buyQuoteMod={buyState.context.buyQuoteMod}
          increaseBuyQty={increaseBuyQty}
          decreaseBuyQty={decreaseBuyQty}
          updateBuyQty={updateBuyQty}
          onApproveClicked={buyKnightApproveClicked}
          onChainMintClicked={buyKnightChainMintClicked}
          onAccountMintClicked={buyKnightAccountMintClicked}
        />
        )}
      </div>
    </div>
  );
}
