import { SiweMessage } from "siwe";
import { assign, createMachine } from "xstate";
import { ethers } from "ethers";
import { API_PATH, CHAIN_ID } from "../env";

const domain = window.location.host;
const origin = window.location.origin;

function createSiweMessage(address: string, statement: string) {
  return new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: parseInt(CHAIN_ID),
  }).prepareMessage();
}

async function signInWithEthereum(message: string) {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    return await signer.signMessage(message);
  } else return "";
}

type Context = {
  eip4361Msg: string;
  signature: string;
  customerJWT: string;
};

const siweMachine = createMachine(
  {
    id: "siwe",
    tsTypes: {} as import("./siwe_machine.typegen").Typegen0,
    schema: {
      context: {} as Context,
      services: {} as {
        generate: { data: string };
        sign: { data: string };
        siwe: { data: string };
      },
    },
    context: {
      eip4361Msg: "",
      signature: "",
      customerJWT: "",
    },
    initial: "generateMsg",
    states: {
      generateMsg: {
        invoke: {
          id: "generate-msg",
          src: "generate",
          onDone: {
            actions: ["updateEip4361Msg"],
            target: "signMsg",
          },
          onError: "error",
        },
      },
      signMsg: {
        invoke: {
          id: "sign-msg",
          src: "sign",
          onDone: {
            actions: ["updateSignature"],
            target: "sendSignedMsg",
          },
          onError: "error",
        },
      },
      sendSignedMsg: {
        invoke: {
          id: "send-signed-msg",
          src: "siwe",
          onDone: {
            actions: ["updateCustomerJWT"],
            target: "finished",
          },
          onError: "error",
        },
      },
      error: { type: "final" },
      finished: {
        type: "final",
        data: (context, _) => context.customerJWT,
      },
    },
  },
  {
    services: {
      generate: async (c: Context) => {
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const eip4361Msg = createSiweMessage(
            await signer.getAddress(),
            "Sign in with Ethereum to the app."
          );
          return eip4361Msg;
        } else return "";
      },
      sign: async (c: Context) => {
        const signedMsg = await signInWithEthereum(c.eip4361Msg);
        return signedMsg;
      },
      siwe: async (c: Context) => {
        const result = await fetch(`${API_PATH}/eth/siwe`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            eip_4361_string: c.eip4361Msg,
            signature: c.signature,
          }),
        });
        const json = await result.json();
        return json.jwt;
      },
    },
    actions: {
      updateEip4361Msg: assign((context: Context, event) => {
        return { ...context, eip4361Msg: event.data };
      }),
      updateSignature: assign((context: Context, event) => {
        return { ...context, signature: event.data };
      }),
      updateCustomerJWT: assign((context: Context, event) => {
        return { ...context, customerJWT: event.data };
      }),
    },
  }
);

export default siweMachine;
