import { shortenWalletAddress } from "../../helpers/walletAddress";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import { ReactComponent as MM } from "../../svgs/metamask.svg";
import { ReactComponent as Ruby } from "../../svgs/ruby.svg";

type Props = {
  icon: string;
  balance: string;
  direction: string;
  walletType: string;
  description: string;
};

function ActionFrame(props: Props) {
  return (
    <>
      {props.walletType === "onChain" && (
        <div className="p-5 bg-gradient-to-tl from-stone-700 rounded-2xl">
          <div className="flex justify-between text-smer uppercase">
            <div>{props.direction}</div>
            <div><span className="font-bold">{props.balance}</span> RUBY</div>
          </div>
          <div className="flex gap-4 items-center mt-2">
            <MM className="w-16 h-16" />
            <div className="flex flex-col">
              <div className="text-lger uppercase">Wallet</div>
              <div className="text-smer">
                {shortenWalletAddress(props.description, 6)}
              </div>
            </div>
            <div className="ml-auto">
              <Ruby className="h-16" />
            </div>
          </div>
        </div>
      )}
      {props.walletType === "inGame" && (
        <div className="p-5 bg-gradient-to-br from-slate-800 to-slate-900 rounded-2xl">
          <div className="flex justify-between text-smer uppercase">
            <div>{props.direction}</div>
            <div><span className="font-bold">{props.balance}</span> RUBY</div>
          </div>
          <div className="flex gap-4 items-center mt-2">
            <Logo className="w-16 h-16" />
            <div className="flex flex-col">
              <div className="text-lger uppercase">In-Game</div>
              <div className="text-smer">{props.description}</div>
            </div>
            <div className="ml-auto">
              <Ruby className="h-16" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ActionFrame;
