import { ReactComponent as XMark } from "../../svgs/xmark.svg";
import { ReactComponent as LinkChain } from "../../svgs/linkChain.svg";
import KnightFrame from "../../components/KnightFrame/Index";
import Modal from "../../components/Modal/Index";
import { StateValue } from "xstate";
import { Knight } from "../../inventory/inventory_machine";

interface Props {
  onClose: () => void;
  onConfirmClicked: () => void;
  isShowCloseModal?: boolean;
  promoteState: StateValue;
  knight?: Knight;
  squire?: Knight;
}

function PopUpPromote(props: Props) {
  // define missing fields for Create New Knight
  if (props.knight && !props.knight.name) {
    props.knight.level = '0';
    props.knight.name = 'New Knight';
  }

  return (
    <Modal>
      <button
        className="btn-dim btn-circle btn-sm btn-dismiss"
        id="btn-promote-popup-close"
        onClick={props.onClose}
        title="Cancel Boost"
      >
        <XMark className="w-4" />
      </button>
      <div className="flex flex-col w-full">
        <h2 className="title w-full">
          Boost Knight
        </h2>
        <div
          className="flex w-full items-center justify-center gap-4 md:gap-6">
          <div className="w-1/2">
            <KnightFrame
              knightType={'squire'}
              knightStatus={'in-game'}
              level={props.squire?.level}
              // imgUri={''}
              name={props.squire?.name || ''}
              tokenId={props.squire?.tokenId}
            ></KnightFrame>
          </div>
          <div>
            <LinkChain className="w-8 h-8" />
          </div>
          <div className="w-1/2">
            <KnightFrame
              knightType={props.knight?.name === 'New Knight' ? 'non-nft-new' : 'non-nft'}
              knightStatus={'in-game'}
              level={props.knight?.level}
              // imgUri={i.imgUrl}
              name={props.knight?.name || ''}
            ></KnightFrame>
          </div>
        </div>
        <div className="mt-8">
          { props.promoteState != "promoteSuccessful" &&
            <button
              className={
                "mx-auto btn-boost btn-lg"
              }
              id="btn-promote-popup-confirm"
              onClick={props.onConfirmClicked}
              disabled={ props.promoteState != "confirmPromote" }
            >
              { props.promoteState === "confirmPromote" &&
                <span>Boost</span>
              }
              { props.promoteState === "promoting" &&
                <span>Boosting...</span>
              }
              { props.promoteState === "promoteFailed" &&
                <span>Boost failed!</span>
              }
            </button>
          }
          { props.promoteState === "promoteSuccessful" &&
            <button
              className="btn-boost btn-lg"
              onClick={props.onClose}
            >
              Boost successful!
            </button>
          } 
          <div className={"collapse" + (props.promoteState === "promoteFailed" ? " active" : "")}>
              <div className="flex items-center justify-center gap-4 mt-4 px-6 text-lger">
                  <span>Error! Try again later.</span>
              </div>
            </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopUpPromote;
