const isDevelopment: boolean =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";

export const API_PATH = isDevelopment
  ? "http://localhost:8222"
  : // ? "https://b.auth.dev.cryptoknights.games"
    // : // ? "https://b.auth.dev.cryptoknights.games"
    "https://auth.cryptoknights.games";
export const CHAIN_ID = isDevelopment ? "13131313" : "137";

export const INVENTORY_PATH = isDevelopment
  ? //  "https://inventory.dev.cryptoknights.games"
    "http://localhost:8333"
  : // ? "https://inventory.dev.cryptoknights.games"
    "https://inventory.cryptoknights.games";

export const BRIDGE_PATH = isDevelopment
  ? // ? // "https://ck-bridge.dev.cryptoknights.games"
    "http://127.0.0.1:8088"
  : "https://ck-bridge.cryptoknights.games";

export const DAI_CONTRACT = isDevelopment
? 
  "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0"
: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";

export const KNIGHTMINTER_CONTRACT = isDevelopment
? 
  "0x0B306BF915C4d645ff596e518fAf3F9669b97016"
: "0xB2Db7da9dE22E4A24eBa47c51748BA9eb5faF834";

export const STAKED_RUBY = isDevelopment
? 
  "0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE"
: "0xe1a0879Beaa1d69D92c348A4079E2A483070A097";
