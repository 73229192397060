import { ReactComponent as Cancel } from "../../svgs/cancel.svg";
import { ReactComponent as Success } from "../../svgs/success.svg";
import { ReactComponent as Warning } from "../../svgs/warning.svg";
import { ReactComponent as XMark } from "../../svgs/xmark.svg";
import Modal from "../../components/Modal/Index";

type TransactionStatus = "successful" | "cancelled" | "declined";
type ItemType = "nft" | "withdraw" | "deposit";
interface PopUpTransactionProps {
  transactionStatus: TransactionStatus;
  onClose: () => void;
  itemsType: string;
}

export const PopUpTransaction = ({
  transactionStatus,
  onClose,
  itemsType,
}: PopUpTransactionProps) => {

return (
  <Modal>
    <button
      className="btn-dim btn-circle btn-sm btn-dismiss"
      id="btn-transaction-close"
      onClick={onClose}
      title="Cancel Boost"
    >
      <XMark className="w-4" />
    </button>
    <div className="flex flex-col w-full">
      <h2 className="title w-full">
        Transfer {transactionStatus}
      </h2>
      <div className="self-center">
        {transactionStatus === "successful" ? (
          <Success className="w-16" />
        ) : transactionStatus === "cancelled" ? (
          <Cancel className="w-16" />
        ) : transactionStatus === "declined" ? (
          <Warning className="w-16" />
        ) : (
          <div></div>
        )}
      </div>
      <p className="mt-8 text-lger text-center">
        <strong>{itemsType}</strong> transfer has
        <br/>been <strong>{transactionStatus}</strong>!
      </p>
    </div>
  </Modal>
  );
};
