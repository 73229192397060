import Web3 from "web3";
// import { ERC20, GAME_CUSTODIAL_CONTRACT } from "./env";
export const web3 = new Web3(Web3.givenProvider);

export const createERC20 = async (addr: string) => {
  return new web3.eth.Contract(
    [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "sender",
            type: "address",
          },
          {
            indexed: true,
            name: "receiver",
            type: "address",
          },
          {
            indexed: false,
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        inputs: [
          {
            name: "_name",
            type: "string",
          },
          {
            name: "_symbol",
            type: "string",
          },
          {
            name: "_decimals",
            type: "uint8",
          },
          {
            name: "_supply",
            type: "uint256",
          },
          {
            name: "_minter",
            type: "address",
          },
        ],
        outputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        gas: 76285,
        inputs: [
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 112684,
        inputs: [
          {
            name: "_from",
            type: "address",
          },
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 39404,
        inputs: [
          {
            name: "_spender",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 76904,
        inputs: [
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 76226,
        inputs: [
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 112610,
        inputs: [
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "burnFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 117103,
        inputs: [
          {
            name: "_operator",
            type: "address",
          },
          {
            name: "_from",
            type: "address",
          },
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 40494,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
          {
            name: "_spender",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "setApprove",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 36545,
        inputs: [
          {
            name: "_new_address",
            type: "address",
          },
        ],
        name: "transferMinter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 27218,
        inputs: [],
        name: "self_destruct",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        gas: 7849,
        inputs: [],
        name: "name",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        gas: 6908,
        inputs: [],
        name: "symbol",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        gas: 1520,
        inputs: [],
        name: "decimals",
        outputs: [
          {
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        gas: 1816,
        inputs: [
          {
            name: "arg0",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        gas: 2112,
        inputs: [
          {
            name: "arg0",
            type: "address",
          },
          {
            name: "arg1",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        gas: 1610,
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    addr
  );
};
export const createGameCustodialContract = (addr: string) => {
  return new web3.eth.Contract(
    [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "depositor",
            type: "address",
          },
          {
            indexed: true,
            name: "nft_contract",
            type: "address",
          },
          {
            indexed: true,
            name: "token_id",
            type: "uint256",
          },
        ],
        name: "Deposit721",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "withdrawer",
            type: "address",
          },
          {
            indexed: true,
            name: "nft_contract",
            type: "address",
          },
          {
            indexed: true,
            name: "token_id",
            type: "uint256",
          },
        ],
        name: "Withdraw721",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "withdrawer",
            type: "address",
          },
          {
            indexed: false,
            name: "nonce",
            type: "uint256",
          },
        ],
        name: "IncrementNonce",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "depositor",
            type: "address",
          },
          {
            indexed: true,
            name: "token_address",
            type: "address",
          },
          {
            indexed: false,
            name: "amount",
            type: "uint256",
          },
        ],
        name: "Deposit20",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "withdrawer",
            type: "address",
          },
          {
            indexed: true,
            name: "token_address",
            type: "address",
          },
          {
            indexed: false,
            name: "amount",
            type: "uint256",
          },
        ],
        name: "Withdraw20",
        type: "event",
      },
      {
        inputs: [
          {
            name: "_bridgeEOA",
            type: "address",
          },
        ],
        name: "custodialInit",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "get_chain_id",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "nft_contract",
            type: "address",
          },
          {
            name: "token_id",
            type: "uint256",
          },
        ],
        name: "deposit721",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "_sig",
            type: "bytes",
          },
          {
            name: "_nft_contract",
            type: "address",
          },
          {
            name: "_token_id",
            type: "uint256",
          },
          {
            name: "_expirytimestamp",
            type: "uint256",
          },
          {
            name: "_nonce",
            type: "uint256",
          },
        ],
        name: "withdraw721",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "cancel_all_withdraws",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "token_address",
            type: "address",
          },
          {
            name: "amount",
            type: "uint256",
          },
        ],
        name: "deposit20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "_sig",
            type: "bytes",
          },
          {
            name: "_token_address",
            type: "address",
          },
          {
            name: "_amount",
            type: "uint256",
          },
          {
            name: "_expirytimestamp",
            type: "uint256",
          },
          {
            name: "_nonce",
            type: "uint256",
          },
        ],
        name: "withdraw20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "player",
            type: "address",
          },
        ],
        name: "get_user_inventory",
        outputs: [],
        stateMutability: "pure",
        type: "function",
      },
      {
        inputs: [
          {
            name: "_new_owner",
            type: "address",
          },
        ],
        name: "update_owner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "_new_bridgeEOA",
            type: "address",
          },
        ],
        name: "update_bridgeEOA",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "contractOperator",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "arg0",
            type: "address",
          },
          {
            name: "arg1",
            type: "address",
          },
          {
            name: "arg2",
            type: "uint256",
          },
        ],
        name: "holdings",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "bridgeEOA",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "arg0",
            type: "address",
          },
        ],
        name: "user_nonces",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "arg0",
            type: "address",
          },
        ],
        name: "user_items",
        outputs: [
          {
            components: [
              {
                name: "nft_contract",
                type: "address",
              },
              {
                name: "token_id",
                type: "uint256",
              },
            ],
            name: "",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [
          {
            name: "",
            type: "bytes32",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    addr
  );
};

export const createSquireContract = (addr: string) => {
  return new web3.eth.Contract(
    [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "sender",
            type: "address",
          },
          {
            indexed: true,
            name: "receiver",
            type: "address",
          },
          {
            indexed: true,
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "holder",
            type: "address",
          },
          {
            indexed: true,
            name: "approved",
            type: "address",
          },
          {
            indexed: true,
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "holder",
            type: "address",
          },
          {
            indexed: true,
            name: "operator",
            type: "address",
          },
          {
            indexed: false,
            name: "approved",
            type: "bool",
          },
        ],
        name: "ApprovalForAll",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            name: "clear_to_send",
            type: "bool",
          },
        ],
        name: "TransferAllowanceChange",
        type: "event",
      },
      {
        inputs: [
          {
            name: "_name",
            type: "string",
          },
          {
            name: "_symbol",
            type: "string",
          },
          {
            name: "baseURI",
            type: "string",
          },
          {
            name: "artist",
            type: "address",
          },
          {
            name: "_max_supply",
            type: "uint256",
          },
        ],
        outputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [],
        name: "setDomainSeparator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "interface_id",
            type: "bytes4",
          },
        ],
        name: "supportsInterface",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "pure",
        type: "function",
      },
      {
        inputs: [
          {
            name: "artist",
            type: "address",
          },
        ],
        name: "setArtist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "address",
          },
          {
            name: "salePrice",
            type: "uint256",
          },
        ],
        name: "royaltyInfo",
        outputs: [
          {
            components: [
              {
                name: "receiver",
                type: "address",
              },
              {
                name: "royaltyAmount",
                type: "uint256",
              },
            ],
            name: "",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "tokenURI",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "baseURI",
            type: "string",
          },
        ],
        name: "updateBaseURI",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "getApproved",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "releaseForTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "restrictTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "holder",
            type: "address",
          },
          {
            name: "receiver",
            type: "address",
          },
          {
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "holder",
            type: "address",
          },
          {
            name: "receiver",
            type: "address",
          },
          {
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "holder",
            type: "address",
          },
          {
            name: "receiver",
            type: "address",
          },
          {
            name: "tokenId",
            type: "uint256",
          },
          {
            name: "data",
            type: "bytes",
          },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "approved",
            type: "address",
          },
          {
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "operator",
            type: "address",
          },
          {
            name: "approved",
            type: "bool",
          },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "minter",
            type: "address",
          },
        ],
        name: "setMinter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "receiver",
            type: "address",
          },
          {
            name: "quantity",
            type: "uint256",
          },
        ],
        name: "batchMintTo",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "receiver",
            type: "address",
          },
        ],
        name: "mint",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            name: "holder",
            type: "address",
          },
          {
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "burn_for",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "self_destruct",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "baseURI",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "CLEAR_TO_SEND",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "arg0",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "arg0",
            type: "uint256",
          },
        ],
        name: "ownerOf",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "arg0",
            type: "address",
          },
          {
            name: "arg1",
            type: "address",
          },
        ],
        name: "isApprovedForAll",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "contract_operator",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "minter",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "artist",
        outputs: [
          {
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            name: "arg0",
            type: "uint256",
          },
        ],
        name: "nonces",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [
          {
            name: "",
            type: "bytes32",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    addr
  );
};

export const createKnightPriceQuote = (addr: string) => {
  return new web3.eth.Contract(
    [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "name": "receiver",
            "type": "address"
          },
          {
            "indexed": true,
            "name": "token_contract",
            "type": "address"
          },
          {
            "indexed": false,
            "name": "first_id",
            "type": "uint256"
          },
          {
            "indexed": false,
            "name": "qty",
            "type": "uint256"
          },
          {
            "indexed": false,
            "name": "total_cost",
            "type": "uint256"
          }
        ],
        "name": "TokensMintedToGame",
        "type": "event"
      },
      {
        "inputs": [
          {
            "name": "_nft",
            "type": "address"
          },
          {
            "name": "_treasury",
            "type": "address"
          },
          {
            "name": "_oracle",
            "type": "address"
          },
          {
            "name": "_game_wallet",
            "type": "address"
          }
        ],
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "inputs": [
          {
            "name": "qty",
            "type": "uint256"
          },
          {
            "name": "currency",
            "type": "address"
          }
        ],
        "name": "price_quote",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "name": "final_price",
            "type": "uint256"
          },
          {
            "name": "currency",
            "type": "address"
          }
        ],
        "name": "callback",
        "outputs": [
          {
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "name": "qty",
            "type": "uint256"
          },
          {
            "name": "spend_currency",
            "type": "address"
          },
          {
            "name": "max_spend",
            "type": "uint256"
          }
        ],
        "name": "buy_tokens",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          },
          {
            "name": "",
            "type": "uint256"
          },
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "name": "qty",
            "type": "uint256"
          },
          {
            "name": "spend_currency",
            "type": "address"
          },
          {
            "name": "max_spend",
            "type": "uint256"
          }
        ],
        "name": "buy_tokens_for_account",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          },
          {
            "name": "",
            "type": "uint256"
          },
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "name": "qty",
            "type": "uint256"
          },
          {
            "name": "spend_currency",
            "type": "address"
          },
          {
            "name": "max_spend",
            "type": "uint256"
          },
          {
            "name": "_receiver",
            "type": "address"
          }
        ],
        "name": "buy_tokens_for_account",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          },
          {
            "name": "",
            "type": "uint256"
          },
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ],
    addr
  );
};

export const createRubyStake = (addr: string) => {
  return new web3.eth.Contract(
    [
      {
        "inputs": [
          {
            "internalType": "contract IERC20",
            "name": "_ruby",
            "type": "address"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "delegator",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "fromDelegate",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "toDelegate",
            "type": "address"
          }
        ],
        "name": "DelegateChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "delegate",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "previousBalance",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "newBalance",
            "type": "uint256"
          }
        ],
        "name": "DelegateVotesChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "DELEGATION_TYPEHASH",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "DOMAIN_TYPEHASH",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_account",
            "type": "address"
          }
        ],
        "name": "RUBYBalance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "rubyAmount_",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_rubyAmount",
            "type": "uint256"
          }
        ],
        "name": "RUBYForxRUBY",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "xRUBYAmount_",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          }
        ],
        "name": "allowance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "uint32",
            "name": "",
            "type": "uint32"
          }
        ],
        "name": "checkpoints",
        "outputs": [
          {
            "internalType": "uint32",
            "name": "fromBlock",
            "type": "uint32"
          },
          {
            "internalType": "uint256",
            "name": "votes",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "decimals",
        "outputs": [
          {
            "internalType": "uint8",
            "name": "",
            "type": "uint8"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "subtractedValue",
            "type": "uint256"
          }
        ],
        "name": "decreaseAllowance",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "delegatee",
            "type": "address"
          }
        ],
        "name": "delegate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "delegatee",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "nonce",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "expiry",
            "type": "uint256"
          },
          {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
          },
          {
            "internalType": "bytes32",
            "name": "r",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
          }
        ],
        "name": "delegateBySig",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "delegator",
            "type": "address"
          }
        ],
        "name": "delegates",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "enter",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "getCurrentVotes",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "blockNumber",
            "type": "uint256"
          }
        ],
        "name": "getPriorVotes",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "addedValue",
            "type": "uint256"
          }
        ],
        "name": "increaseAllowance",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_share",
            "type": "uint256"
          }
        ],
        "name": "leave",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "nonces",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "numCheckpoints",
        "outputs": [
          {
            "internalType": "uint32",
            "name": "",
            "type": "uint32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "ruby",
        "outputs": [
          {
            "internalType": "contract IERC20",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "recipient",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "sender",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "recipient",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_xRUBYAmount",
            "type": "uint256"
          }
        ],
        "name": "xRUBYForRUBY",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "rubyAmount_",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
    addr
  );
};
