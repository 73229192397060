import { ReactComponent as XMark } from "../../svgs/xmark.svg";
import { useMemo, useState } from "react";
import { StateValue } from "xstate";
import KnightFrame from "../../components/KnightFrame/Index";
import Modal from "../../components/Modal/Index";
import { Knight } from "../../inventory/inventory_machine";

interface Props {
  onClose: () => void;
  onApproveClicked: () => void;
  onConfirmImportClicked: () => void;
  depositStatus: StateValue;
  selectedKnight?: Knight; // << change to index
  //TODO: add knight type
}

// TODO: Receive meta data for display knight info

function PopUpApprove(props: Props) {
  const [isApproved, setIsApproved] = useState(false);
  const [isDisplayImport, setIsDisplayImport] = useState(false);
  useMemo(() => {
    if (props.depositStatus === "approved") {
      let t = setTimeout(() => setIsApproved(true), 2000);
      return () => {
        clearTimeout(t);
      };
    }
  }, [props.depositStatus]);

  useMemo(() => {
    if (isApproved) {
      let t = setTimeout(() => setIsDisplayImport(true), 700);
      return () => {
        clearTimeout(t);
      };
    }
  }, [isApproved]);

  return (
    <Modal size="lg">
      <div className="relative flex justify-center items-center">
        <button
          className="btn-dim btn-circle btn-sm btn-dismiss"
          id="btn-import-close"
          onClick={props.onClose}
          title="Close Transfer Popup"
        >
          <XMark className="w-4" />
        </button>
      </div>
      <h2 className="title w-full">
        Deposit Knight
      </h2>
      <div className="flex flex-col lg:flex-row justify-center gap-4 mt-8">
        <div className="w-40 mx-auto mb-4 text-center">
          <KnightFrame
            knightType={props.selectedKnight?.name === 'squire' ? 'squire' : 'nft'}
            knightStatus={'on-chain'}
            level={props.selectedKnight?.level}
            // imgUri={''}
            name={props.selectedKnight?.name || ''}
            tokenId={props.selectedKnight?.tokenId}
          ></KnightFrame>
        </div>
        <div className="w-full">
          {/* step 1 */}
          <div
            className={
              (isApproved ? "opacity-40" : "") +
              " transition-all delay-150 duration-900"
            }
          >
            <div className="flex items-center gap-4 px-6 pb-4">
              <div
                className="flex justify-center items-center w-10 h-10 bg-gradient-to-b from-violet-500 to-violet-800 rounded-full"
              >
                <span className="font-bold text-center">
                  1
                </span>
              </div>
              <h3 className="mb-0">
                Approve transfer
              </h3>
            </div>

            <div
              className={
                "bg-gradient-to-tl from-slate-800 rounded-2xl transition-all delay-150 duration-700 " +
                (isApproved ? "h-0" : "h-auto")
              }
            >
              {isApproved || (
                <div className="p-8">
                  <p>
                    To transfer the Knight into the game, you must approve this item for transfer.
                  </p>
                  {props.depositStatus === "checkingApproval" ? (
                    <div className="font-bold text-neutral-400 mt-2">
                      checking for approval...
                    </div>
                  ) : props.depositStatus === "waitForApprove" ? (
                    <div className="flex justify-center lg:justify-start">
                      <button
                        onClick={props.onApproveClicked}
                        className={
                          "btn-ie mt-4 w-48"
                        }
                        id="btn-import-approve"
                      >
                        Approve
                      </button>
                    </div>
                  ) : props.depositStatus === "approving" ? (
                    <div className="text-center text-white font-bold mt-4 lg:text-left">
                      waiting for approval...
                    </div>
                  ) : props.depositStatus === "approved" ? (
                    <div className="text-center lg:text-left font-bold text-emerald-400 mt-2">
                      Approve successful
                    </div>
                  ) : props.depositStatus === "approveFailed" ? (
                    <div className="text-center lg:text-left font-bold text-red-400 mt-2">
                      Approve Failed
                    </div>
                  ) : (
                    <div>loading...?</div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* step 2 */}
          <div className={isApproved ? "mt-4" : "mt-4 opacity-40"}>
            <div className="flex items-center gap-4 px-6 pb-4">
              <div
                className="flex justify-center items-center w-10 h-10 bg-gradient-to-b from-violet-500 to-violet-800 rounded-full"
              >
                <span className="font-bold text-center">
                  2
                </span>
              </div>
              <h3 className="mb-0">
                Confirm Import
              </h3>
            </div>

            <div
              className={
                "bg-gradient-to-tl from-slate-800 rounded-2xl transition-all delay-150 duration-700 " +
                (isApproved ? "h-auto" : "h-0")
              }
            >
              {isApproved && (
                <div
                  className={
                    "p-8 transition-opacity duration-100 " +
                    (isDisplayImport ? "opacity-100" : "opacity-0")
                  }
                >
                  <p>
                    Transfer from your wallet to the <strong>CryptoKnights</strong> game.
                  </p>

                  {props.depositStatus === "approved" ? (
                    <div className="flex flex-col justify-center items-start">
                      <button
                        onClick={props.onConfirmImportClicked}
                        className="btn-ie mt-4 w-48"
                        id="btn-import-confirm"
                      >
                        Import to game
                      </button>
                    </div>
                  ) : props.depositStatus === "importing" ||
                    "waitForBlockConfirmImport" ? (
                    <div className="mt-4 text-center text-white font-bold lg:text-left">
                      Importing to <strong>CryptoKnights</strong>...
                    </div>
                  ) : props.depositStatus === "importFailed" ? (
                    <div className="text-center lg:text-left font-bold text-red-400 mt-4 lg:mt-2">
                      Import failed!
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopUpApprove;
