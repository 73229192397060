import { assign, createMachine } from "xstate";
import { publish } from "./app_events";
import { BRIDGE_PATH } from "./env";

type Context = {
  contract: string;
  erc20: string;
  erc721: string;
  gameCustodial: string;
};
export const mainMachine = createMachine(
  {
    id: "main",
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
    },
    context: {
      contract: "",
      erc20: "",
      erc721: "",
      gameCustodial: "",
    } as Context,
    initial: "login",
    entry: ["listenerAccountChange"],
    states: {
      login: { on: { login__success: "prepareContract" } },
      prepareContract: {
        invoke: {
          id: "fetch-contract-address",
          src: "fetchContractAddr",
          onDone: {
            target: "inventory",
            actions: "updateContractAddress",
          },
          onError: {
            target: "error",
          },
        },
      },
      inventory: {
        on: {
          main__wallet_selected: { target: "wallet" },
          main__buy_selected: { target: "buy" },
          main__promote_knight: { target: "promoteKnight" },
        },
      },
      wallet: {
        on: {
          main__inventory_selected: { target: "inventory" },
          main__buy_selected: { target: "buy" },
          main__wallet_deposit: { target: "rubyDeposit" },
          main__wallet_withdraw: { target: "rubyWithdraw" },
        },
      },
      buy: {
        on: {
          main__inventory_selected: { target: "inventory" },
          main__wallet_selected: { target: "wallet" },
        },
      },
      rubyDeposit: {
        on: {
          main__deposit_cancel: { target: "wallet" },
          swap__transaction_clicked: { target: "rubyWithdraw" },
        },
      },
      rubyWithdraw: {
        on: {
          main__withdraw_cancel: { target: "wallet" },
          swap__transaction_clicked: { target: "rubyDeposit" },
        },
      },
      error: {},
      promoteKnight: {},
    },
  },
  {
    services: {
      fetchContractAddr: async () => {
        const resp = await fetch(`${BRIDGE_PATH}/config.json`);
        const json = await resp.json();
        console.log('payload??')
        console.log(json)
        const erc20 = Object.keys(json.erc20)[0];
        const erc721 = Object.keys(json.erc721)[0];
        const finalityBlocks = json.finality_blocks;
        const gameCustodial = json.custodial_smartcontract;
        publish(
          {
            type: "has_contract",
            payload: {
              erc721: erc721,
              ruby: erc20,
              gameCustodial: gameCustodial,
              finality_blocks: finalityBlocks,
            },
          },
          "main"
        );
        return {
          ruby: erc20,
          erc721: erc721,
          gameCustodial: gameCustodial,
        };
      },
    },
    actions: {
      listenerAccountChange: (_, event) => {
        if (window.ethereum) {
          window.ethereum.on("accountsChanged", () => {
            window.location.reload();
          });
        }
      },
      updateContractAddress: assign((context, event: any) => {
        return {
          ...context,
          erc20: event.data.ruby,
          erc721: event.data.erc721,
        };
      }),
    },
  }
);
