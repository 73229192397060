import Web3 from "web3";
import { assign, createMachine } from "xstate";
import { BRIDGE_PATH, INVENTORY_PATH } from "../env";
import {
  ConvertToFixBitPrice,
  priceString,
  toFixedWithoutZeros,
} from "../helpers/convertWei";
import { fetchX } from "../helpers/fetchX";
import { parseJwt } from "../helpers/jwtParser";
import { createERC20, createGameCustodialContract } from "../web3";
import {
  gasOracle
} from "../helpers/gasOracle";
type Context = {
  walletAddress: string;
  playerEmail: string;
  inGameBalance: string; // need to find how to get this
  onChainBalance: string;
  decimal: number;
  transactionValue: string; // normal like 0.4 ruby not wei
  status: string | null;
  erc20: string;
  gameCustodial: string;
  finality_blocks: number;
};
type Withdrawal = {
  token_address: string;
  token_id: string;
  expiry: number;
  nonce: number;
  signature: string;
};
async function initWithdrawal(
  contract: string,
  amount: string
): Promise<Withdrawal> {
  const jwt = localStorage.getItem("CustomerJWT");
  const resp = await fetch(`${BRIDGE_PATH}/erc20/${contract}/withdraw/`, {
    body: JSON.stringify({ amount: ConvertToFixBitPrice(amount, 18) }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    method: "PUT",
  });
  const r = await resp.json();
  console.log("response from bridge", r);
  return {
    token_address: r.token_address, // addr
    token_id: r.token_id, // 0xblahblah
    expiry: r.expires, // timestamp
    nonce: r.nonce, //uint
    signature: r.signature, //byte
  };
}
export const withdrawMachine = createMachine(
  {
    id: "withdraw",
    tsTypes: {} as import("./withdraw_machine.typegen").Typegen0,
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
    },
    context: {
      walletAddress: "",
      transactionValue: "",
      playerEmail: "",
      onChainBalance: "",
      inGameBalance: "",
      decimal: 18,
      status: null,
      erc20: "",
      gameCustodial: "",
      finality_blocks: 5,
    },
    initial: "waitForContract",
    on: {
      close_popup_modal: {
        target: "idle",
      },
      swap__transaction_clicked: {
        target: "loading",
      },
    },
    states: {
      waitForContract: {
        on: {
          has_contract: {
            target: "waitForStartSignal",
            actions: "updateContractAddr",
          },
        },
      },
      waitForStartSignal: {
        on: {
          withdraw_start: {
            target: "loading",
            actions: "updateWallet",
          },
        },
      },
      loading: {
        // fetch every thing by wallet address etc.ruby balance on chain , game info
        invoke: {
          id: "fetchinfo",
          src: "fetchInfo",
          onDone: {
            target: "idle",
            actions: "updateInfo",
          },
          onError: {
            target: "error",
          },
        },
      },
      idle: {
        on: {
          withdraw__submitted: {
            target: "transfering",
            actions: "updateTransactionValue",
          },
        },
      },
      transfering: {
        invoke: {
          id: "withdraw-ruby",
          src: "withdrawRuby",
          onDone: {
            target: "waitForBlockConfirmation",
          },
          onError: {
            target: "transferError",
          },
        },
      },
      waitForBlockConfirmation: {
        invoke: {
          id: "waitforXblock",
          src: "waitBlockConfirm",
          onDone: {
            target: "transfered",
          },
          onError: {
            target: "transferError",
          },
        },
      },
      transfered: {
        on: {
          close_popup_modal: {
            target: "loading",
          },
          main__withdraw_cancel: {
            target: "waitForStartSignal",
          },
        },
      },
      transferError: {},
      error: {},
    },
  },
  {
    services: {
      withdrawRuby: async (context) => {
        const resp = await initWithdrawal(
          context.erc20,
          toFixedWithoutZeros(
            priceString(context.transactionValue, context.decimal),
            4
          )
        );
        const gameCustodialContract = await createGameCustodialContract(
          context.gameCustodial
        );

        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })

        const transac = await gameCustodialContract.methods
          .withdraw20(
            resp.signature,
            resp.token_address,
            context.transactionValue,
            resp.expiry,
            resp.nonce
          )
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(function (result: any) {
            console.log("[DEBUG] result transaction", result);
            return result;
          });
      },
      waitBlockConfirm: async (context) => {
        let web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8545");
        let isFirstBlockAppear = false;
        let qty = 0;
        var subscription = web3.eth
          .subscribe("newBlockHeaders", function (error, result) {
            if (!error) {
              qty += 1;
              isFirstBlockAppear = true;
              return;
            }
            console.error(error);
          })
          .on("error", console.error);
        console.log("finality ", context.finality_blocks);
        while (isFirstBlockAppear === false) {
          await new Promise((r) => setTimeout(r, 2000));
          while (qty <= context.finality_blocks) {
            await new Promise((r) => setTimeout(r, 2000));
            console.log("block added", qty);
          }
        }
        subscription.unsubscribe(function (error, success) {
          if (success) {
            console.log("Successfully unsubscribed!");
          } else {
            console.log("error", error);
          }
        });
      },
      fetchInfo: async (context) => {
        const jwt = localStorage.getItem("CustomerJWT");
        const data = parseJwt(jwt || "");
        const email = data.email;
        const resp = await fetchX(
          `${INVENTORY_PATH}/account/${data.identity_id}/RUBY`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + jwt,
            },
          }
        );
        const contract = await createERC20(context.erc20);
        const rubyBalance = await contract.methods
          .balanceOf(context.walletAddress)
          .call()
          .then(function (result: any) {
            return result;
          });
        const decimal = await contract.methods
          .decimals()
          .call()
          .then(function (result: any) {
            return result;
          });
        return {
          inGameBalance: toFixedWithoutZeros(
            priceString(resp.balance.toString(), 18),
            4
          ),
          onChainBalance: toFixedWithoutZeros(
            priceString(rubyBalance, parseInt(decimal)),
            4
          ),
          decimal: decimal,
          playerEmail: email,
        };
      },
    },
    actions: {
      updateContractAddr: assign((context, event: any) => {
        return {
          ...context,
          erc20: event.payload.ruby,
          gameCustodial: event.payload.gameCustodial,
          finality_blocks: event.payload.finality_blocks,
        };
      }),
      updateTransactionValue: assign((context, event: any) => {
        return { ...context, transactionValue: event.value };
      }),
      updateInfo: assign((context, event: any) => {
        return {
          ...context,
          inGameBalance: event.data.inGameBalance,
          onChainBalance: event.data.onChainBalance,
          playerEmail: event.data.playerEmail,
        };
      }),
      updateWallet: assign((context, event) => {
        return { ...context, walletAddress: event.wallet };
      }),
    },
  }
);
