import { useSelector } from "@xstate/react";
import { actorsInterpreter } from "../actors_machine";

const { main } = actorsInterpreter.getSnapshot().context;

export function useErc20Address() {
  const erc20Address = useSelector(main, (state) => state.context.erc20);
  return erc20Address;
}
export function useGameCustodialAddress() {
  const gameCustodial = useSelector(
    main,
    (state) => state.context.gameCustodial
  );
  return gameCustodial;
}

export function useErc721Address() {
  const gameCustodial = useSelector(main, (state) => state.context.erc721);
  return gameCustodial;
}
