import { useState } from "react";
import { ReactComponent as Chevron } from "../svgs/chevron.svg";

export default function LoginTemplate(props: {
  title?: string;
  description?: any;
  contents?: any;
}) {
  const [openHelp, setOpenHelp] = useState(false);

  const toggleHelp = ():void => {
    setOpenHelp(!openHelp)
  }
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row items-center">
        </div>
        <div className="w-full p-8 bg-gradient-to-br from-slate-800 to-slate-900 rounded-2xl md:max-w-md">
          {props.title && (
            <h2 className="flex gap-2 items-center justify-center">
              <span>{props.title}</span>
              <button
                className="btn-dim btn-xs btn-circle"
                title="More details about associating wallet"
                onClick={toggleHelp}
              >
                <Chevron className={"w-3" + (openHelp ? " rotate-180" : "")} />
              </button>
            </h2>
          )}
          <div className={"collapse" + (openHelp ? " active" : "")}>
            <div className="px-1 pb-6 text-neutral-300">
              {props.description && (
                <>{props.description}</>
              )}
            </div>
          </div>
          {props.contents}
        </div>
      </div>
    </div>
  );
}
