import { useActor } from "@xstate/react";
import React, { useCallback, useState } from "react";
import { actorsInterpreter } from "./actors_machine";
import MainMenuMobile from "./components/MainMenuMobile/Index";
import Footer from "./Footer";
import Header from "./Header";
import { useIsLoginSuccess } from "./hooks/useIsLoginSuccess";
import { useWalletAddress } from "./hooks/useWalletAddress";
import { useErc20Address } from "./hooks/useContract";
import divider from "./images/divider.png";
import Login from "./login/Login";
import SideBar from "./components/SideBar/Index";
import Inventory from "./inventory/Index";
import MyWallet from "./myWallet/Index";
import Buy from "./buy/Index";
import Deposit from "./rubyDeposit/Index";
import Withdraw from "./rubyWithdraw/Index";
const { main } = actorsInterpreter.getSnapshot().context;

function Main() {
  const [mainState] = useActor(main);
  const walletAddress = useWalletAddress();
  const erc20Address = useErc20Address();
  return (
    <div className="relative min-h-screen pt-header">
      <div
        className={`${
          mainState.matches("buy") ||
          mainState.matches("inventory") ||
          mainState.matches("rubyDeposit") ||
          mainState.matches("rubyWithdraw") ||
          mainState.matches("wallet")
            ? "flex frame pb-8 md:pb-12 lg:pb-20 xl:pb-24"
            : "flex"
          }`}
      >
        <div
          className={`${
            mainState.matches("login") ||
            mainState.matches("rubyDeposit") ||
            mainState.matches("rubyWithdraw")
              ? "hidden"
              : "hidden md:flex md:w-1/4 "
          }`}
        >
          <SideBar stateSelected={mainState.value} />
        </div>
        <div
          className={`${
            mainState.matches("login") ||
            mainState.matches("rubyDeposit") ||
            mainState.matches("rubyWithdraw")
              ? "w-full"
              : "w-full md:w-3/4"
          } `}
        >
          {mainState.matches("login") && <Login />}
          {mainState.matches("inventory") && <Inventory />}
          {mainState.matches("rubyDeposit") && <Deposit />}
          {mainState.matches("wallet") && (
            <MyWallet
              walletAddress={walletAddress}
              erc20Address={erc20Address}
            ></MyWallet>
          )}
          {mainState.matches("rubyWithdraw") && <Withdraw />}
          {mainState.matches("buy") && <Buy />}
        </div>
      </div>
    </div>
  );
}

function App() {
  const [menuOpened, setMenuOpened] = useState(false);
  const walletAddress = useWalletAddress();
  const isLoginSuccess = useIsLoginSuccess();
  const toggleMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuOpened(!menuOpened);
  };
  const onOutOfMenuClicked = useCallback(() => {
    setMenuOpened(false);
  }, []);

  return (
    <div className="relative">
      <div onClick={onOutOfMenuClicked}>
        <Header
          toggleMenu={toggleMenu}
          menuOpened={menuOpened}
          walletAddress={walletAddress}
        ></Header>
        <div
          className={
            (menuOpened ? "" : "hidden ") +
            "absolute top-18 lg:fixed lg:top-20 right-0 z-20"
          }
          >
          <MainMenuMobile
            isLoginSuccess={isLoginSuccess}
            walletAddress={walletAddress}
            toggleMenu={toggleMenu}
          ></MainMenuMobile>
        </div>
        <Main></Main>
        <img className="h-2 w-full" src={divider} alt=""></img>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
