import { STAKED_RUBY } from "../env";
import { assign, createMachine } from "xstate";
import {
  ConvertToFixBitPrice,
  priceString,
  toFixedWithoutZeros,
} from "../helpers/convertWei";
import {
  gasOracle
} from "../helpers/gasOracle";
import {
  createERC20,
  createRubyStake,
} from "../web3";
type Context = {
  stakeVirgin: string;
  stakeFull: string;
  stakeQty: string;
  unstakeQty: string;
  stakeQuote: string;
  stakeQuoteWei: string;
  totalStaked: string;
  APR: string;
  walletAddress: string;
  selectedMenu: string;
  erc20: string;
  erc721: string;
  gameCustodial: string;
  finality_blocks: number;
  allowance: string;
};

export const walletMachine = createMachine(
  {
    id: "wallet",
    tsTypes: {} as import("./wallet_machine.typegen").Typegen0,
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
    },

    context: {
      stakeVirgin: "0",
      stakeFull: "0",
      stakeQty: "0",
      unstakeQty: "0",
      stakeQuote: "0",
      stakeQuoteWei: "0",
      totalStaked: "0",
      APR: "0",
      walletAddress: "",
      selectedMenu: "inGame",
      erc20: "",
      erc721: "",
      gameCustodial: "",
      finality_blocks: 1,
      allowance: "",
    } as Context,

    initial: "idle",
    states: {
      idle: {
        on: {
          wallet__stake_ruby_clicked: {
            target: "stakeRubyQuoting",
          },
          wallet__unstake_ruby_clicked: {
            target: "unstakeRubyQuoting",
          },
        },
      },
      stakeRubyQuoting: {
        invoke: {
          id: "id-stakeRubyQuoting",
          src: "stakeRubyGetQuote",
          onDone: {
            target: "stakeRubyQuoteSuccess",
            actions: "updateStakeRubyQuote",
          },
          onError: {
            target: "stakeRubyQuoteFail",
          },
        },
      },
      stakeRubyQuoteSuccess: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
          wallet__get_stake_ruby_qty_input: {
            target: "stakeRubyQuoting",
          },
          wallet__stake_ruby_approve_clicked: {
            target: "stakeRubyApproving",
          },
        },
      },
      stakeRubyQuoteFail: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
          wallet__get_stake_ruby_qty_input: {
            target: "stakeRubyQuoting",
          },
        },
      },
      stakeRubyApproving: {
        invoke: {
          id: "id-stakeRubyApproving",
          src: "approveRequest",
          onDone: {
            target: "stakeRubyApproveSuccess",
          },
          onError: {
            target: "stakeRubyQuoteSuccess",
          },
        },
      },
      stakeRubyApproveSuccess: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
          wallet__stake_ruby_stake_clicked: {
            target: "stakeRubyStaking",
          },
        },
      },
      stakeRubyStaking: {
        invoke: {
          id: "id-stakeRubyStaking",
          src: "stakeRubyStake",
          onDone: {
            target: "stakeRubyStakeSuccess",
          },
          onError: {
            target: "stakeRubyStakeFail",
          },
        },
      },
      stakeRubyStakeSuccess: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
        },
      },
      stakeRubyStakeFail: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
        },
      },
      unstakeRubyQuoting: {
        invoke: {
          id: "id-unstakeRubyQuoting",
          src: "unstakeRubyGetQuote",
          onDone: {
            target: "unstakeRubyQuoteSuccess",
            actions: "updateStakeRubyQuote",
          },
          onError: {
            target: "unstakeRubyQuoteFail",
          },
        },
      },
      unstakeRubyQuoteSuccess: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
          wallet__get_unstake_ruby_qty_input: {
            target: "unstakeRubyQuoting",
          },
          wallet__unstake_ruby_stake_clicked: {
            target: "unstakeRubyStaking",
          },
        },
      },
      unstakeRubyQuoteFail: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
          wallet__get_unstake_ruby_qty_input: {
            target: "unstakeRubyQuoting",
          },
        },
      },
      unstakeRubyStaking: {
        invoke: {
          id: "id-unstakeRubyStaking",
          src: "unstakeRubyStake",
          onDone: {
            target: "unstakeRubyStakeSuccess",
          },
          onError: {
            target: "unstakeRubyStakeFail",
          },
        },
      },
      unstakeRubyStakeSuccess: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
        },
      },
      unstakeRubyStakeFail: {
        on: {
          wallet__close_modal_clicked: {
            target: "idle",
            actions: "stakeRubyReset",
          },
          wallet__unstake_ruby_stake_clicked: {
            target: "unstakeRubyStaking",
          },
        },
      },
    },
  },
  {
    services: {
      stakeRubyGetQuote: async (context, event: any) => {
        const balanceContract = await createERC20(context.erc20);
        const balanceDecimal = await balanceContract.methods
          .decimals()
          .call()
          .then(function (result: any) {
            return result;
          });

        const balanceOf = await balanceContract.methods
          .balanceOf(STAKED_RUBY)
          .call()
          .then(function (result: any) {
            console.log(result)
            return result;
          })

        const totalStaked = toFixedWithoutZeros(
          priceString(balanceOf.toString(), balanceDecimal),
          4
        )

        const contract = await createERC20(STAKED_RUBY);
        const decimal = await contract.methods
          .decimals()
          .call()
          .then(function (result: any) {
            return result;
          });
        
        const quoteContract = await createRubyStake(STAKED_RUBY);
        let stakeVirgin = await quoteContract.methods
          .balanceOf(context.walletAddress)
          .call()
          .then(function (result: any) {
            console.log(result)
            return result;
          })
        stakeVirgin = toFixedWithoutZeros(
            priceString(stakeVirgin.toString(), balanceDecimal),
            4
          )

        const stakeQuote = await quoteContract.methods
          .RUBYForxRUBY(context.stakeQty)
          .call()
          .then(function (result: any) {
            console.log(result)
            return result;
          })

        // will throw an error, if runs with 0
        let stakeFull = "0";
        if (parseFloat(stakeVirgin) > 0) {
          stakeFull = await quoteContract.methods
            .RUBYBalance(context.walletAddress)
            .call()
            .then(function (result: any) {
              console.log(result)
              return result;
            })
          stakeFull = toFixedWithoutZeros(
            priceString(stakeFull.toString(), balanceDecimal),
            4
          )
        }

        const stakeQuoteWei = ConvertToFixBitPrice(stakeQuote, decimal)
        
        console.log('stakeVirgin', stakeVirgin, 'stakeFull', stakeFull, 'stakeQuote', stakeQuote)

        // 365 days + leap year? from Yukai
        const APR = 3652500 / parseFloat(totalStaked);

        return {
          ...context,
          stakeVirgin: stakeVirgin,
          stakeFull: stakeFull,
          stakeQuote: stakeQuote,
          stakeQuoteWei: stakeQuoteWei,
          totalStaked: totalStaked,
          APR: APR
        };
        
        // if (!isApproved) throw new Error("not approve");
      },
      approveRequest: async (context) => {
        const contract = await createERC20(context.erc20);
        // let approve;
        const oracleResult = await gasOracle().then(function(result){
          return result;
        })
        const approve = await contract.methods
          .approve(
            STAKED_RUBY,
            context.stakeQuoteWei
          )
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(function (result: any) {
            console.log(result)
            return JSON.stringify(result);
          });
          // return approve;
      },
      stakeRubyStake: async (context) => {
        const oracleResult = await gasOracle().then(function(result){
          return result;
        })
        // qty, spend_currency, max_spend
        const contract = await createRubyStake(STAKED_RUBY);
        const theMint = await contract.methods
          .enter(context.stakeQuoteWei)
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(
            function (result: any) {
              console.log(result)
              return result;
            },
            function (error: any) {
              console.log(error)
              throw new Error(error);
            }
          );
        // if (!isApproved) throw new Error("not approve");
      },
      unstakeRubyGetQuote: async (context, event: any) => {
        const balanceContract = await createERC20(context.erc20);
        const balanceDecimal = await balanceContract.methods
          .decimals()
          .call()
          .then(function (result: any) {
            return result;
          });

        const balanceOf = await balanceContract.methods
          .balanceOf(STAKED_RUBY)
          .call()
          .then(function (result: any) {
            return result;
          })

        const totalStaked = toFixedWithoutZeros(
          priceString(balanceOf.toString(), balanceDecimal),
          4
        )

        const contract = await createERC20(STAKED_RUBY);
        const decimal = await contract.methods
          .decimals()
          .call()
          .then(function (result: any) {
            return result;
          });
        
        // get
        const quoteContract = await createRubyStake(STAKED_RUBY);
        let stakeVirgin = await quoteContract.methods
          .balanceOf(context.walletAddress)
          .call()
          .then(function (result: any) {
            return result;
          })
        stakeVirgin = toFixedWithoutZeros(
          priceString(stakeVirgin.toString(), balanceDecimal),
          4
        )

        // will throw an error, if runs with 0
        let stakeFull = "0";
        if (parseFloat(stakeVirgin) > 0) {
          stakeFull = await quoteContract.methods
            .RUBYBalance(context.walletAddress)
            .call()
            .then(function (result: any) {
              console.log(result)
              return result;
            })
          stakeFull = toFixedWithoutZeros(
            priceString(stakeFull.toString(), balanceDecimal),
            4
          )
        }

        // will throw an error, if runs with 0
        let stakeQuote = "0";
        if (parseFloat(context.unstakeQty) > 0) {
        stakeQuote = await quoteContract.methods
          .xRUBYForRUBY(context.unstakeQty)
          .call()
          .then(function (result: any) {
            return result;
          })
        }

        const stakeQuoteWei = ConvertToFixBitPrice(context.unstakeQty, decimal)
        
        console.log('stakeVirgin', stakeVirgin, 'stakeFull', stakeFull, 'stakeQuote', stakeQuote)

        // 365 days + leap year? from Yukai
        const APR = 3652500 / parseFloat(totalStaked);

        return {
          ...context,
          stakeVirgin: stakeVirgin,
          stakeFull: stakeFull,
          stakeQuote: stakeQuote,
          stakeQuoteWei: stakeQuoteWei,
          totalStaked: totalStaked,
          APR: APR
        };
        
        // if (!isApproved) throw new Error("not approve");
      },
      unstakeRubyStake: async (context) => {
        const oracleResult = await gasOracle().then(function(result){
          return result;
        })
        // qty, spend_currency, max_spend
        const contract = await createRubyStake(STAKED_RUBY);
        const theMint = await contract.methods
          .leave(context.stakeQuoteWei)
          .send({
            from: context.walletAddress,
            maxFeePerGas: oracleResult.maxFeePerGas,
            maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
          })
          .then(
            function (result: any) {
              console.log(result)
              return result;
            },
            function (error: any) {
              console.log(error)
              throw new Error(error);
            }
          );
        // if (!isApproved) throw new Error("not approve");
      },
    },
    actions: {
      updateStakeRubyQuote: assign((context: Context, event: any) => {
        return {
          ...context,
          stakeVirgin: event.data.stakeVirgin,
          stakeFull: event.data.stakeFull,
          stakeQuote: event.data.stakeQuote,
          stakeQuoteWei: event.data.stakeQuoteWei,
          totalStaked: event.data.totalStaked,
          APR: event.data.APR
        };
      }),
      stakeRubyReset: assign((context, event) => {
        return {
          ...context,
          stakeQty: "0",
          unstakeQty: "0",
          stakeQuote: "0",
        };
      }),
    },
  }
);
