import Web3 from "web3";
import { assign, createMachine } from "xstate";
import { BRIDGE_PATH, INVENTORY_PATH } from "../env";
import { fetchX } from "../helpers/fetchX";
import {
  createGameCustodialContract,
  createSquireContract,
  web3,
} from "../web3";
import {
  gasOracle
} from "../helpers/gasOracle";
type Context = {
  selectedMenu: string;
  knights: Knight[];
  squires: Knight[];
  nonNftKnights: Knight[];
  index?: number;
  erc721: string;
  gameCustodial: string;
  selectedKnight?: Knight;
  selectedNonNftKnight?: Knight;
  selectedSquire?: Knight;
  selectingLast?: string;
  finality_blocks: number;
  currentKnight: CurrentKnight;
};
export interface Knight {
  displayIndex?: number;
  token_contract?: string;
  tokenId?: string;
  name: string;
  level?: string;
  imgUrl: string;
}
interface RespKnight {
  id: string;
  meta: Meta;
}
interface Meta {
  name: string;
  attributes: TraitType[];
}
interface TraitType {
  trait_type: string;
  value: string;
}
interface KnightInformation {
  name: string;
  image: string;
  token_contract: string;
  token_id: string;
  attributes: TraitType[];
}

interface CurrentKnight {
  account_id: string;
  knight_id: string;
  current_knight_token_contract?: string;
  current_knight_token_id?: string;
}

export const inventoryMachine = createMachine(
  {
    id: "inventory",
    tsTypes: {} as import("./inventory_machine.typegen").Typegen0,
    schema: {
      events: {} as AppEvent,
      context: {} as Context,
    },

    context: {
      selectedMenu: "inGame",
      knights: [],
      squires: [],
      nonNftKnights: [],
      erc721: "",
      gameCustodial: "",
      finality_blocks: 1,
      currentKnight: {
        account_id: "",
        knight_id: "",
      },
    } as Context,

    initial: "waitForContract",
    states: {
      waitForContract: {
        on: {
          has_contract: {
            target: "loading",
            actions: "updateContractAddr",
          },
        },
      },
      loading: {
        invoke: {
          id: "fetch-knight",
          src: "fetchKnight",
          onDone: {
            target: "idle",
            actions: "updateKnight",
          },
          onError: {
            target: "error",
          },
        },
      },
      idle: {
        on: {
          inventory__promote_squire_clicked: {
            target: "selectNonNftKnight",
            actions: ["setSelectedSquireLast", "setSelectedSquireIndex"],
          },
          inventory__promote_non_nft_clicked: {
            target: "selectSquire",
            actions: [
              "setSelectedNonNFTKnightLast",
              "setSelectedNonNFTKnightIndex",
            ],
          },
          inventory__selected_menu_clicked: {
            target: "loading",
            actions: "setSelectedMenu",
          },
          inventory__import_clicked: {
            target: "checkingApproval",
            actions: "setSelectedKnightIndex",
          },
          inventory__export_clicked: {
            target: "confirmExport",
            actions: "setSelectedKnightIndex",
          },
          inventory__promote_clicked: {
            target: "confirmPromote",
            actions: "setSelectedSquireIndex",
          },
        },
      },
      confirmExport: {
        on: {
          inventory__confirm_export_clicked: {
            target: "createWithdrawTransaction",
          },
          inventory__close_modal_clicked: {
            target: "idle",
          },
        },
      },
      // at inventory services
      createWithdrawTransaction: {
        invoke: {
          id: "create-withdraw",
          src: "createWithdraw",
          onDone: {
            target: "processingWithdraw",
          },
          onError: {
            target: "transferfailed",
          },
        },
      },
      processingWithdraw: {
        invoke: {
          id: "submit-transaction",
          src: "submitTransaction",
          onDone: {
            target: "waitForBlockConfirmWithdraw",
          },
          onError: {
            target: "transferfailed",
          },
        },
      },
      waitForBlockConfirmWithdraw: {
        invoke: {
          id: "withdraw-wait-confirm",
          src: "waitBlockConfirm",
          onDone: {
            target: "transferSuccessful",
          },
          onError: {
            target: "importFailed",
          },
        },
      },
      //approve import
      checkingApproval: {
        invoke: {
          id: "check-approval",
          src: "checkApproval",
          onDone: {
            target: "approved",
          },
          onError: {
            target: "waitForApprove",
          },
        },
      },
      waitForApprove: {
        on: {
          inventory__approve_clicked: {
            target: "approving",
          },
          inventory__close_modal_clicked: {
            target: "idle",
            actions: "clearSelectedKnight",
          },
        },
      },
      selectNonNftKnight: {
        on: {
          inventory__selected_non_nft_cancel: {
            target: "loading",
            actions: "clearSelectedNonNFTAndSquire",
          },
          inventory__selected_non_nft_clicked: {
            actions: "setSelectedNonNFTKnightIndex",
          },
          inventory__selected_non_nft_confirm: {
            target: "confirmPromote",
          },
        },
      },
      selectSquire: {
        on: {
          inventory__selected_squire_cancel: {
            target: "loading",
            actions: "clearSelectedNonNFTAndSquire",
          },
          inventory__selected_squire_clicked: {
            actions: "setSelectedSquireIndex",
          },
          inventory__selected_squire_confirm: {
            target: "confirmPromote",
          },
        },
      },
      approving: {
        invoke: {
          id: "approval-request",
          src: "approveRequest",
          onDone: {
            target: "approved",
          },
          onError: {
            target: "approveFailed",
          },
        },
      },
      approveFailed: {
        on: {
          inventory__close_modal_clicked: {
            target: "idle",
            actions: "clearSelectedKnight",
          },
        },
      },
      approved: {
        on: {
          inventory__close_modal_clicked: {
            target: "idle",
            actions: "clearSelectedKnight",
          },
          inventory__confirm_import_clicked: {
            target: "importing",
          },
        },
      },
      importing: {
        invoke: {
          id: "import-knight",
          src: "createImportTransaction",
          onDone: {
            target: "waitForBlockConfirmImport",
          },
          onError: {
            target: "importFailed",
          },
        },
      },
      waitForBlockConfirmImport: {
        invoke: {
          id: "import-wait-comfirm",
          src: "waitBlockConfirm",
          onDone: {
            target: "transferSuccessful",
          },
          onError: {
            target: "importFailed",
          },
        },
      },
      importFailed: {
        on: {
          inventory__close_modal_clicked: {
            target: "idle",
            actions: "clearSelectedKnight",
          },
        },
      },
      processingTransaction: {
        invoke: {
          id: "approve-transfer",
          src: "approveTransfer",
          onDone: {
            target: "transferSuccessful",
          },
          onError: {
            target: "transferfailed",
          },
        },
      },
      transferSuccessful: {
        on: {
          inventory__close_modal_clicked: {
            target: "loading",
            actions: "clearSelectedKnight",
          },
        },
      },
      transferfailed: {
        on: { inventory__close_modal_clicked: { target: "loading" } },
      },
      confirmPromote: {
        on: {
          inventory__confirm_promote_clicked: {
            target: "promoting",
          },
          inventory__close_modal_clicked: {
            target: "idle",
          },
        },
      },
      promoting: {
        invoke: {
          id: "approve-promoting",
          src: "approvePromote",
          onDone: {
            target: "promoteSuccessful",
          },
          onError: {
            target: "promoteFailed",
          },
        },
      },
      promoteSuccessful: {
        on: {
          inventory__close_modal_clicked: { target: "loading" },
        },
      },
      promoteFailed: {
        on: { inventory__close_modal_clicked: { target: "loading" } },
      },
      error: {},
    },
  },
  {
    services: {
      submitTransaction: async (context) => {
        await new Promise((r) => setTimeout(r, 3000));

        // generate transaction
        return true;
      },
      checkApproval: async (context) => {
        const accounts = await web3.eth.getAccounts();
        if (context.selectedKnight?.token_contract) {
          const squireContract = await createSquireContract(context.erc721);
          const approvedAddr = await squireContract.methods
            .getApproved(context.selectedKnight.tokenId)
            .call()
            .then(function (result: any) {
              return result;
            });
          if (approvedAddr.toLowerCase() !== context.gameCustodial) {
            const isApproved = await squireContract.methods
              .isApprovedForAll(accounts[0], context.gameCustodial)
              .call()
              .then(function (result: any) {
                return result;
              });
            if (!isApproved) throw new Error("not approve");
          }
        }
      },
      approveRequest: async (context) => {
        const accounts = await web3.eth.getAccounts();

        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })

        if (context.selectedKnight?.token_contract) {
          const squireContract = await createSquireContract(context.erc721);
          const approvetx = await squireContract.methods
            .approve(context.gameCustodial, context.selectedKnight.tokenId)
            .send({
              from: accounts[0],
              maxFeePerGas: oracleResult.maxFeePerGas,
              maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
            })
            .then(
              function (result: any) {
                return result;
              },
              function (error: any) {
                throw new Error(error);
              }
            );
        }
      },
      //import = deposit nft-knight to in-game
      createImportTransaction: async (context) => {
        const accounts = await web3.eth.getAccounts();
        const gameCustodialContract = await createGameCustodialContract(
          context.gameCustodial
        );

        const oracleResult = await gasOracle().then(function(result){
          console.log('oracle', result);
          return result;
        })

        if (context.selectedKnight?.token_contract) {
          const importtx = await gameCustodialContract.methods
            .deposit721(
              context.selectedKnight.token_contract,
              context.selectedKnight.tokenId
            )
            .send({
              from: accounts[0],
              maxFeePerGas: oracleResult.maxFeePerGas,
              maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
            })
            .then(
              function (result: any) {
                return result;
              },
              function (error: any) {
                throw new Error(error);
              }
            );
        }
      },
      waitBlockConfirm: async (context) => {
        let web3 = new Web3(Web3.givenProvider || "http://127.0.0.1:8545");
        let isFirstBlockAppear = false;
        let qty = 0;
        var subscription = web3.eth
          .subscribe("newBlockHeaders", function (error, result) {
            if (!error) {
              qty += 1;
              isFirstBlockAppear = true;
              return;
            }
            console.error(error);
          })
          .on("error", console.error);
        console.log("finality ", context.finality_blocks);
        while (isFirstBlockAppear === false) {
          await new Promise((r) => setTimeout(r, 2000));
          while (qty <= context.finality_blocks) {
            await new Promise((r) => setTimeout(r, 2000));
            console.log("block added", qty);
          }
        }
        subscription.unsubscribe(function (error, success) {
          if (success) {
            console.log("Successfully unsubscribed!");
          } else {
            console.log("error", error);
          }
        });
      },
      createWithdraw: async (context) => {
        const jwt = localStorage.getItem("CustomerJWT");
        if (!jwt) throw Error("No JWT!");
        try {
          const withdrawReq = await fetchX(
            `${BRIDGE_PATH}/erc721/${context.selectedKnight?.token_contract}/${context.selectedKnight?.tokenId}/withdraw/`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
            }
          );
          const accounts = await web3.eth.getAccounts();
          const gameCustodialContract = await createGameCustodialContract(
            context.gameCustodial
          );

          const oracleResult = await gasOracle().then(function(result){
            console.log('oracle', result);
            return result;
          })

          if (context.selectedKnight?.token_contract) {
            const importtx = await gameCustodialContract.methods
              .withdraw721(
                withdrawReq.signature,
                context.selectedKnight.token_contract,
                context.selectedKnight.tokenId,
                withdrawReq.expires,
                withdrawReq.nonce
              )
              .send({
                from: accounts[0],
                maxFeePerGas: oracleResult.maxFeePerGas,
                maxPriorityFeePerGas: oracleResult.maxPriorityFeePerGas
              })
              .then(
                function (result: any) {
                  return result.message;
                },
                function (error: any) {
                  throw new Error(error);
                }
              );
          }
        } catch (e) {
          console.error(e);
          throw new Error("Knighting ceremony failed!!");
        }
      },
      approvePromote: async (context) => {
        const jwt = localStorage.getItem("CustomerJWT");
        if (!jwt) throw Error("No JWT!");

        try {
          const result = await fetchX(
            `${INVENTORY_PATH}/squire/${context.erc721}/${context.selectedSquire?.tokenId}/associate`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
              body: JSON.stringify({
                knight_id: context.selectedNonNftKnight?.tokenId || null,
              }),
            }
          );
          return result.message;
        } catch (e) {
          console.error(e);
          throw new Error("Knighting ceremony failed!!");
        }
      },
      approveTransfer: async (context) => {
        //construct and do transaction here
        // const squireContract = await createSquireContract(context.erc721);
        await new Promise((r) => setTimeout(r, 2000));

        // throw new Error("e");
        return true;
      },
      fetchKnight: async (
        context
      ): Promise<{
        knights: Knight[];
        squires: Knight[];
        nonNftKnights: Knight[];
        currentKnight: CurrentKnight;
      }> => {
        let knights: KnightInformation[] = [],
          squires: KnightInformation[] = [],
          nonNftKnights: RespKnight[] = [];
        let accounts = [];
        switch (context.selectedMenu) {
          case "inGame":
            const jwt = localStorage.getItem("CustomerJWT");

            if (!jwt) throw Error("No JWT!");
            const accountId = JSON.parse(atob(jwt.split(".")[1]));
            const identityId = accountId.identity_id;
            const data = await fetch(
              `${INVENTORY_PATH}/knight/by-account/${identityId}/non-nft`,
              {
                method: "GET",
                headers: {
                  Authorization: "Bearer " + jwt,
                },
              }
            );
            const nonNftKnights = await data.json();

            accounts = await web3.eth.getAccounts();
            const tokens = await fetch(
              `${BRIDGE_PATH}/items/${accounts[0]}/in-game/`
            );
            const inGameKnights = await tokens.json();

            for (let i in inGameKnights) {
              let temp = await fetch(`${inGameKnights[i].token_uri}`);
              const json = await temp.json();
              const traitLevel = json.attributes.find(
                (o: TraitType) => o.trait_type === "level"
              );
              if (traitLevel.value === "0") {
                squires.push({
                  ...json,
                  token_contract: inGameKnights[i].contract_address,
                  token_id: inGameKnights[i].token_id,
                });
              } else {
                knights.push({
                  ...json,
                  token_contract: inGameKnights[i].contract_address,
                  token_id: inGameKnights[i].token_id,
                });
              }
            }

            const fetchCurrentKnight = await fetch(
              `${INVENTORY_PATH}/account/current-knight`,
              {
                method: "GET",
                headers: {
                  Authorization: "Bearer " + jwt,
                },
              }
            );
            const currentKnight = await fetchCurrentKnight.json();
            return {
              knights: constructedData(knights),
              squires: constructedData(squires),
              nonNftKnights: constructedNonNftData(nonNftKnights),
              currentKnight: currentKnight,
            };
          case "onChain":
            accounts = await web3.eth.getAccounts();
            let resp = await fetch(
              `${BRIDGE_PATH}/items/${accounts[0]}/in-wallet/`
            );
            const inGameItem = await resp.json();
            for (let i in inGameItem) {
              let temp = await fetch(`${inGameItem[i].token_uri}`);
              const json = await temp.json();
              const traitLevel = json.attributes.find(
                (o: TraitType) => o.trait_type === "level"
              );
              if (traitLevel.value === "0") {
                squires.push({
                  ...json,
                  token_contract: inGameItem[i].contract_address,
                  token_id: inGameItem[i].token_id,
                });
              } else {
                knights.push({
                  ...json,
                  token_contract: inGameItem[i].contract_address,
                  token_id: inGameItem[i].token_id,
                });
              }
            }

            return {
              knights: constructedData(knights),
              squires: constructedData(squires),
              nonNftKnights: [],
              currentKnight: {
                account_id: "",
                knight_id: "",
              },
            };
          default:
            throw new Error("Can't fetch knight ");
        }
      },
    },
    actions: {
      updateKnight: assign((context, event: any) => {
        return {
          ...context,
          knights: event.data.knights,
          squires: event.data.squires,
          nonNftKnights: event.data.nonNftKnights,
          currentKnight: event.data.currentKnight,
        };
      }),
      setSelectedMenu: assign((context, event: any) => {
        return { ...context, selectedMenu: event.selected };
      }),
      setSelectedKnightIndex: assign((context, event: any) => {
        switch (event.knightType) {
          case "nft":
            return {
              ...context,
              selectedKnight: context.knights[event.index],
            };
          case "squire":
            return {
              ...context,
              selectedKnight: context.squires[event.index],
            };
          default:
            return {
              ...context,
            };
        }
      }),
      clearSelectedKnight: assign((context, event: any) => {
        return {
          ...context,
          selectedKnight: undefined,
        };
      }),
      setSelectedNonNFTKnightIndex: assign((context, event: any) => {
        return {
          ...context,
          selectedNonNftKnight: {
            ...context.nonNftKnights[event.index],
            displayIndex: event.index,
          },
        };
      }),
      clearSelectedNonNFTAndSquire: assign((context, event: any) => {
        return {
          ...context,
          selectedNonNftKnight: undefined,
          selectedSquire: undefined,
        };
      }),
      setSelectedSquireIndex: assign((context, event: any) => {
        return {
          ...context,
          selectedSquire: {
            ...context.squires[event.index],
            displayIndex: event.index,
          },
        };
      }),
      setSelectedNonNFTKnightLast: assign((context, event: any) => {
        return {
          ...context,
          selectingLast: "squire",
        };
      }),
      setSelectedSquireLast: assign((context, event: any) => {
        return {
          ...context,
          selectingLast: "nonnft",
        };
      }),
      updateContractAddr: assign((context, event: any) => {
        return {
          ...context,
          erc721: event.payload.erc721,
          gameCustodial: event.payload.gameCustodial,
          finality_blocks: event.payload.finality_blocks,
        };
      }),
    },
  }
);

const constructedData = (knights: KnightInformation[]) => {
  return knights.map((r) => {
    const traitLevel = r.attributes.find(
      (o: TraitType) => o.trait_type === "level"
    );
    return {
      token_contract: r.token_contract,
      tokenId: r.token_id,
      imgUrl: r.image,
      name: r.name,
      level: traitLevel ? traitLevel.value : "-",
    };
  });
};

const constructedNonNftData = (knights: RespKnight[]) => {
  return knights.map((r) => {
    const traitLevel = r.meta.attributes.find(
      (o: TraitType) => o.trait_type === "level"
    );
    return {
      tokenId: r.id,
      imgUrl: "",
      name: r.meta.name,
      level: traitLevel ? traitLevel.value : "-",
    };
  });
};
