import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as Chevron } from "../../svgs/chevron.svg";
import { ReactComponent as Ruby } from "../../svgs/ruby.svg";
import { ReactComponent as XMark } from "../../svgs/xmark.svg";
import Modal from "../../components/Modal/Index";
import { StateValue } from "xstate";

interface Props {
  onClose?: () => void;
  rubyCurrent: string;
  stakeVirgin: string;
  stakeFull: string;
  stakeQty: string;
  stakeQuote: string;
  totalStaked: string;
  APR: string;
  stakeSalesLang: string;
  stakeAPRLang: string;
  updateStakeQty: (arg: string) => void;
  onRubyStakeClicked: () => void;
  walletState: StateValue;
}

function PopUpUnstakeRuby(props: Props) {
  const [openHelp, setOpenHelp] = useState(false);

  const toggleHelp = ():void => {
    setOpenHelp(!openHelp)
  }

  useEffect(() => {
    // auto quote, for sebsequent popup launches, where a state stakeQTY still exists
    props.updateStakeQty(props.stakeQty)
  }, []);

  return (
    <Modal>
      <button
        className="btn-dim btn-circle btn-sm btn-dismiss"
        id="btn-promote-popup-close"
        onClick={props.onClose}
        title="Cancel Boost"
      >
        <XMark className="w-4" />
      </button>
      <div className="flex flex-col w-full">
        <h2 className="title w-full">
          Unstake RUBY
        </h2>
        <div
          className="flex w-full items-center justify-center gap-2">
          <div className="w-1/2">
            <div className="relative pt-full">
              <div className="absolute inset-0 flex items-center p-4">
                <Ruby className="rotate-180 hue-rotate-240" />
                <div className="absolute bottom-6 left-6 py-1 px-3 text-smer bg-black bg-opacity-50 rounded-full">
                  <span>xRUBY</span>
                </div>
              </div>
            </div>
          </div>
          <Chevron className="w-8 -rotate-90" />
          <div className="w-1/2">
            <div className="relative pt-full">
              <div className="absolute inset-0 flex items-center p-4">
                <Ruby />
                <div className="absolute bottom-6 left-6 py-1 px-3 text-smer bg-black bg-opacity-50 rounded-full">
                  <span>RUBY</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 px-6 text-neutral-300">
          <div className="flex items-center justify-between">
            <div>Your RUBY stake</div>
            <span>{ parseFloat(props.stakeFull) > 0 ? parseFloat(props.stakeFull).toFixed(2) : '~' }</span>
          </div>
          <div className="flex items-center justify-between">
            <div>Your RUBY available</div>
            <span>{ parseFloat(props.rubyCurrent) > 0 ? parseFloat(props.rubyCurrent).toFixed(2) : '~' }</span>
          </div>
          <div className="flex items-center justify-between">
            <div>Your xRUBY balance</div>
            <span>{ parseFloat(props.stakeVirgin) > 0 ? parseFloat(props.stakeVirgin).toFixed(2) : '~' }</span>
          </div>
        </div>
        <div className="mt-4">
          <div>
            {
              // observe state props when needed
              /*
              <div className="mt-4">
                stakeQty: {props.stakeQty}
              </div>
              */
            }
            <div>
              <label className="sr-only" htmlFor="unstake-ruby-quote-qty">Qty</label>
              <div className="relative">
                <input className="input" id="unstake-ruby-quote-qty" min="1" max={props.stakeVirgin} step="1" placeholder={parseFloat(props.stakeVirgin) === 0 ? "No RUBY staked!" : "1 - " + (props.stakeVirgin)} type="number" required value={props.stakeQty} onChange={(e) => props.updateStakeQty(e.target.value)} disabled={ props.walletState !== "unstakeRubyQuoting" && props.walletState !== "unstakeRubyQuoteSuccess" && props.walletState !==  "unstakeRubyQuoteFail" } />
                <button className="btn-dim gap-2 absolute top-2 right-2.5 w-min" disabled>
                  <Ruby className="w-3 rotate-180 hue-rotate-240" />
                  <span>xRUBY</span>
                </button>
              </div>
            </div>
            
            <div className="flex items-center justify-between mt-4 px-6 text-xl">
              <div className="flex gap-1">
                <span>RUBY received</span>
                <button
                  className="btn-dim btn-xs btn-circle"
                  title="More details about RUBY staking"
                  onClick={toggleHelp}
                >
                  <Chevron className={"w-3" + (openHelp ? " rotate-180" : "")} />
                </button>
              </div>
              <div className="flex gap-3 items-center">
                <Ruby className="w-3" />
                <span>{ parseFloat(props.stakeQuote) > 0 ? parseFloat(props.stakeQuote).toFixed(2) : '~' }</span>
              </div>
            </div>
            <div className={"collapse" + (openHelp ? " active" : "")}>
              <div className="px-6 text-neutral-300">
                <div className="flex items-center justify-between">
                  <div>APR</div>
                  <span>{ parseFloat(props.APR) > 0 ? parseFloat(props.APR).toFixed(2) : '~' }%</span>
                </div>
                <div className="flex items-center justify-between">
                  <div>Total RUBY staked</div>
                  <div className="flex gap-3 items-center">
                    <Ruby className="h-3" />
                    <span>{ parseFloat(props.totalStaked) > 0 ? parseFloat(props.totalStaked).toFixed(2) : '~' }</span>
                  </div>
                </div>
                <div className="mt-3 text-smer">
                  { props.stakeSalesLang }
                  <br /><br />
                  { props.stakeAPRLang }
                </div>
              </div>
            </div>
            { props.walletState === "unstakeRubyStakeSuccess" ?
              <button
                className="btn-boost btn-lg mt-4"
                onClick={props.onClose}
              >
                Unstake successful!
              </button>
              :
              <button
                className="btn-boost btn-lg mt-4"
                disabled={ (props.walletState === "unstakeRubyQuoteSuccess" || props.walletState === "unstakeRubyStakeFail") && parseFloat(props.stakeQuote) > 0 ? false : true }
                onClick={props.onRubyStakeClicked}
              >
                { props.walletState === "unstakeRubyStaking" ?
                  <span>Unstaking...</span>
                  :
                  <span>Unstake</span>
                }
              </button>
            }
            <div className={"collapse" + (props.walletState === "unstakeRubyStakeFail" ? " active" : "")}>
              <div className="flex items-center justify-center gap-4 mt-4 px-6 text-lger">
                  <span>Error! Maybe try again.</span>
              </div>
            </div>
            {/*
            <div className="mt-6 px-5">{ props.walletState as string }</div>
            */}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopUpUnstakeRuby;
