import { actorsInterpreter } from "./actors_machine";

type EventType = AppEvent["type"];
type ActorName = string;

const registry = new Map<EventType, Set<ActorName>>();

export function registerActor(events: EventType[], name: string): () => void {
  events.forEach((e) => {
    if (registry.has(e)) {
      const set = registry.get(e);
      set?.add(name);
    } else {
      const set = new Set<ActorName>();
      set.add(name);
      registry.set(e, set);
    }
  });

  // returns cleaning function
  return () => {
    events.forEach((e) => {
      const set = registry.get(e);
      set?.delete(name);
    });
  };
}

export function publish(event: AppEvent, source: string) {
  console.log(event);
  const actors = registry.get(event.type);
  if (actors) {
    const events: {
      type: "actor_context__publish";
      event: AppEvent;
      actor: string;
      source: string;
    }[] = Array.from(actors).map((actor) => {
      return {
        type: "actor_context__publish",
        event,
        actor,
        source,
      };
    });
    actorsInterpreter.send(events);
  }
}
