import { useEffect, useState } from "react";
import { ReactComponent as Chevron } from "../../svgs/chevron.svg";
import { ReactComponent as DAI } from "../../svgs/dai.svg";
import { ReactComponent as Knights } from "../../svgs/knights.svg";
import { ReactComponent as Minus } from "../../svgs/minus.svg";
import { ReactComponent as Mixer } from "../../svgs/mixer.svg";
import { ReactComponent as Plus } from "../../svgs/plus.svg";
import { ReactComponent as XMark } from "../../svgs/xmark.svg";
import KnightFrame from "../../components/KnightFrame/Index";
import Modal from "../../components/Modal/Index";
import { StateValue } from "xstate";

interface Props {
  isGuest: boolean;
  onClose?: () => void;
  buyQty: string;
  buyQuote: string;
  buyQuoteMod: string;
  updateBuyQty: (arg: string) => void;
  increaseBuyQty: () => void;
  decreaseBuyQty: () => void;
  onApproveClicked: () => void;
  onChainMintClicked: () => void;
  onAccountMintClicked: () => void;
  buyState: StateValue;
}

function PopUpBuyKnight(props: Props) {
  const [openHelp, setOpenHelp] = useState(false);

  const toggleHelp = ():void => {
    setOpenHelp(!openHelp)
  }

  useEffect(() => {
    // auto quote, for sebsequent popup launches, where a state stakeQTY still exists
    props.updateBuyQty(props.buyQty)
  }, []);

  return (
    <Modal>
      <button
        className="btn-dim btn-circle btn-sm btn-dismiss"
        id="btn-promote-popup-close"
        onClick={props.onClose}
        title="Cancel Boost"
      >
        <XMark className="w-4" />
      </button>
      <div className="flex flex-col w-full">
        <h2 className="title w-full">
          Mint Squire
        </h2>
        <div
          className="flex w-full items-center justify-center gap-4 md:gap-6">
          <div className="w-2/5">
            <KnightFrame
              knightType={'squire'}
              knightStatus={'in-game'}
              level={"0"}
              // imgUri={''}
              name={"New Squire"}
            ></KnightFrame>
          </div>
        </div>
        <div className="mt-6">
          <div>
            {
              // observe state props when needed
              /*
              <div className="mt-4">
                buyQty: {props.buyQty}
              </div>
              */
            }
            <div>
              <label className="sr-only" htmlFor="buy-knight-quote-qty">Qty</label>
              <div className="relative">
                <input className="input btn-rounded-b-sm" id="buy-knight-quote-qty" min="1" max="300" step="1" placeholder="1 - 300" type="number" required value={props.buyQty} onChange={(e) => props.updateBuyQty(e.target.value)} disabled={ props.buyState !== "buyKnightQuoting" && props.buyState !== "buyKnightQuoteSuccess" && props.buyState !==  "buyKnightQuoteFail" } />
                <button className="btn-dim gap-2 absolute top-2 right-2.5 w-min" disabled>
                  <Knights className="w-4" />
                  <span>Knights</span>
                </button>
                {/*
                <button className="btn-ie btn-sm btn-circle absolute top-1.5 right-11" title="Increase qty" disabled={parseFloat(props.buyQty) > 299 || (props.buyState !== "buyKnightQuoteSuccess" && props.buyState !==  "buyKnightQuoteFail")} onClick={() => props.increaseBuyQty()}>
                  <Plus className="w-3" />
                </button>
                <button className="btn-ie btn-sm btn-circle absolute top-1.5 right-2" title="Decrease qty" disabled={parseFloat(props.buyQty) < 2 || (props.buyState !== "buyKnightQuoteSuccess" && props.buyState !==  "buyKnightQuoteFail")} onClick={() => props.decreaseBuyQty()}>
                  <Minus className="w-3" />
                </button>
                */}
              </div>
            </div>
            <div className="relative z-10 w-min -my-3.5 mx-auto bg-black rounded-full">
              <button className="btn-dim btn-sm btn-circle" title="Minting knights with DAI" disabled>
                <Mixer className="w-3" />
              </button>
            </div>
            <div className="relative mb-4">
              <label className="sr-only" htmlFor="buy-knight-quote-currency">Currency</label>
              <input className="input btn-rounded-t-sm" id="buy-knight-quote-currency" type="text" readOnly value="DAI" disabled={props.buyState !== "buyKnightQuoteSuccess" && props.buyState !==  "buyKnightQuoteFail"} />
              <button className="btn-dim gap-2 absolute top-2 right-2.5 w-min" disabled>
                <DAI className="w-4" />
                <span>DAI</span>
              </button>
            </div>
            <div className="flex items-center justify-between px-6 text-xl">
              <div className="flex gap-1">
                <span>Est. Total</span>
                <button
                  className="btn-dim btn-xs btn-circle"
                  title="More details about Knight minting"
                  onClick={toggleHelp}
                  disabled={ parseFloat(props.buyQuote) <= 0}
                >
                  <Chevron className={"w-3" + (openHelp ? " rotate-180" : "")} />
                </button>
              </div>
              <div className="flex gap-3 items-center">
                <DAI className="w-5" />
                <span>{ parseFloat(props.buyQuote) > 0 ? parseFloat(props.buyQuote) : '~' }</span>
              </div>
            </div>
            <div className={"collapse" + (openHelp ? " active" : "")}>
              <div className="px-6 text-neutral-300">
                <div className="flex items-center justify-between">
                  <div>Max Total</div>
                  <span>{ parseFloat(props.buyQuoteMod) > 0 ? parseFloat(props.buyQuoteMod) : '~' }</span>
                </div>
                <div className="mt-3 text-smer">The estimated price is <span className="text-white font-semibold">{ parseFloat(props.buyQuote) > 0 ? parseFloat(props.buyQuote) : '~' } DAI</span> - MetaMask will prompt you to approve a transaction for <span className="text-white font-semibold">{ parseFloat(props.buyQuoteMod) > 0 ? parseFloat(props.buyQuoteMod) : '~' } DAI</span> - to help facilitate the transfer during times of high transaction volume. </div>
              </div>
            </div>
            { props.buyState  !== "buyKnightChainMintSuccess" &&
              <>
              { props.buyState  !== "buyKnightApproveSuccess" &&
                props.buyState  !== "buyKnightChainMinting" &&
                props.buyState  !== "buyKnightChainMintSuccess" &&
                props.buyState  !== "buyKnightChainMintFail" &&
                props.buyState  !== "buyKnightAccountMinting" &&
                props.buyState  !== "buyKnightAccountWaiting" ?
                <div className="flex gap-4 mt-4">
                  <button
                    className="btn-boost btn-lg"
                    disabled={ props.buyState === "buyKnightQuoteSuccess" && parseFloat(props.buyQuote) > 0 ? false : true }
                    onClick={props.onApproveClicked}
                  >
                    { props.buyState === "buyKnightApproving" ?
                      <span>Approving...</span>
                      :
                      <span>Approve</span>
                    }
                  </button>
                </div>
                :
                <div className="flex gap-4 mt-4">
                  <button
                    className="btn-boost btn-lg"
                    disabled={ props.buyState === "buyKnightChainMinting" || props.buyState === "buyKnightAccountMinting" || props.buyState === "buyKnightAccountWaiting" }
                    onClick={props.onChainMintClicked}
                  >
                    { props.buyState === "buyKnightChainMinting" ?
                      <span>Minting...</span>
                      :
                      <span>Mint to Wallet</span>
                    }
                  </button>
                  <button
                    className="btn-boost btn-lg"
                    disabled={ props.isGuest || props.buyState === "buyKnightChainMinting" || props.buyState === "buyKnightAccountMinting" || props.buyState === "buyKnightAccountWaiting" }
                    onClick={props.onAccountMintClicked}
                  >
                    { props.buyState === "buyKnightAccountMinting" ?
                      <span>Minting...</span>
                    : props.buyState === "buyKnightAccountWaiting" ?
                      <span>Confirming...</span>
                    :
                      <span>Mint to Account</span>
                    }
                  </button>
                </div>
              }
              </>
            }
            { props.buyState === "buyKnightChainMintSuccess" &&
              <button
                className="btn-boost btn-lg mt-4"
                onClick={props.onClose}
              >
                Mint successful!
              </button>
            }
            {/*
            <div className="mt-6 px-5">{ props.buyState as string }</div>
            */}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopUpBuyKnight;
